import { VocabularyEnum } from 'common/enums/VocabularyEnum'
import { useVocabulary } from 'customHooks/vocabulary/useVocabulary'
import { FC } from 'react'
import { momentDateFormat, momentHoursFormat } from 'utils/DateFormatting'
import { matchOptionWithName } from 'utils/MatchOptionWithName'
import { matchWeekDays } from 'utils/MatchWeekDays'
import { IJobSchedule } from '../../../common/interfaces/jobs/IJob'

type Props = {
    selectedItem: IJobSchedule,
}

const DeactivateScheduleModalBody: FC<Props> = ({ selectedItem: schedule }) => {
    const {vocabulary: shiftVocabulary} = useVocabulary(VocabularyEnum.shift, true)

    return (
        <>
            <div className="row mb-3">
                <div className="col-sm-5 details-information__title mb-0">
                    <h6 className="mb-0">Start and End Dates:</h6>
                </div>
                <div className="col-sm-7 details-information__value">
                    <p className="mb-0">
                        {schedule.dates
                            ? momentDateFormat(schedule.dates)
                            : `${momentDateFormat(schedule.startDate)} - ${momentDateFormat(schedule.endsOn)}`}
                    </p>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-sm-5 details-information__title mb-0">
                    <h6 className="mb-0">ASP Start Time:</h6>
                </div>
                <div className="col-sm-7 details-information__value">
                    <p className="mb-0">{schedule.aspStartDate && momentHoursFormat(schedule.aspStartDate, schedule.timeFrom, true)}</p>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-sm-5 details-information__title mb-0">
                    <h6 className="mb-0">Start and End Times:</h6>
                </div>
                <div className="col-sm-7 details-information__value">
                    <p className="mb-0">{schedule.times
                        ? momentHoursFormat(schedule.times)
                        : `${momentHoursFormat(schedule.timeFrom)} - ${momentHoursFormat(schedule.timeTo, schedule.timeFrom)}`
                    }</p>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-sm-5 details-information__title mb-0">
                    <h6 className="mb-0">Shift Period:</h6>
                </div>
                <div className="col-sm-7 details-information__value">
                    <p className="mb-0">
                        { schedule.shiftPeriod && matchOptionWithName(schedule.shiftPeriod, shiftVocabulary) }
                    </p>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-sm-5 details-information__title mb-0">
                    <h6 className="mb-0">Repeat on:</h6>
                </div>
                <div className="col-sm-7 details-information__value">
                    <p className="mb-0">
                        { schedule.repeatOn && `Every week: ${matchWeekDays(schedule.repeatOn)}` }
                    </p>
                </div>
            </div>
        </>
    )
}

export default DeactivateScheduleModalBody
