import { ICityDropdown } from 'common/interfaces/dropdowns/ICityDropdown';
import {convertToRaw, EditorState, convertFromHTML, ContentState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';

export const convertToHtml = (inputValue: EditorState) => draftToHtml(convertToRaw(inputValue.getCurrentContent()));

export const createContentStateFromHtml = (html: string) => {
    const {contentBlocks, entityMap} = convertFromHTML(html);
    let contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
    return adjustContentStateForAnchorTags(contentState)
}

export const adjustContentStateForAnchorTags = (contentState: ContentState) => {
    const rawContentState = convertToRaw(contentState);

    Object.keys(rawContentState.entityMap).forEach((key) => {
        const entity = rawContentState.entityMap[key];
        if (entity.type === 'LINK') {
            if (entity.data && entity.data.href) {
                entity.data.url = entity.data.href;
            }
        }
    });
    return contentState
}

export const jobFromStrToLink = (jobs: object | string) => {
  let result = '';
  if(jobs){
      if(typeof jobs == 'string'){
          const jobsArr = jobs.split(';');
          return jobsArr.map(job => (
              `<a href="/#/jobs/${job}/details" target="_blank">${job}</a>`
          )).join(', ')
      } else {
          const jobsArr = Object.entries(jobs)
          return jobsArr.map(job => (
              `<a href="/#/jobs/${job[1]}/details" target="_blank">${job[0]}</a>`
          )).join(', ')
      }
  } else return result;
}


export const getSortedZones = (cities: ICityDropdown[]) => cities.map(v => v.zoneViewModels).flat(1).map(({id, name}) => ({id, name})).sort((a, b) => a.name.trim().toLowerCase().localeCompare(b.name.trim().toLowerCase()));

