import { VocabularyEnum } from 'common/enums/VocabularyEnum'
import { useVocabulary } from 'customHooks/vocabulary/useVocabulary'
import { useState } from 'react'
import { IDropdown } from '../../common/interfaces/dropdowns/IDropdown'
// import { IShiftJobReportModel } from '../../common/interfaces/IDataFilter'
import ResourceDataService from '../../common/services/ResourceDataService'

import useZones from 'customHooks/useZones'

type TProps = {
    [index: string] : any
}

const useReportActions = <T extends TProps> (filter: T) => {
    const svcSchoolBoards = new ResourceDataService<IDropdown[]>({url: 'school/dropdown'})
    const svcHoliday = new ResourceDataService<IDropdown[]>({url: 'holiday'})
    const [showTable, setShowTable] = useState(false)
    const { vocabulary: shiftVocabulary } = useVocabulary(VocabularyEnum.shift, true)

    const [schoolBoards, setSchoolBoards] = useState<IDropdown[]>([])
    const [holidays, setHolidays] = useState<IDropdown[]>([])

    const zones = useZones();

    

    const fetchHolidaysBySchoolBoard = async () => {
        let response = await svcHoliday.queryString(`schoolBoardsId=${filter.schoolBoard!}`)
        setHolidays(response.data.map(holiday => ({
            id: holiday.id, name: holiday.name
        })))
    }

    const fetchHolidays = async () => {
        let response = await svcHoliday.getAll()
        setHolidays(response.data.map(holiday => ({
            id: holiday.id, name: holiday.name
        })))
    }

    const fetchAdditionalData = async () => {
        try {
            const response = await svcSchoolBoards.getAll()
            setSchoolBoards(response.data.map(board => ({
                id: board.id, name: board.name
            })))
        } catch (error: any) {
            console.log(error.message)
        }
    }
    
    const onChangeMultiSelectString = (type: keyof  T, index: number | string,
        callBack: (result: string, type: keyof  T) => void
        ) => {
        let array: string[] = [], result;
        let data: any = filter[type]

        if (data) array = data?.split(';')
        let find = array && [...array]?.find(el => el === `${index}`)
        if (find) {
            result = array?.filter(el => el !== `${index}`)
        } else {
            array.push(`${index}`)
            result = array
        }
        result = result?.join(';');

        result ? callBack(result, type) : callBack('', type)
    }

    return {
        fetchHolidaysBySchoolBoard, fetchHolidays, fetchAdditionalData, onChangeMultiSelectString,
        schoolBoards, holidays,showTable, setShowTable, shiftVocabulary, zones
    }
}

export default useReportActions
