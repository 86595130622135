import React, { FC, useContext, useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { AuthService } from '../../common/auth/AuthService'
import { Roles } from '../../common/enums/Roles'
import { NotificationTypes } from '../../common/interfaces/INotification'
import { IValidationBody, ValidationRules } from '../../common/interfaces/IValidation'
import BaseStartScreen from '../../components/base-start-screen/BaseStartScreen'
import AppContext from '../../context/AppContext'
import { useOnIputChange } from '../../customHooks/useOnInputChange'
import { validateFiled, removeHighlightError, highlightError } from '../../utils/Validation'
import ChooseTenantModal from '../../components/modal/administration/ChooseTenantModal'
import { ILoggedInUser } from 'common/interfaces/IUser'
import { useLocalStorage } from 'customHooks/useLocalStorage'
const SignIn: FC<RouteComponentProps> = (props) => {
    const {onChange, hookState: formData} = useOnIputChange({ username: '', password: '' });
    const { setCurrentUser, showLoader, setLoggedIn, showNotification, currentUser } = useContext(AppContext)
    const {storedValue} = useLocalStorage('tenantId', '')
    const history = useHistory();
    const [showTenantModal, setShowTenantModal] = useState(false);
    useEffect(() => {
        if (Object.keys(currentUser).length !== 0 && currentUser.role === Roles.SuperAdmin && !storedValue) {
            window.localStorage.clear()
        } else {
            Object.keys(currentUser).length !== 0  ? history.push('/dashboard') : window.localStorage.clear();
        }
    }, [])

    // basicuser@gmail.com
    const validForm = () => {
        let errors: IValidationBody[] = [];

        errors = validateFiled(formData.username as string, 'User Email', ValidationRules.required)
        errors = validateFiled(formData.password as string, 'Password', ValidationRules.required)
        if (errors.length === 0) {
            removeHighlightError()
            return true
        } else {
            highlightError([{ fieldName: 'username', errorMessage: '' }, { fieldName: 'password', errorMessage: '' }])
            return false
        }
    }
    const successCallback = (user: ILoggedInUser) => {
        AuthService.checkIfUserHasPermission([Roles.ASP, Roles.Admin, Roles.Client, Roles.Supervisor, Roles.SuperAdmin], user.role) ?
        props.history.push('/dashboard') :
        showNotification(NotificationTypes.danger, `${user.firstName} ${user.lastName} doesn't have enough permissions to signin`)
    }
    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent) => {
        if (!validForm()) return;
        showLoader(true)
        e.preventDefault()
        try {

            const user = await AuthService.signIn(formData.username, formData.password)
            setCurrentUser(user)
            setLoggedIn(true)
            showLoader(false)
            AuthService.checkIfUserHasPermission([Roles.SuperAdmin], user.role) ? setShowTenantModal(true) : successCallback(user)
        } catch (err: any) {
            showLoader(false)
            setLoggedIn(false)
            showNotification(NotificationTypes.danger, err.message)
            if (err.message) {
                highlightError([{ fieldName: 'username', errorMessage: '' }, { fieldName: 'password', errorMessage: '' }])
                return false
            }
        }
    }
    const onKeyDown = (event: React.KeyboardEvent): void => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            handleSubmit(event);
        }
    }

    return (
        <BaseStartScreen
            title={'Sign In'}
            agreement={''}
            forgot={true}
            link={'/'}
            linkTitle={'test link'}
            formValid={false}
        >
            <div className={`form-group mb-3`}>
                <label htmlFor="username">User Email</label>
                <input
                    type="text"
                    className="form-control"
                    id="username"
                    name="username"
                    value={formData.username}
                    placeholder=""
                    onChange={e => onChange(e)}
                    onKeyDown={onKeyDown}
                />
            </div>
            <div className={`form-group mb-4`}>
                <label htmlFor="password">Password</label>
                <input
                    type="password"
                    className="form-control"
                    id="password"
                    name="password"
                    value={formData.password}
                    placeholder=""
                    onChange={e => onChange(e)}
                    onKeyDown={onKeyDown}
                />
            </div>
            <div className="form-group mb-3">
                <button
                    type="button"
                    className="btn btn-aqua-blue w-100 text-center d-block"
                    onClick={e => handleSubmit(e)}
                >
                    Sign In</button>
            </div>
            {showTenantModal && AuthService.checkIfUserHasPermission([Roles.SuperAdmin], currentUser.role) && 
                <ChooseTenantModal  show={showTenantModal} submitCallback={() => {
                    setShowTenantModal(false)
                    successCallback(currentUser)
                }}/>
            }
            
        </BaseStartScreen>
    )
}

export default SignIn
