import React, {FC, useState, useContext, useEffect} from 'react'
import InputMask from 'react-input-mask';

import { IBaseModalProps } from '../../../common/interfaces/IModal'
import { NotificationTypes } from '../../../common/interfaces/INotification';
import { School } from '../../../common/models/Administration'
import { IValidationBody, ValidationRules } from '../../../common/interfaces/IValidation';
import { AddNewItemEnum } from '../../../common/enums/AddNewItemEnum';
import { IVocabulary } from '../../../common/interfaces/vocabulary/IVocabulary';
import AppContext from '../../../context/AppContext';

import BaseModal from '../BaseModal'
import CustomSelectControl from '../../custom-select/CustomSelectControl'
import { highlightError, removeHighlightError, validateFiled, validatePhoneNumber } from '../../../utils/Validation';
import AddNewItemModal from '../AddNewItemModal'

import { useOnIputChange } from '../../../customHooks/useOnInputChange'
import { useAddNewItem } from '../../../customHooks/useAddNewItem';
import {IJobSchools} from "../../../common/interfaces/jobs/IJob";
import { ISchool } from '../../../common/interfaces/administration/ISchool';
import { useGoogleAPIs } from 'customHooks/useGoogleAPIs';
import { OptionTypeBase } from 'react-select';
import PlacesAutocomplete from 'components/places-autocomplete/PlacesAutocomplete';
import { matchOptionWithName } from 'utils/MatchOptionWithName';
import useCountryField from 'customHooks/useCountryField';

interface Props extends IBaseModalProps {
    vocabulary: IVocabulary[]
    states: IVocabulary[]
    countries: IVocabulary[]
    schoolBoards: Array<{id: number, name: string}>
    boardsCallback: (option: any) => void
    selectBoard?: IJobSchools | undefined
    schoolToEdit?: ISchool
    isEditMode?: boolean
}

const AddSchoolModal: FC<Props> = ({ onCancel, onSubmit, schoolBoards, boardsCallback, selectBoard, isEditMode = false, schoolToEdit }) => {
    const {onChange , hookState: formData, setHookState} = useOnIputChange(schoolToEdit ? schoolToEdit : new School())
    const [showMainModal, setShowMainModal] = useState<boolean>(true)
    const appContext = useContext(AppContext)
    const { showAddNewItemModal, newItemModalTypo, onAddNewItemClick, onAddNewItemSubmit, onAddNewItemModalClose } = useAddNewItem();
    const [selectedBoardId, setSelectedBoardId] = useState(selectBoard?.schoolBoardId);
    const {
        countries,
		populateAddressFields,
		setCountryName,
		findCountyNameById,
		currentStates,
        postalCodeMask,
	} = useCountryField();

    useEffect(() => {
        if (selectBoard && selectBoard.schoolBoardId) {
            const board = `${selectBoard.schoolBoardId}`
            setHookState({
                ...formData,
                schoolBoardId: board
            })
        }
        if (schoolToEdit && schoolToEdit.schoolBoardId) {
            setHookState({
                ...formData,
                schoolBoardId: schoolToEdit.schoolBoardId
            })
        }
    }, [schoolToEdit, selectBoard])

    const validateClientFields = (callback: () => void) => {
        let errors: IValidationBody[] = [];

        for (let [field, value] of Object.entries(formData)) {
            const skipFields = ['id', "secondStreet", "province", "postalCode", "city", "firstAddress"]
            if (!skipFields.includes(field)) {
                errors = [...errors, ...validateFiled(value as string, field, ValidationRules.required)]
            }
        }

        errors = [...errors, ...validateFiled(formData.postalCode as string, "postalCode", ValidationRules.isValidPostalCode)]

        if (errors.length !== 0) {
            appContext.showNotification(NotificationTypes.warning, "Please fill in all fields correctly")
            highlightError(errors)
        } else {
            removeHighlightError()
            callback()
        }
    }

    const onSaveDataClick = () => {
        onSubmit && validateClientFields(() => onSubmit(formData))
    }

    const newSchoolBoardAdded = (option: { id: number, boardName: string }) => {
        onChange([
            { value: option.id, name: "schoolBoardId" },
            {value: option.boardName, name: "boardName"},
        ])
        boardsCallback(option)
    }

    const populateFields = async (response: OptionTypeBase) => {
		populateAddressFields(response, (data) =>
			setHookState((prev) => ({
				...prev,
				...data,
                firstStreet: data.firstAddress,
                firstAddress: undefined,
			}))
		);
	};


    useEffect(() => {
		formData.country && countries.length && setCountryName(matchOptionWithName(formData.country, countries)?.toString() || 'Canada');
		!schoolToEdit && setHookState((prev) => ({
			...prev,
			clientCountry: formData.country || '',
			firstStreet: '',
			postalCode: '',
			province: '',
			city: '',
		}));
	}, [formData.country, schoolToEdit, countries]);

    const onCountrySelected = () => {
        formData.country && setHookState((prev) => ({
			...prev,
			firstStreet: '',
			postalCode: '',
			province: '',
			city: '',
		}));
    }

    useEffect(() => {
        !formData.country && setHookState((prev) => ({
            ...prev,
            country: formData.country || appContext.currentClient.country || ''
        })
        )
    }, [appContext.currentClient.country, formData.country])

    return (
        <>
        <BaseModal
            show={showMainModal}
            onCancel={() => onCancel()}
            onSubmit={() => onSaveDataClick()}
            submitBtnText={'Save'}
            title={isEditMode ? 'Edit School' : 'Add a School'}
        >

            <form autoComplete="off" onSubmit={e => e.preventDefault()}>
                <div className="row">
                    <div className="col-12 form-group">
                        <label className="required" htmlFor="schoolName">School Name</label>
                        <input value={formData.schoolName} onChange={e => onChange(e)} name="schoolName" type="text" id="schoolName" className="form-control" placeholder="Enter school name in full" autoComplete="new-password"/>
                    </div>


                    <div className="col-12 form-group">
                        <label className="required" htmlFor="firstStreet">Address 1: </label>
                        <PlacesAutocomplete
                            placeholder={'Enter address 1'}
                            onChange={async (option, response) => {
                                response && (await populateFields(response));
                                onChange(option, 'firstStreet');
                            }}
                            types={'address'}
                            restrictions={
                                formData.country
                                    ? {
                                            country:
                                                matchOptionWithName(formData.country, countries) ===
                                                'Canada'
                                                    ? 'CA'
                                                    : 'US',
                                        }
                                    : undefined
                            }
                            selectedValue={formData.firstStreet}
                        />
                    </div>

                    <div className="col-12 form-group">
                        <label htmlFor="secondStreet">Address 2: </label>
                        <input value={formData.secondStreet} onChange={e => onChange(e)} name="secondStreet" type="text" id="secondStreet" className="form-control" placeholder="Enter Address 2" autoComplete="new-password"/>
                    </div>

                    <div className="col-12 form-group">
                        <label className="mb-0" htmlFor="city">City: </label>
                        <input value={formData.city} onChange={e => onChange(e)} name="city" type="text" id="city" className="form-control"  placeholder="Enter City" autoComplete="new-password"/>
                    </div>


                    <div className="col-md-4 form-group">
                        <label className="mb-0">Province/State: </label>
                        <CustomSelectControl
                            options={currentStates}
                            onChange={value => onChange(value, "province")}
                            value={formData.province}
                            placeholder="Select"
                            className={`custom-react-select--province`}
                        />
                    </div>

                    <div className="col-md-4 form-group">
                        <label className="mb-0">Postal/Zip Code: </label>
                        <InputMask
                            mask={postalCodeMask}
                            type="text"
                            name="postalCode"
                            className="form-control"
                            id="postalCode"
                            placeholder="Postal code"
                            autoComplete="new-password"
                            value={formData.postalCode}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
                            onBlur={e => validatePhoneNumber(e)}
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <label className="required mb-0" htmlFor="country">
                            Country
                        </label>
                        <CustomSelectControl
                            options={countries}
                            onChange={(value) => {
                                onChange(value, 'country');
                                onCountrySelected();
                            }}
                            value={formData.country}
                            placeholder="Select"
                            className={`custom-react-select--province`}
                        />
                    </div>


                    <div className="col-md-12 form-group">
                        <div className="add-item-group d-flex flex-row align-items-center justify-content-between">
                            <label className="mb-0 required">School Board: </label>
                            <button
                                type="button"
                                className="btn btn-transparent p-0 text-primary ml-auto"
                                onClick={() => {
                                    setShowMainModal(false)
                                    onAddNewItemClick(AddNewItemEnum.board)
                                    setSelectedBoardId(0);
                                }}
                            >
                                Add School Board</button>
                        </div>
                        <CustomSelectControl
                            options={schoolBoards}
                            onChange={(value, label) => onChange([
                                {value: value, name: "schoolBoardId"},
                                {value: label, name: "boardName"},
                            ])}
                            value={selectedBoardId ? selectedBoardId : formData.schoolBoardId}
                            placeholder={"Select a School Board"}
                            className={`custom-react-select--schoolBoardId`}
                        />
                    </div>

                </div>
            </form>

        </BaseModal>

        { showAddNewItemModal && <AddNewItemModal
            showModal={showAddNewItemModal}
            titleBody={newItemModalTypo.title}
            placeholderText={newItemModalTypo.placeholder}
            onCancel={() => {
                onAddNewItemModalClose()
                setShowMainModal(true)
            }}
            onSubmit={newItem => {
                onAddNewItemSubmit({ itemName: newItem }, newSchoolBoardAdded)
                setShowMainModal(true)
            }}
        /> }

        </>
    )
}

export default AddSchoolModal
