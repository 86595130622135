import React, { FC, useContext, useEffect, useState } from 'react'

import { IJobSchedule } from '../../../../common/interfaces/jobs/IJob'
import { ActivationDeactivation, ModelStatus } from '../../../../common/enums/Actions'

import AppContext from '../../../../context/AppContext'
import JobDetailsTabsContext from '../../../../context/jobs/JobDetailsTabsContext'
import useActivateDeactivate from '../../../../customHooks/useActivateDeactivate'
import { momentDateFormat, momentHoursFormat } from '../../../../utils/DateFormatting'
import { matchOptionWithName } from '../../../../utils/MatchOptionWithName'
import { matchWeekDays } from '../../../../utils/MatchWeekDays'

import DeactivateScheduleModalBody from '../../../modal/administration/DeactivateScheduleModalBody'
import BaseModal from '../../../modal/BaseModal'
import DataTable from '../../../tables/DataTable'

import { useVocabulary } from "../../../../customHooks/vocabulary/useVocabulary";
import { VocabularyEnum } from "../../../../common/enums/VocabularyEnum";

import AddEditScheduleModal from '../../../modal/jobs/AddEditScheduleModal'
import { DataService } from '../../../../common/services/DataService'
import { NotificationTypes } from '../../../../common/interfaces/INotification'
import ReportActions from "../../../reports/ReportActions";
import { JobIncludesEnum } from "../../../../common/enums/JobEnums";
import DeactivateModal from '../../../modal/users/DeactivateModal'
import { IVocabulary } from '../../../../common/interfaces/vocabulary/IVocabulary'
import { useRole } from 'customHooks/useRole'
import { useVerifyRolesTHead } from "../../../../customHooks/useVerifyRolesTHead";
import { jobsScheduleTable } from "../../../../content/jobs/JobsContent";
import SchedulesActions from '../SchedulesActions'
import AvailableGuards from 'components/guards/AvailableGuards'
import useAssignGuard from 'customHooks/useAssignGuard'
import useUnassignGuard from 'customHooks/useUnassignGuard'
import ShiftAssignedSuccessModal from 'components/modal/jobs/ShiftAssignedSuccessModal'
import { IAvailableGuard } from 'common/interfaces/guards/IGuard'
import RemoveShiftModal from 'components/modal/jobs/RemoveShiftModal'
import { generateFullName } from 'utils/GenerateFullName'
import { ScheduleModals } from 'common/models/Modals'
import AddShiftToScheduleModal from 'components/modal/jobs/AddShiftToScheduleModal'

type Props = {
    onSuccessScheduleAddEdit: (actionType?: "add" | "edit") => void
}

const JobSchedulesTab: FC<Props> = ({ onSuccessScheduleAddEdit }) => {
    const { isLoading, job, vocabulary: shiftsVocabulary, removeGuardVocabulary, fetchData } = useContext(JobDetailsTabsContext);
    const { showLoader, showNotification } = useContext(AppContext);
    const { onRequestHandler, onRequestSubmitHandler, requestModals, modalText, onCloseRequestModals, itemInFocus } = useActivateDeactivate('schedule');
    const { isClientRole, isAdminRole, isSupervisorRole, isSuperAdminRole } = useRole()
    const { vocabulary: scheduleDeactivation } = useVocabulary(VocabularyEnum.scheduleDeactivation, true)
    const [selectedGuard, setSelectedGuard] = useState<IAvailableGuard>({} as IAvailableGuard);

    const { onAssignGuardSubmit } = useAssignGuard('schedule')
    const { onUnassignGuardSubmit } = useUnassignGuard('schedule')
    const [scheduleInFocus, setScheduleInFocus] = useState<IJobSchedule>({} as IJobSchedule)

    const [scheduleModals, setScheduleModals] = useState<ScheduleModals>(new ScheduleModals({}));

    const dataSvc = new DataService<IJobSchedule>({ url: "schedule" })

    const { theadTable, addVerifyRoles } = useVerifyRolesTHead()

    useEffect(() => {
        addVerifyRoles(jobsScheduleTable.thead)
    }, [jobsScheduleTable])

    const onAddEditScheduleSubmit = async (updatedSchedule: IJobSchedule, actionType?: "add" | "edit") => {
        showLoader(true)
        try {
            actionType === "add" ?
                await dataSvc.create(updatedSchedule) :
                await dataSvc.update(updatedSchedule, updatedSchedule.id as number)
            onSuccessScheduleAddEdit(actionType)
            setScheduleModals(new ScheduleModals({}))
            setScheduleInFocus({} as IJobSchedule)
            // showLoader(false)
        } catch (error: any) {
            showNotification(NotificationTypes.danger, error.message)
            showLoader(false)
        }
    }

    const onAddEditScheduleClick = (actionType: 'add' | 'edit', jobSchedule?: IJobSchedule) => {
        jobSchedule && setScheduleInFocus(jobSchedule)
        setScheduleModals(new ScheduleModals({
            showEdit: actionType === 'edit' ? true : false,
            showAdd: actionType === 'add' ? true : false
        }))
    }

    const onAddChangeGuardClick = (jobSchedule: IJobSchedule, actionType: 'add' | 'edit') => {
        setScheduleInFocus(jobSchedule)
        setScheduleModals(new ScheduleModals({
            showChangeGuard: actionType === 'edit' ? true : false,
            showAddGuard: actionType === 'add' ? true : false
        }))
    }

    const onAddChangeGuardSubmitClick = async (guard: IAvailableGuard) => {
        onAssignGuardSubmit(guard, scheduleInFocus.id!, scheduleModals.showChangeGuard
            ? 'change-guard'
            : 'assign', () => {
                setSelectedGuard(guard)
                fetchData(job.id)
                setScheduleModals(new ScheduleModals({
                    showChangeGuardSuccess: scheduleModals.showChangeGuard,
                    showAddGuardSuccess: scheduleModals.showAddGuard
                }))
            })
    }

    const onUnassignGuardClick = (jobSchedule: IJobSchedule) => {
        setScheduleInFocus(jobSchedule)
        setScheduleModals(new ScheduleModals({ showUnassignGuard: true }))
    }

    const onUnassignGuardSubmitClick = (reason: string | IVocabulary) => {
        onUnassignGuardSubmit(reason, scheduleInFocus.id!, async () => {
            await fetchData(job.id)
            setScheduleModals(new ScheduleModals({ showUnassignGuardSuccess: true }))
            showLoader(false)
        }, 'unassign', true)
    }

    const onAddAShiftClick = (jobSchedule: IJobSchedule) => {
        setScheduleModals(new ScheduleModals({ showAddShift: true }))
        setScheduleInFocus(jobSchedule)
    }

    return (
        <div>
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                <h5 className="mb-md-0">Job Schedules</h5>
                <div className="d-flex flex-row">
                    <ReportActions title="Job Schedules:" id={job.id} tabName={JobIncludesEnum.schedules} page="job" hidePrint showForShiftReport={false} />
                    {!isSupervisorRole() &&
                        <button className="btn btn-aqua-blue ml-2" onClick={() => onAddEditScheduleClick('add')}>Add a Schedule</button>
                    }
                </div>
            </div>
            {
                React.useMemo(() => (
                    <DataTable
                        thead={theadTable}
                        tbody={
                            job.jobSchedules ? job.jobSchedules.map(schedule => (
                                {
                                    id: schedule.id,
                                    assignedGuardLink: (schedule.assignedGuard && schedule.guardId)
                                        ? `<a href="#/guards/${schedule.guardId}/details">${schedule.assignedGuard}</a>` : '-',
                                    dates: `${momentDateFormat(schedule.startDate)} - ${momentDateFormat(schedule.endsOn)}`,
                                    aspStartDate: schedule.aspStartDate ? momentHoursFormat(schedule.aspStartDate, schedule.timeFrom, true) : "-",
                                    times: `${momentHoursFormat(schedule.timeFrom)} - ${momentHoursFormat(schedule.timeTo, schedule.timeFrom)}`,
                                    shiftPeriod: matchOptionWithName(schedule.shiftPeriod, shiftsVocabulary),
                                    repeatOn: `Every week: ${matchWeekDays(schedule.repeatOn)}`,
                                    isActive: schedule.isActive,
                                    guardId: schedule.guardId
                                }
                            )) : []}
                        ignoreCols={[0, 7, 8, ...(isClientRole() || isSupervisorRole() ? [3] : [])]}
                        ignoreTheadCols={[...(isClientRole() || isSupervisorRole() ? [2,6] : [])]}
                        isLoading={isLoading}
                    >
                        {
                            (_id, rowItem: IJobSchedule, rowIndex: number) => (
                                !isClientRole() && !isSupervisorRole() &&
                                <td className="aling-middle">
                                    {
                                        (rowItem.isActive === ModelStatus.pending || rowItem.isActive === ModelStatus.inactive)
                                            ?
                                            <div>
                                                <span className="font-weight-bold text-uppercase">
                                                    {rowItem.isActive === ModelStatus.pending ? ModelStatus.pending : ModelStatus.inactive}
                                                </span>
                                            </div>
                                            :
                                            <SchedulesActions
                                                onEditSchedule={() => onAddEditScheduleClick('edit', job.jobSchedules[rowIndex])}
                                                onDeactivateSchedule={() => {
                                                    onRequestHandler({
                                                        title: `Are you sure you want to deactivate selected schedule for <span class="font-weight-bold">${job.jobName}</span>?`,
                                                        itemInFocus: job.jobSchedules[rowIndex],
                                                        type: ActivationDeactivation.deactivate
                                                    })
                                                }}
                                                onAddAShift={() => onAddAShiftClick(job.jobSchedules[rowIndex])}
                                                onAddChangeGuard={() => onAddChangeGuardClick(job.jobSchedules[rowIndex], !!rowItem.guardId ? 'edit' : 'add')}
                                                onRemoveGuard={() => onUnassignGuardClick(job.jobSchedules[rowIndex])}
                                                scheduleWithGuard={!!rowItem.guardId}
                                            />
                                    }

                                </td>
                            )
                        }
                    </DataTable>
                ), [job.jobSchedules, shiftsVocabulary, scheduleDeactivation])
            }

            {/* Add/Edit Guard for Schedule */}
            {
                (scheduleModals.showChangeGuard || scheduleModals.showAddGuard) && <BaseModal
                    show={(scheduleModals.showChangeGuard || scheduleModals.showAddGuard)}
                    onCancel={() => setScheduleModals(new ScheduleModals({}))}
                    className="available-guards-modal"
                    cancelBtnText={'Cancel'}
                >
                    <AvailableGuards
                        onSubmit={guard => onAddChangeGuardSubmitClick(guard)}
                        title={`${scheduleModals.showChangeGuard ? 'Change ' : 'Add '}
                        Guard for Selected Job Schedule for <span class="font-weight-bold">${job.jobName}</span>`}
                        selectedItem={scheduleInFocus}
                        itemHasEndDate
                        scheduleAssign
                    />
                </BaseModal>
            }

            {
                (scheduleModals.showChangeGuardSuccess || scheduleModals.showAddGuardSuccess) &&
                <ShiftAssignedSuccessModal
                    show={(scheduleModals.showChangeGuardSuccess || scheduleModals.showAddGuardSuccess)}
                    onClose={() => setScheduleModals(new ScheduleModals({}))}
                    selectedGuard={selectedGuard}
                    selectedShift={{
                        ...scheduleInFocus,
                        jobName: job.jobName,
                        replacedGuard: scheduleInFocus.assignedGuard
                    }}
                    vocabulary={shiftsVocabulary}
                    title={`​You have successfully ${scheduleModals.showAddGuardSuccess ? 'added' : 'updated the assigned '} guard for the selected job schedule:`}
                    warning={`A notification has been sent to the guard with the details of this update.`}
                    isSchedule
                />
            }

            {

                requestModals.showRequest && <DeactivateModal
                    onCancel={() => onCloseRequestModals()}
                    onSubmit={(reason: string | IVocabulary) => onRequestSubmitHandler(
                        ActivationDeactivation.deactivate,
                        { itemData: null, successCallback: () => fetchData(job.id) },
                        `${isAdminRole() || isSuperAdminRole() ? `<span class="font-weight-bold">${job.jobName}</span> schedule has been deactivated.` : `Your request to deactivate <span class="font-weight-bold">${job.jobName}</span> schedule has been sent to the Administrator.`} `,
                        reason
                    )}
                    title={modalText.title}
                    vocabulary={scheduleDeactivation}
                    htmlBody={<DeactivateScheduleModalBody selectedItem={itemInFocus as IJobSchedule} />}
                />
            }

            {
                requestModals.showSuccess &&
                <BaseModal
                    show={requestModals.showSuccess}
                    onCancel={() => onCloseRequestModals()}
                    cancelBtnText={"Close"}
                    title={modalText.title}
                >
                </BaseModal>
            }

            {
                (scheduleModals.showEdit || scheduleModals.showAdd) && <AddEditScheduleModal
                    onCancel={() => setScheduleModals(new ScheduleModals({}))}
                    onSubmit={(schedule, action) => onAddEditScheduleSubmit(schedule as IJobSchedule, action)}
                    schedule={scheduleModals.showEdit ? scheduleInFocus : undefined}
                    descriptionNote={scheduleModals.showEdit ? 'When a schedule is edited, the affected future shifts will be removed, and new ones will be created. Removed shifts will be stored in the archive records. The same guard is automatically assigned to the new schedule.' :
                        'When a schedule is added, there is no check for overlap with an existing schedule or shifts for this job. You will be asked to assign a Guard.'}
                />
            }

            {/* Remove Guard */}
            <>
                {

                    scheduleModals.showUnassignGuard &&
                    <RemoveShiftModal
                        titleBody={`Are you sure you want to remove <span class="font-weight-bold">${generateFullName(scheduleInFocus)}</span> from this schedule?`}
                        show={scheduleModals.showUnassignGuard}
                        onClose={() => setScheduleModals(new ScheduleModals({}))}
                        onSubmit={(reason) => onUnassignGuardSubmitClick(reason as string | IVocabulary)}
                        reasons={removeGuardVocabulary}
                        jobName={job.jobName as string}
                        shift={scheduleInFocus}
                        shifts={shiftsVocabulary}
                        warning={"Reminder to replace this guard in the job schedule."}
                    />
                }
                {
                    scheduleModals.showUnassignGuardSuccess && <BaseModal
                        show={scheduleModals.showUnassignGuardSuccess}
                        onCancel={() => setScheduleModals(new ScheduleModals({}))}
                        cancelBtnText={'Close'}
                        title={`
                            <span class="font-weight-bold">${generateFullName(scheduleInFocus)}</span> has been removed from 
                            <span class="font-weight-bold">${job.jobName}</span> schedule 
                        `}
                    >
                    </BaseModal>
                }
            </>

            {/* Add Shift To Schedule Modal */}
            {
                scheduleModals.showAddShift &&
                <AddShiftToScheduleModal
                    jobName={job.jobName || ""}
                    jobSchedule={scheduleInFocus}
                    shiftVocabulary={shiftsVocabulary}
                    onCancel={() => setScheduleModals(new ScheduleModals({}))}
                />
            }
        </div>
    )
}

export default JobSchedulesTab
