import { TAutofillCallbackArg } from "common/interfaces/maps/IUseGoogleAPIs";
import moment from "moment";

export const useGoogleAPIs = () => {

    const loadGoogleMaps = (callback?: () => void) => {
        const existingScript = document.getElementById("googlePlacesScript");
        if (!existingScript) {
            const script = document.createElement("script");
            script.src =
                `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_KEY}&libraries=places`;
            script.id = "googleMaps";
            document.body.appendChild(script);
            script.onload = () => {
                if (callback) callback();
            };
        }
        if (existingScript && callback) callback();
    };

    const unloadGoogleMaps = () => {
        let googlePlacesScript = document.getElementById("googlePlacesScript");
        if (googlePlacesScript) {
            googlePlacesScript.remove();
        }
    };

    const getPlaceDetails = async (placeId: string,
        callBack: (place: TAutofillCallbackArg) => void) => {
        const dummyMapDiv = document.createElement("div")
        const service = new google.maps.places.PlacesService(dummyMapDiv)
        const params = { placeId }

        const addressTypes = {
            postalCode: "postal_code",
            country: "country",
            state: "administrative_area_level_1",
            city2: "administrative_area_level_2",
            city3: "administrative_area_level_3",
            city: "locality",
            address: "route",
            street: "street_number"
        }
        
        service.getDetails(params, (place, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK && place) {
                const addressHelper = (addresstype: string) =>  place?.address_components?.find((item) => 
                item.types.includes(addresstype));
                console.log(place)
                callBack({
                    address: addressHelper(addressTypes.address),
                    postalCode: addressHelper(addressTypes.postalCode),
                    country: addressHelper(addressTypes.country),
                    state: addressHelper(addressTypes.state),
                    city: addressHelper(addressTypes.city) || addressHelper(addressTypes.city3) || addressHelper(addressTypes.city2),
                    street: addressHelper(addressTypes.street),
                    placeCoordinates: {
                        lat: place.geometry?.location?.lat() || 0,
                        lng: place.geometry?.location?.lng() || 0,
                    }
                });
            }
        })

        return
    }

    const getTimeZone = async (lat: number, lng: number) => {
        const baseUrl = 'https://maps.googleapis.com/maps/api/timezone/json?location='
        try {
            const response = await fetch(`${baseUrl}${lat},${lng}&timestamp=${moment().unix()}&key=${process.env.REACT_APP_MAP_KEY}`)
            const data = await response.json();
            // - data.dstOffset
            return ((data.rawOffset) + data.dstOffset) as number
        } catch (error) {
            // Temporary
            console.log(error)
        }
    }

    return {
        getPlaceDetails, getTimeZone, loadGoogleMaps, unloadGoogleMaps
    }
}