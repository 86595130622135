import { useState } from "react";
import { queryUrlXGuardReports } from "../../helpers/XGuardReportQueryUrl";

interface IBaseFilter {
  [index: string]: string
}

interface IProps{
  baseUrl: string
  defaultParams?: any
  urlAlreadyHasQuery?: boolean
  typeIsMulti?: boolean
}

const useXGuardReportsQuery = <T extends IBaseFilter>({ baseUrl, defaultParams,
  urlAlreadyHasQuery = false, typeIsMulti = true}: IProps) => {
  const [queryParams, setQueryParams] = useState<T>(defaultParams ? defaultParams : {} as T)

  const onQueryParamChanged = (value: string | number | boolean | undefined, queryName: string) => {
    setQueryParams(prev => ({
      ...prev, [queryName]: value || ''
    } as T))
  }

  const generateQueryParams = (extraParams?: {[key: string]: string}): string => {
    let query = queryParams ? `${baseUrl}?` : baseUrl;
    query+= queryUrlXGuardReports({...queryParams, ...extraParams}, typeIsMulti);
    return query.replace('?&', urlAlreadyHasQuery ? '&' : '?');
  }

  return {
    onQueryParamChanged, queryParams, generateQueryParams
  }

}

export default useXGuardReportsQuery;
