import React, { FC } from 'react'
import { SVGViewOnMap, SVGEraser } from '../../assets/icons/SvgIcons'
import CustomSelectControl from '../custom-select/CustomSelectControl'
import { showNextDropdownOptions, checkInTypeDropdownOptions, checkOutTypeDropdownOptions } from '../../content/dashboard/Dropdowns'
import { IVocabulary } from '../../common/interfaces/vocabulary/IVocabulary'
import { useRole } from 'customHooks/useRole'

type TProps = {
    setActiveJobsOnMapModal: (status: boolean) => void
    filter: any
    setFilter: (filter: any) => void
    jobsListFilter: any
    shiftVocabulary: IVocabulary[]
}

const FilterJobList: FC<TProps> = ({ setActiveJobsOnMapModal, filter, setFilter, jobsListFilter,
    shiftVocabulary, }) => {

    const { isClientRole } = useRole()

    return (
        <div className="col-12">
            {
                React.useMemo(() => (
                    <form className="filters-form-group filters-form-group--dashboard mb-0">
                        <div className="d-flex mb-3 flex-column flex-md-row align-items-md-center">
                            <h5 className="mb-0">Filter Job List</h5>
                            <div className="d-flex flex-row flex-fill mt-2 mt-md-0">
                                <button
                                    type="button"
                                    className="btn btn-outline-aqua-blue ml-md-3 d-flex align-items-center"
                                    onClick={() => setActiveJobsOnMapModal(true)}
                                >
                                    <span className="d-flex align-items-center">
                                        View Map
                                    </span>
                                    <SVGViewOnMap className="ml-3" />
                                </button>
                                {
                                    (filter.minutesFromNow !== 30 || filter.checkInType || filter.checkOutType || filter.zone || filter.shiftPeriod || filter.schoolBoardId) ? <button
                                        type="button"
                                        className="btn btn-aqua-blue ml-2 ml-md-auto mr-md-1"
                                        onClick={() => setFilter({
                                            ...filter,
                                            minutesFromNow: 30,
                                            checkInType: 0,
                                            checkOutType: 0,
                                            zone: 0,
                                            shiftPeriod: 0,
                                            schoolBoardId: 0,
                                            keyWord: ''
                                        })}>
                                        <SVGEraser />
                                    </button>
                                        : null
                                }
                            </div>
                        </div>
                        <div className="d-flex flex-row flex-wrap flex-lg-nowrap">
                            <CustomSelectControl
                                options={showNextDropdownOptions}
                                value={jobsListFilter.filter.minutesFromNow}
                                placeholder={"Select View next"}
                                onChange={e => jobsListFilter.onFilterDropdownChange(e, "minutesFromNow")}
                                className="flex-grow-0 mx-1 mb-2 mb-xl-0 custom-react-select--showNext"
                            />
                            <CustomSelectControl
                                isClearable={true}
                                options={shiftVocabulary || []}
                                value={jobsListFilter.filter.shiftPeriod}
                                placeholder={"Select Shift"}
                                onChange={e => jobsListFilter.onFilterDropdownChange(e, "shiftPeriod")}
                                className="flex-grow-0 mx-1 mb-2 mb-xl-0 custom-react-select--small"
                            />
                            <CustomSelectControl
                                isClearable={true}
                                options={jobsListFilter.zones || []}
                                value={jobsListFilter.filter.zone}
                                sort
                                placeholder={"Select Zone"}
                                onChange={e => jobsListFilter.onFilterDropdownChange(e, "zone")}
                                className="flex-grow-0 mx-1 mb-2 mb-xl-0 custom-react-select--small"
                            />
                            <CustomSelectControl
                                isClearable={true}
                                options={jobsListFilter.schoolBoards || []}
                                value={jobsListFilter.filter.schoolBoardId}
                                placeholder={"Select School Board"}
                                onChange={e => jobsListFilter.onFilterDropdownChange(e, "schoolBoardId")}
                                className="flex-grow-0 mx-1 mb-2 mb-xl-0"
                            />
                            <CustomSelectControl
                                options={checkInTypeDropdownOptions}
                                value={jobsListFilter.filter.checkInType}
                                placeholder={"Select Check-In status"}
                                onChange={e => jobsListFilter.onFilterDropdownChange(e, "checkInType")}
                                className="flex-grow-0 mx-1 mb-2 mb-xl-0 custom-react-select--checkType"
                                hiddenOptions={isClientRole() ? [4] : []}
                            />
                            <CustomSelectControl
                                options={checkOutTypeDropdownOptions}
                                value={jobsListFilter.filter.checkOutType}
                                placeholder={"Select Check-Out status"}
                                onChange={e => jobsListFilter.onFilterDropdownChange(e, "checkOutType")}
                                className="flex-grow-0 mx-1 mb-2 mb-xl-0 custom-react-select--checkType"
                            />
                        </div>
                    </form>
                ), [jobsListFilter])
            }
        </div>
    )
}

export default FilterJobList