import { Roles } from '../common/enums/Roles';

export type DropDownMenuLink = {
  title: string;
  link: string;
  invisible?: Array<Roles>;
  subLinks?: Array<SubLink>;
};

export type SubLink = {
  title: string;
  link: string;
  invisible?: Array<Roles>;
};

export type DropdownsContentType = {
  [index: number]: {
    title: string;
    invisible?: Array<Roles>;
    linkRoute: string;
    menuLinks?: Array<DropDownMenuLink> | null;
  };
};

export const dropdownsContent: DropdownsContentType = {
  1: {
    title: 'Jobs',
    linkRoute: '/jobs',
    menuLinks: [
      {
        title: 'Job Schedules',
        link: '/jobs/schedules',
        subLinks: [
          {
            title: 'Edit Job Schedules',
            link: '/jobs/schedules/edit',
            invisible: [Roles.Client, Roles.Supervisor]
          },
          {
            title: 'Pending Job Schedules',
            link: '/jobs/duplicate/pending',
            invisible: [Roles.Client, Roles.Supervisor]
          },
          {
            title: 'Deactivation Requests for Schedules',
            link: '/jobs/job-schedules/schedules/deactivate',
            invisible: [Roles.Client, Roles.Supervisor]
          },
          {
            title: 'Deactivated Schedules',
            link: '/jobs/job-schedules/deactivated',
            invisible: [Roles.Client, Roles.Supervisor]
          },
        ],
      },
      {
        title: 'Job Shifts',
        link: '#',
        invisible: [Roles.Client, Roles.Supervisor],
        subLinks: [
          {
            title: 'Unassigned Job Shifts',
            link: 'jobs/unassigned',
          },
          {
            title: 'Edit/View Job Shifts',
            link: '/jobs/shifts/edit',
            invisible: [Roles.Client, Roles.Supervisor]
          },
          {
            title: "View Job Shifts",
            link: "/jobs/shifts/view",
            invisible: [Roles.ASP, Roles.Admin, Roles.SuperAdmin]
          },
        ],
      },
      {
        title: 'Job Shifts',
        link: '#',
        invisible: [Roles.ASP, Roles.Admin, Roles.SuperAdmin],
        subLinks: [
          {
            title: 'Unassigned Job Shifts',
            link: 'jobs/unassigned',
          },
          {
            title: "View Job Shifts",
            link: "/jobs/shifts/view",
            invisible: [Roles.ASP, Roles.Admin]
          },
        ],
      },
      {
        title: 'Manage Jobs',
        link: '#',
        invisible: [Roles.Client, Roles.Supervisor],
        subLinks: [
          {
            title: "Add a Job",
            link: "/jobs/add",
            invisible: [Roles.Client, Roles.Supervisor]
          },
          {
            title: 'Deactivation Requests for Jobs',
            link: '/jobs/deactivate',
            invisible: [Roles.Client, Roles.Supervisor]
          },
          {
            title: 'Deactivated Jobs',
            link: '/jobs/deactivated',
            invisible: [Roles.Client, Roles.Supervisor]
          },
          {
            title: 'Duplicate Jobs',
            link: '/jobs/duplicate',
            invisible: [Roles.Client, Roles.Supervisor]
          },
        ],
      },
    ],
  },
  2: {
    title: 'Guards',
    linkRoute: '/guards',
    menuLinks: [
      {
        title: 'Add Guard',
        link: '/guards/add',
        invisible: [Roles.Client, Roles.Supervisor],
      },
      {
        title: 'Deactivation Requests for Guards',
        link: '/guards/deactivate',
        invisible: [Roles.Client, Roles.Supervisor],
      },
      {
        title: 'Deactivated Guards',
        link: '/guards/deactivated',
        invisible: [Roles.Client, Roles.Supervisor],
      },
    ],
  },
  3: {
    title: 'Clients',
    linkRoute: '/clients',
    invisible: [Roles.Supervisor, Roles.Client, Roles.ASP, Roles.Admin],
    menuLinks: [
      {
        title: 'Add Client',
        link: '/clients/add',
        invisible: [Roles.Client],
      },
    ],
  },
  4: {
    title: 'Reports',
    linkRoute: '/reports',
    
    menuLinks: [
      {
        title: 'Shift Report',
        link: '/reports/shift',
        invisible: [Roles.Client],
      },
      // {
      //   title: 'Guard Report',
      //   link: '/reports/guard',
      //   invisible: [Roles.Client]
      // }, //! temp hide 
      {
        title: 'Attendance Report',
        link: '/reports/dailyattendance',
        // invisible: [Roles.Client],
      },
      {
        title: 'Covid Screenings',
        link: '/reports/guard-tests',
        invisible: [Roles.Client],
      },
      {
        title: 'Health and Safety Checks',
        link: '/reports/health-checks',
        invisible: [Roles.Client],
      },
      // {
      //   title: 'Guard Location Report',
      //   link: '/reports/guard-location',
      //   invisible: [Roles.Client, Roles.Supervisor],
      // },
      {
        title: 'Guard Location Tracking',
        link: '/reports/location-tracking',
        invisible: [Roles.Client],
      },
      {
        title: 'Incident Reports',
        link: '/reports/incidents',
        // invisible: [Roles.Client, Roles.ASP],
      },
      {
        title: 'Arrival Reports',
        link: '/reports/guard-arrival',
        invisible: [Roles.Client],
      },
      {
        title: 'Messages from Guards',
        link: '/reports/guard-messages',
        invisible: [Roles.Client],
      },
      {
        title: 'Guard Leave Report',
        link: '/reports/leave-requests',
        invisible: [Roles.Client, Roles.Supervisor],
      },
      {
        title: 'Guard App Profile Update Requests',
        link: '/reports/profile-update-requests',
        invisible: [Roles.Client, Roles.Supervisor],
      },
    ],
  },
  5: {
    title: 'Administration',
    linkRoute: '/administration',
    invisible: [Roles.Client, Roles.Supervisor],
    menuLinks: [
      {
        title: 'Users',
        link: '/administration/manage-users',
        invisible: [Roles.Client],
        subLinks: [
          {
            title: 'Deactivation Requests for Users',
            link: '/administration/manage-users/pending',
            invisible: [Roles.Client, Roles.ASP]
          },
          {
            title: 'Deactivated Users',
            link: '/administration/manage-users/deactivated-users',
            invisible: [Roles.Client, Roles.ASP]
          },
          {
            title: 'Devices',
            link: '/administration/devices',
            invisible: [Roles.Client]
          },
        ],
      },
      {
        title: 'Settings',
        link: '/administration/settings',
        invisible: [Roles.Client],
        subLinks: [
          {
            title: 'Schools and Boards',
            link: '/administration/schools-and-boards',
            invisible: [Roles.Client]
          },
          {
            title: 'Holidays',
            link: '/administration/holidays',
            invisible: [Roles.Client]
          },
          {
            title: 'Notifications',
            link: '/administration/notifications',
            invisible: [Roles.Client]
          },
          {
            title: 'Dropdown Options',
            link: '/administration/dropdown',
            invisible: [Roles.Client]
          },
          {
            title: 'School Years',
            link: '/administration/schools-years',
            invisible: [Roles.Client]
          },
        ],
      },
      {
        title: 'Activity Report',
        link: '/administration/activity-report',
        invisible: [Roles.Client, Roles.ASP],
      },
      {
        title: 'Deactivation Requests',
        link: '#',
        invisible: [Roles.Client],
        subLinks: [
          {
            title: "Jobs",
            link: "/jobs/deactivate",
            invisible: [Roles.Client]
          },
          {
            title: 'Guards',
            link: '/guards/deactivate',
            invisible: [Roles.Client]
          },
          {
            title: 'Schedules',
            link: '/jobs/job-schedules/schedules/deactivate',
            invisible: [Roles.Client]
          }
        ],
      },
      // {
      //   title: 'Tenants',
      //   link: '/administration/tenants',
      //   invisible: [Roles.ASP, Roles.Admin, Roles.Client, Roles.Supervisor]
      // }
    ],
  },

};
