import { SVGCircleCheck } from 'assets/icons/SvgIcons';
import React, { FC } from 'react';
type Props = {
	checked: boolean;
	disabled: boolean;
	onChange: () => void;
	id: string | number;
	label?: string;
};
const CustomCheckBox: FC<Props> = ({ checked, disabled, onChange, id, label }: Props) => {
	const renderCheckbox = () => (
		<div className={`circle-checkbox ${label ? 'd-flex align-items-center' : ''}`}>
			<input
				type='checkbox'
				className='circle-checkbox__checkbox'
				id={String(id)}
				checked={checked}
				onChange={onChange}
				disabled={disabled}
			/>
			<label
				tabIndex={!disabled ? 0 : undefined}
				className={`circle-checkbox__check m-0 p-0 ${
					disabled ? 'circle-checkbox__check--disabled' : ''
				}`}
				htmlFor={String(id)}></label>
			{label && <label htmlFor={String(id)} className="mb-0 ml-2">{label}</label>}
		</div>
	);
	return !disabled ? renderCheckbox() : checked ? <SVGCircleCheck /> : renderCheckbox();
};

export default CustomCheckBox;
