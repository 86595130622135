import React from 'react';
import { FC, useEffect, useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { LatLng } from 'react-google-places-autocomplete/build/GooglePlacesAutocomplete.types';
import { OptionTypeBase, ValueType } from 'react-select';
import { Option } from 'react-select/src/filters';
import { isObjectEmpty } from 'utils/IsObjEmpty';

type Props = {
	placeholder: string;
	selectedValue?: string;
	defaultValue?: string;
	onChange: (value: string, responseOption?: OptionTypeBase | null) => void;
	types: 'address' | '(regions)' | '(cities)';
	restrictions?: { country: string | string[] };
	limitOptionsBy?: string;
	className?: string;
	isMemberSide?: boolean;
	location?: LatLng;
	radius?: number;
};

const PlacesAutocomplete: FC<Props> = ({
	placeholder,
	onChange,
	selectedValue,
	types,
	restrictions,
	limitOptionsBy,
	location,
	radius,
}) => {
	const [dropdownValue, setDropdowndValue] = useState<{ value: string | null; label: string | null } | null>(
		selectedValue
			? Object.assign({
					value: selectedValue,
					label: selectedValue,
			  })
			: null
	);

	useEffect(() => {
		setDropdowndValue(
			selectedValue
				? {
						value: selectedValue,
						label: selectedValue,
				  }
				: null
		);
	}, [selectedValue]);

	const onAutocompleteSelectedHandler = React.useCallback(
		(option: ValueType<OptionTypeBase, false> | null) => {
			const parsedOpt = {
				label: option?.value.structured_formatting.main_text || '',
				value: option?.value.structured_formatting.main_text || '',
			};
			onChange(parsedOpt.label || '', option);
			setDropdowndValue(option ? parsedOpt : null);
		},
		[onChange]
	);

	const filterDropdownOptions = React.useMemo(
		() => (option: Option) => {
			if (limitOptionsBy === undefined || limitOptionsBy === null || limitOptionsBy.trim().length === 0)
				return true;
			if (option.data.value.types.includes(limitOptionsBy)) {
				return true;
			} else if (option.data.label.includes(limitOptionsBy)) {
				return true;
			} else return false;
		},
		[limitOptionsBy]
	);

	return (
		<GooglePlacesAutocomplete
			apiKey={process.env.REACT_APP_MAP_KEY as string}
			autocompletionRequest={{
				componentRestrictions: restrictions ? restrictions : { country: ['US', 'CA'] },
				types: [types],
				location: isObjectEmpty(location || {}) ? undefined : location,
				radius: !isObjectEmpty(location || {})  ? radius || 10000 : undefined
			}}
			selectProps={{
				value: dropdownValue,
				onChange: (value) => onAutocompleteSelectedHandler(value),
				isClearable: true,
				className: 'google-places-container',
				classNamePrefix: 'google-places',
				placeholder: placeholder,
				components: { DropdownIndicator: () => null, IndicatorSeparator: () => null },
				filterOption: (option) => filterDropdownOptions(option),
				escapeClearsValue: true,
				noOptionsMessage: () => null,
				onFocus: (ev) => ev.target.setAttribute('autocomplete', 'new-password'),
			}}
		/>
	);
};

export default PlacesAutocomplete;
