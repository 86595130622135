import { Link } from 'react-router-dom'
import Layout from '../../components/layout/Layout'
import SectionTitle from '../../components/titles/SectionTitle'
import ShiftsIcon  from 'assets/icons/reports/shifts.svg';
import AttendanceIcon  from 'assets/icons/reports/attendance.svg';
import SafetycheckIcon  from 'assets/icons/reports/safetycheck.svg';
import IncidentIcon  from 'assets/icons/reports/incident.svg';
import ArrivalIcon  from 'assets/icons/reports/arrival.svg';
import MessagesIcon  from 'assets/icons/reports/guardmessage.svg';
import CovidIcon  from 'assets/icons/reports/covid.svg';
import LocationTrackingIcon  from 'assets/icons/reports/guardlocation.svg';
import LeaveRequestsIcon  from 'assets/icons/reports/leaverequest.svg';
import ProfileRequestsIcon  from 'assets/icons/reports/profilechangerequest.svg';
import GuardLocationTrackingIcon  from 'assets/icons/reports/guardlocationtracking.svg';
import { useRole } from 'customHooks/useRole';

const Reports = () => {
    // TODO: Improve Code logic
    const { isSupervisorRole, isClientRole } = useRole();

    return (
        <Layout
            breadcrumbs={{ currentPageTitle: 'Reports' }}
        >
            <div className="row mb-5">
                <div className="col-12">
                    <SectionTitle title="Reports"/>
                </div>
            </div>

            <div className="administration row">
                <div className="col-12 mx-auto d-flex flex-row flex-wrap justify-content-center ">

                {  !isClientRole() &&
                    <div className="administration__item text-center mx-2 mb-4">
                        <Link to="/reports/shift" className="text-decoration-none">
                            <div className="administration__item__circle d-flex justify-content-center align-items-center">
                                <img src={ShiftsIcon} alt="Shifts Icon" />
                            </div>
                            <h5 className="font-weight-bold text-primary pt-3">Shifts </h5>
                        </Link>
                    </div>
                }

                    <div className="administration__item text-center mx-2 mb-4">
                        <Link to="/reports/dailyattendance" className="text-decoration-none">
                            <div className="administration__item__circle d-flex justify-content-center align-items-center">
                                <img src={AttendanceIcon} alt="Attendence Icon" />
                            </div>
                            <h5 className="font-weight-bold text-primary pt-3">Daily Attendance</h5>
                        </Link>
                    </div>

                {  !isClientRole() &&
                    <div className="administration__item text-center mx-2 mb-4">
                        <Link to="/reports/guard-tests" className="text-decoration-none">
                            <div className="administration__item__circle d-flex justify-content-center align-items-center">
                                <img src={CovidIcon} alt="Attendence Icon" />
                            </div>
                            <h5 className="font-weight-bold text-primary pt-3">Covid Screenings</h5>
                        </Link>
                    </div>
                }

                    {  !isClientRole() &&
                    <div className="administration__item text-center mx-2 mb-4">
                        <Link to="/reports/health-checks" className="text-decoration-none">
                            <div className="administration__item__circle d-flex justify-content-center align-items-center">
                                <img src={SafetycheckIcon} alt="Health and Safety Checks Icon" />
                            </div>
                            <h5 className="font-weight-bold text-primary pt-3">Health and Safety Checks</h5>
                        </Link>
                    </div>
                    }

                    <div className="administration__item text-center mx-2 mb-4">
                        <Link to="/reports/incidents" className="text-decoration-none">
                            <div className="administration__item__circle d-flex justify-content-center align-items-center">
                                <img src={IncidentIcon} alt="Incident Icon" />
                            </div>
                            <h5 className="font-weight-bold text-primary pt-3">Incident Reports</h5>
                        </Link>
                    </div>

                    {  !isClientRole() &&
                    <div className="administration__item text-center mx-2 mb-4">
                        <Link to="/reports/guard-arrival" className="text-decoration-none">
                            <div className="administration__item__circle d-flex justify-content-center align-items-center">
                                <img src={ArrivalIcon} alt="Arrival Icon" />
                            </div>
                            <h5 className="font-weight-bold text-primary pt-3">Arrival Reports</h5>
                        </Link>
                    </div>
                    }

                    {  !isClientRole() &&
                    <div className="administration__item text-center mx-2 mb-4">
                        <Link to="/reports/guard-messages" className="text-decoration-none">
                            <div className="administration__item__circle d-flex justify-content-center align-items-center">
                                <img src={MessagesIcon} alt="Messages Icon" />
                            </div>
                            <h5 className="font-weight-bold text-primary pt-3">
                                Messages from Guards
                            </h5>
                        </Link>
                    </div>
                    }

                    {/* { !isSupervisorRole() && !isClientRole() &&
                    <div className="administration__item text-center mx-2 mb-4">
                        <Link to="/reports/guard-location" className="text-decoration-none">
                            <div className="administration__item__circle d-flex justify-content-center align-items-center">
                                <img src={LocationTrackingIcon} alt="Guard Location Icon" />
                            </div>
                            <h5 className="font-weight-bold text-primary pt-3">Guard Location Reports</h5>
                        </Link>
                    </div>
                    } */}

                    {  !isClientRole() &&
                    <div className="administration__item text-center mx-2 mb-4">
                        <Link to="/reports/location-tracking" className="text-decoration-none">
                            <div className="administration__item__circle d-flex justify-content-center align-items-center">
                                <img src={GuardLocationTrackingIcon} alt="Guard Location Tracking Icon" />
                            </div>
                            <h5 className="font-weight-bold text-primary pt-3">Guard Location Tracking</h5>
                        </Link>
                    </div>
                    }

                    { !isSupervisorRole() && !isClientRole() &&
                    <div className="administration__item text-center mx-2 mb-4">
                        <Link to="/reports/leave-requests" className="text-decoration-none">
                            <div className="administration__item__circle d-flex justify-content-center align-items-center">
                                <img src={LeaveRequestsIcon} alt="Guard App Leave Requests Icon" />
                            </div>
                            <h5 className="font-weight-bold text-primary pt-3">Guard Leave Report</h5>
                        </Link>
                    </div>
                    }

                    { !isSupervisorRole() && !isClientRole() &&
                    <div className="administration__item text-center mx-2 mb-4">
                        <Link to="/reports/profile-update-requests" className="text-decoration-none">
                            <div className="administration__item__circle d-flex justify-content-center align-items-center">
                                <img src={ProfileRequestsIcon} alt="Guard App Profile Update Requests Icon" />
                            </div>
                            <h5 className="font-weight-bold text-primary pt-3">Guard App Profile Update Requests</h5>
                        </Link>
                    </div>
                    }

                </div>
            </div>
        </Layout>
    )
}

export default Reports
