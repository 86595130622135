export enum AddNewItemEnum {
    city = 'city',
    zone = 'zone',
    board = 'board',
    holidayType = 'holidayType',
    holidayName = 'holidayName',
    clientContact = 'clientContact',
    GuardDeactivationReason = 'GuardDeactivationReason',
    GuardActivationReason = 'GuardActivationReason',
    GuardAbsenceReason = 'GuardAbsenceReason',
    GuardReplacementReason= 'GuardReplacementReason',
    GuardBookAbsenceReason = 'GuardBookAbsenceReason',
    GuardRemoveJobScheduleReason = 'GuardRemoveJobScheduleReason',
    GuardRemoveJobShiftReason = 'GuardRemoveJobShiftReason',
    ClientDeactivationReason = 'ClientDeactivationReason',
    ASPUserDeactivationReason = 'ASPUserDeactivationReason',
    ASPAdminDeactivationReason = 'ASPAdminDeactivationReason',
    JobActivationReason = 'JobActivationReason',
    JobDeactivationReason = 'JobDeactivationReason',
    ScheduleDeactivationReason = 'ScheduleDeactivationReason',
    GuardBookedLeaveReason = 'GuardBookedLeaveReason',
    ClientContactDeactivationReason = 'ClientContactDeactivationReason',
    DeviceBrand = 'DeviceBrand',
    IncidentType = 'IncidentTypesOnGuardMobileApp',
    ContactFormTopicsOnGuardMobileApp  = "ContactFormTopicsOnGuardMobileApp",
    ArrivalTypesOnGuardMobileApp = "ArrivalTypesOnGuardMobileApp",
    SecurityQuestionsForGuards = "SecurityQuestions",
}