import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { Tabs, Tab } from 'react-bootstrap'

import { JobIncludesEnum } from '../../common/enums/JobEnums'
import { NotificationTypes } from '../../common/interfaces/INotification'
import { IJobAssignedShifts, IJobDetails } from '../../common/interfaces/jobs/IJob'
import { IVocabulary } from '../../common/interfaces/vocabulary/IVocabulary'
import { DataService } from '../../common/services/DataService'
import { IJobModals } from '../../common/interfaces/jobs/IJobModals'
import { IAvailableGuard } from '../../common/interfaces/guards/IGuard'
import { VocabularyEnum } from '../../common/enums/VocabularyEnum'
import { JobDetailsModel } from '../../common/models/Job'
import { ActivationDeactivation, ModelStatus } from '../../common/enums/Actions'

import AssignedShiftsTab from '../../components/jobs/details/tabs/AssignedShiftsTab'
// import AssignedSchedulesTab from '../../components/jobs/details/tabs/AssignedSchedulesTab'
import ShiftReportTab from '../../components/jobs/details/tabs/ShiftReportTab'
import JobSchedulesTab from '../../components/jobs/details/tabs/JobSchedulesTab'
import ShiftsInNeedTab from '../../components/jobs/details/tabs/ShiftsInNeedTab'
import HolidaysTab from '../../components/jobs/details/tabs/HolidaysTab'
import MainJobData from '../../components/jobs/MainJobData'
import Layout from '../../components/layout/Layout'
import SectionTitle from '../../components/titles/SectionTitle'
import DeactivateModal from '../../components/modal/users/DeactivateModal'
import BaseModal from '../../components/modal/BaseModal'
import ModelStatusesNew from '../../components/jobs/ModelStatusesNew'

import AppContext from '../../context/AppContext'
import JobDetailsTabsContext from '../../context/jobs/JobDetailsTabsContext'
import { useVocabulary } from '../../customHooks/vocabulary/useVocabulary'
import { SVGCheck, SVGPencil } from '../../assets/icons/SvgIcons'
import useActivateDeactivate from '../../customHooks/useActivateDeactivate'
import { useModelDetails } from '../../customHooks/useModelDetails'
import useAssignGuard from '../../customHooks/useAssignGuard'
import useUnassignGuard from '../../customHooks/useUnassignGuard'
import { useHolidays } from '../../customHooks/useHolidays'
import { useTableList } from '../../customHooks/useTableList'
import { useRole } from 'customHooks/useRole'
import useMultiTableList from 'customHooks/useMultiTableList'
import { IJobHoliday } from 'common/interfaces/IHoliday'
import ResourceDataService from 'common/services/ResourceDataService'


const JobDetails: FC<RouteComponentProps> = (props) => {
    const { detailsModel, userId: jobId, setDetailsModel, isLoading, fetchData } = useModelDetails<IJobDetails>("job", new JobDetailsModel(), { include: [JobIncludesEnum.schedules, JobIncludesEnum.assignedSchedules, JobIncludesEnum.shiftsInNeed, JobIncludesEnum.assignedShits, JobIncludesEnum.report, JobIncludesEnum.holidays] })
    const shiftReportList = useTableList(`reports/jobshifts?JobId=${jobId}`, 24, true)
	const { tablesData, fetchData: fetchHolidays, isLoading: isHolidaysLoading } = useMultiTableList<IJobHoliday>(`holiday/job?JobId=${jobId}&OrderBy=startDate&Desc=true`, !!detailsModel.id);
    const [job, setJob] = useState<IJobDetails>(detailsModel);
    const [reasonVocabulary, setReasonVocabulary] = useState([] as IVocabulary[]);
    const [shiftsModals, setShiftsModals] = useState<IJobModals>({ editSuccess: false, removeSuccess: false, addGuardSuccess: false });
    const appContext = useContext(AppContext)
    const { vocabulary } = useVocabulary(VocabularyEnum.shift, true)
    const { vocabulary: removeGuardVocabulary } = useVocabulary(VocabularyEnum.guardRemoveJobScheduleReason, true)
    // const reasonVocabulary = useVocabulary(VocabularyEnum.bookLeave, true)
    const { vocabulary: bookLeaveVocabulary } = useVocabulary(VocabularyEnum.bookLeave, true)
    const { vocabulary: shiftReasonVocabulary } = useVocabulary(VocabularyEnum.shiftReason, true)
    const { vocabulary: scheduleDeactivationVocabulary } = useVocabulary(VocabularyEnum.scheduleDeactivation, true)
    const { vocabulary: deactivationVocabulary } = useVocabulary(VocabularyEnum.jobDeactivation, true)
    const { allHolidays, getHolidaysSchoolBoardById } = useHolidays();
    const tabsRef = useRef(null)
    const { isAdminRole, isASPAndAdminRole, isSupervisorRole, isSuperAdminRole } = useRole()
    const { onRequestHandler, onRequestSubmitHandler, requestModals, modalText, onCloseRequestModals, setRequestModals, itemInFocus, setModalText } = useActivateDeactivate<IJobDetails>('job');

    const asssignSvc = new DataService<{ guardId: number }>({ url: "shift" })
    const { onAssignGuardSubmit: saveAssignedGuard } = useAssignGuard()
    const { onUnassignGuardSubmit } = useUnassignGuard('shift')
    const checkSvc = new ResourceDataService<any>({ url: "job/assigned-guard-check" });
    const [hasAssignedGuards, setHasAssignedGuards] = useState(false);

    useEffect(() => {
        (job.schools?.length !== 0 && !isLoading) && getHolidaysSchoolBoardById(job.schools)
    }, [job.schools])

    useEffect(() => {
        setReasonVocabulary([...bookLeaveVocabulary, ...shiftReasonVocabulary, ...scheduleDeactivationVocabulary, ...removeGuardVocabulary] as IVocabulary[])
    }, [bookLeaveVocabulary, shiftReasonVocabulary, scheduleDeactivationVocabulary])
    useEffect(() => setJob(detailsModel), [detailsModel])

    const onRemoveShiftConfirmed = async (reason: IVocabulary | string, shiftId: number) => {
        onUnassignGuardSubmit(reason, shiftId, async () => {
            await fetchData(jobId)
            setShiftsModals({
                ...shiftsModals,
                removeSuccess: true
            })
        })
    }

    const onAssignGuardSubmit = async (guard: IAvailableGuard, shiftId: number) => {
        saveAssignedGuard(guard, shiftId, 'assign', async () => {
            await fetchData(jobId)
            setShiftsModals({...shiftsModals, addGuardSuccess: true })
        })
    }

    const onAssignedShiftsEditClick = async (shift: IJobAssignedShifts) => {
        appContext.showLoader(true)
        try {
            const body = {
                guardId: shift.guardId,
                timeFrom: shift.timeFrom,
                timeTo: shift.timeTo,
            }
            await asssignSvc.update(body, shift.id)
            await fetchData(jobId)
            setShiftsModals({...shiftsModals, editSuccess: true })
            appContext.showLoader(false)
        } catch(e: any) {
            appContext.showLoader(false)
            appContext.showNotification(NotificationTypes.danger, e.message)
        }
    }

    const onSuccessJobDeactivation = () => {
        setDetailsModel({
            ...job,
            isActive: (isAdminRole() || isSuperAdminRole()) ? ModelStatus.inactive : ModelStatus.pending
        })
    }

    const onSuccessScheduleEdit = async (actionType?: "add" | "edit") => {
        appContext.showLoader(true)
        await fetchData(jobId)
        await fetchHolidays()
      
        setModalText({ title: `Job Schedule for <span class="font-weight-bold">${job.jobName}</span>
        has been ${actionType === "add" ? 'added' : "updated"}` })
        setRequestModals({
            ...requestModals,
            showSuccess: true,
        })
        appContext.showLoader(false)
    }

    const checkDefaultTabFromQuery = (defaultKey: string) => {
        const search = props.location.search;
        const params = new URLSearchParams(search);

        if (params.has('defaultKey')) {
            const element = tabsRef.current
            const topPos = (element && (element as HTMLElement).getBoundingClientRect().top + window.pageYOffset) || 0;
            window.scrollTo({ top: topPos })
            return params.get('defaultKey')
        } else return defaultKey
    }

    const checkJobHasAssignedGuard = async () => {
      const response = await checkSvc.queryString(`JobId=${job.id}`);

      setHasAssignedGuards(response.data.hasGuards);
    }
 
    return (
        <Layout
        breadcrumbs={{
            links: [{
                title: 'Jobs',
                link: 'Jobs'
            }],
            currentPageTitle: job.jobName ? job.jobName : 'Job Details'
        }}
        >
            <section>
                <div className="row">
                    <div className="col-md-12 mx-auto mb-4">
                        <div className="d-flex flex-row align-items-center flex-wrap justify-content-between mb-5  mb-sm-0">
                            <SectionTitle title={job.jobName || ""} />
                            
                            <div className="buttons-container">
                                <div className='id-container'>
                                    <div className="d-flex align-items-center">
                                        <h6 className="mb-0 mr-3 text-right" style={{width: "120px"}}>City Job ID:</h6>
                                        <span className="text-right">{ job.jobId }</span>
                                    </div>
                                    {/* <div className="d-flex align-items-center">
                                        <h6 className="mb-0 mr-3 text-right" style={{width: "120px"}}>XGuard Job ID:</h6>
                                        <span className="text-right">{ jobId }</span>
                                    </div> */}
                                </div>
                               
                                <div className={`deactivate-btn-container ${job.isActive === ModelStatus.pending ? 'deactivate-btn-container--lg' : ''}`}>
                                    <ModelStatusesNew item={job}/>
                                    <div className={`mt-2 d-flex ${job.isActive === ModelStatus.active && !isSupervisorRole() ? 'justify-content-between' : 'justify-content-end'}`}>
                                        { job.isActive === ModelStatus.active && !isSupervisorRole() &&
                                            <button className="btn btn-outline-aqua-blue" onClick={() => {
                                                checkJobHasAssignedGuard()
                                                onRequestHandler({
                                                title: `Are you sure you want to deactivate <span class="font-weight-bold">${job.jobName}</span>?
                                                ${!isAdminRole() && !isSuperAdminRole() ? `
                                                    <br />
                                                    <small class="text-danger">* Deactivation request will expire if it isn't accepted within 
                                                    <span class="font-weight-bold"> 48 hours</span></small>
                                                ` : ''}`,
                                                itemInFocus: job,
                                                type: ActivationDeactivation.deactivate
                                            })}}>
                                                Deactivate Job</button>
                                            }
                                            {isASPAndAdminRole() &&
                                            <Link
                                                className="btn btn-aqua-blue ml-3"
                                                to={`/jobs/${jobId}/edit`}
                                            ><SVGPencil /></Link>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                          <MainJobData
                            job={job}
                            jobId={jobId}
                          />

                    </div>
                </div>
            </section>

            <section className="mt-3 mt-md-5 details-tabs" ref={tabsRef}>
                <div className="row">
                    <div className="col-12 mx-auto details-tabs--jobs-details">
                        <JobDetailsTabsContext.Provider value={{ isLoading: isLoading || isHolidaysLoading, job,
                            vocabulary, removeGuardVocabulary, allHolidays, fetchData }}>
                            <Tabs
                                defaultActiveKey={checkDefaultTabFromQuery("job-schedules") as string}
                                id="jobDetails-tabs"
                                className="justify-content-stretch tabs-details"
                            >
                                <Tab eventKey="job-schedules" title="Job Schedules">
                                    <JobSchedulesTab
                                        onSuccessScheduleAddEdit={onSuccessScheduleEdit}
                                    />
                                </Tab>
                                {/* <Tab eventKey="assigned-schedules" title="Assigned Schedules">
                                    <AssignedSchedulesTab
                                        onSuccessGuardChange={() => onSuccessGuardChange()}
                                        onSuccessGuardRemove={() => onSuccessGuardRemove()}
                                    />
                                </Tab> */}
                                <Tab eventKey="assigned-shifts" title="Assigned Shifts">
                                    <AssignedShiftsTab
                                        onDelete={(reason, shiftId) => onRemoveShiftConfirmed(reason, shiftId)}
                                        onEditSubmit={(shift) => onAssignedShiftsEditClick(shift)}
                                        modalsControl={shiftsModals}
                                        toggleModal={(name, value) => setShiftsModals({...shiftsModals, [name]: value})}
                                    />
                                </Tab>
                                <Tab eventKey="shifts-need" title="Shifts in Need of Guards">
                                    <ShiftsInNeedTab
                                        onSubmit={(guard, shiftId) => onAssignGuardSubmit(guard, shiftId)}
                                        modalsControl={shiftsModals}
                                        toggleModal={(name, value) => setShiftsModals({...shiftsModals, [name]: value})}
                                        vocabularyReasons={reasonVocabulary}
                                    />
                                </Tab>
                                <Tab eventKey="guards-report" title="Shift Report">
                                    <ShiftReportTab
                                        guardsAssigned={job.guardsAssigned}
                                        vocabulary={vocabulary}
                                        shiftReportList={shiftReportList}
                                    />
                                </Tab>
                                <Tab eventKey="holidays" title="Holidays">
                                    <HolidaysTab
                                        fetchData={async () => await fetchData(jobId)}
                                        fetchHolidays={fetchHolidays}
                                        holidays={tablesData as IJobHoliday}
                                    />
                                </Tab>
                            </Tabs>
                        </JobDetailsTabsContext.Provider>
                    </div>
                </div>
            </section>

            { (requestModals.showRequest) &&
                <DeactivateModal
                    onCancel={() => onCloseRequestModals()}
                    onSubmit={(reason: string | IVocabulary) => onRequestSubmitHandler(ActivationDeactivation.deactivate,
                        { itemData: null, successCallback: () => onSuccessJobDeactivation() },
                        `${(isAdminRole() || isSuperAdminRole()) ?
                        `<span class="font-weight-bold">${itemInFocus.jobName}</span> has been deactivated.` :
                        `Your request to deactivate <span class="font-weight-bold">${itemInFocus.jobName}</span> has been sent to the Administrator.`} `,
                        reason
                    )}
                    title={modalText.title}
                    vocabulary={deactivationVocabulary}
                    htmlFooter={
                      hasAssignedGuards ? (
                        <div className="text-center mt-4 text-danger">
                          This job has assigned guards. Are you sure you want to request deactivation?
                        </div>
                      ) : ""
                    }
                />
            }

            {
                requestModals.showSuccess && <BaseModal
                    show={requestModals.showSuccess}
                    onCancel={() => onCloseRequestModals()}
                    cancelBtnText={'Close'}
                >
                    <h4 className="text-left mb-4" dangerouslySetInnerHTML={{ __html: modalText.title }} />
                </BaseModal>
            }
        </Layout>
    )
}

export default JobDetails
