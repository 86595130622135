import firebase from 'firebase/app';
import 'firebase/messaging';

let msg = null;

const fbConfig = {
    apiKey: "AIzaSyCT-yqIYxjV1ZQ1PgLuUi78H9CgXfP87M0",
    authDomain: "xguard-app-6761a.firebaseapp.com",
    projectId: "xguard-app-6761a",
    storageBucket: "xguard-app-6761a.appspot.com",
    messagingSenderId: "702646271673",
    appId: "1:702646271673:web:620441a632640f8309a568"
};

const fbConfigMT = {
    apiKey: "AIzaSyCKCndl1p5C7wD4-CXZhP9C-gP7IcqSFoc",
    authDomain: "crossing-guard-test.firebaseapp.com",
    databaseURL: "https://crossing-guard-test.firebaseio.com",
    projectId: "crossing-guard-test",
    storageBucket: "crossing-guard-test.appspot.com",
    messagingSenderId: "1009266606215",
    appId: "1:1009266606215:web:a9cf01b8903ea014243822"
};

console.log(process.env.REACT_APP_TYPE)
firebase.initializeApp(process.env.REACT_APP_TYPE === 'MT' ? fbConfigMT : fbConfig);

if (firebase.messaging.isSupported()) {
    msg = firebase.messaging();
}

export const messaging = msg;