import { useState } from "react"
import { IActiveJobSchedules, IJobsRightNow } from "../common/interfaces/jobs/IJob"

export type JobOnMapState = {
    jobName: string,
    jobId: string
    shiftId?: number,
    id: number
    location: {
        latitude: string,
        longititude: string
    }
    geoFence: number,
    guardName?: string,
    checkInDateTime?: string,
    checkOutDateTime?: string,
    invisible?:boolean,
    zoneId?: number,
    timeFrom?: string,
    timeTo?: string,
}

export const useJobMarkers = (primaryKey: string = 'id') => {
    const [jobsOnMap, setJobsOnMap] = useState<{ onLoadDataReady: boolean, markers: JobOnMapState[] }>({ onLoadDataReady: false, markers: [] as JobOnMapState[] })

    const generateMarkersForJobs = (sourceData: Array<IJobsRightNow | IActiveJobSchedules>) => {
        setJobsOnMap(prev => ({onLoadDataReady: prev.onLoadDataReady, markers:
            sourceData.map((item: IJobsRightNow | IActiveJobSchedules) => (
                {
                    id: item[primaryKey],
                    shiftId: item.shiftId,
                    zoneId: item.zoneId,
                    jobName: item.jobName,
                    jobId: item.jobId,
                    location: item.location,
                    guardName: item.guardName ? item.guardName : "",
                    geoFence: item.geoFence,
                    timeFrom: item.timeFrom,
                    timeTo: item.timeTo,
                    checkInDateTime: item.checkInDateTime ? item.checkInDateTime : "",
                    checkOutDateTime: item.checkOutDateTime ? item.checkOutDateTime : null
                }
            ))
        }))
    }

    return {
        generateMarkersForJobs, jobsOnMap
    }

}