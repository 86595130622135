import { LatLng } from "react-google-places-autocomplete/build/GooglePlacesAutocomplete.types";

export function calculateCityRadius(city: LatLng, cityBounds: {northeast: LatLng, southwest: LatLng}): number {
    
    const lat1 = city.lat;
    const lon1 = city.lng;
  
    const earthRadiusInKm = 6371; // Radius of the Earth in kilometers
  
    // Calculate the distance between the center of the city and the northeast point
    const northeast = cityBounds.northeast;
    const lat2 = northeast.lat;
    const lon2 = northeast.lng;
    const distance = calculateDistance(lat1, lon1, lat2, lon2, earthRadiusInKm);
  
    // Calculate the distance between the center of the city and the southwest point
    const southwest = cityBounds.southwest;
    const lat3 = southwest.lat;
    const lon3 = southwest.lng;
    const distance2 = calculateDistance(lat1, lon1, lat3, lon3, earthRadiusInKm);
  
    // Return the average of the two distances as the radius
    return ((distance + distance2) / 2) * 1000;
  }
  
  // Helper function to calculate the distance between two points using the Haversine formula
  function calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number, radius: number): number {
    const dLat = degreesToRadians(lat2 - lat1);
    const dLon = degreesToRadians(lon2 - lon1);
  
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(degreesToRadians(lat1)) *
        Math.cos(degreesToRadians(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
  
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = radius * c;
  
    return distance;
  }
  
  // Helper function to convert degrees to radians
  function degreesToRadians(degrees: number): number {
    return degrees * (Math.PI / 180);
  }