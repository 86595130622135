import React, { useContext, useState, useEffect } from "react";
import Nav from "react-bootstrap/esm/Nav";
import Navbar from "react-bootstrap/esm/Navbar";
import NavDropdown from "react-bootstrap/esm/NavDropdown";
import { useHistory, useLocation } from "react-router-dom";
import {
  SVGAccount,
  SVGMenuArrow,
  SVGReplace,
} from "../../assets/icons/SvgIcons";
import Logo from "../../assets/images/xguardlogo_blue.svg";
import { AuthService } from "../../common/auth/AuthService";
import { Roles } from "../../common/enums/Roles";
import { NotificationTypes } from "../../common/interfaces/INotification";
import { ILoggedInUser } from "../../common/interfaces/IUser";
import {
  DropDownMenuLink,
  dropdownsContent,
} from "../../content/MenuDropdowns";
import AppContext from "../../context/AppContext";
import { useMediaQuery } from "@react-hook/media-query";
import { checkIfSubMenuIsOffOfViewport } from "utils/Navigation";
import { useTenant } from "customHooks/useTenant";
import CustomSelectControl from "components/custom-select/CustomSelectControl";
import moment from "moment";
import GeneratePasswordButton from "components/buttons/GeneratePasswordButton";

const Navigation = () => {
  const {
    currentUser,
    showNotification,
    showLoader,
    setCurrentUser,
    setLoggedIn,
  } = useContext(AppContext);
  const {setCurrentTenantId, currentTenantId, tableData, currentClient, setTenantToLS} = useTenant()

  const matches = useMediaQuery("only screen and (min-width: 1024px)");
  const history = useHistory();
  const location = useLocation();
  const [showSubMenus, setShowSubMenus] = useState<{
    [index: number]: boolean;
  }>({});
  // TODO: remove any
  const onLogoutClick = async (e: React.MouseEvent<any>) => {
    e.preventDefault();
    showLoader(true);
    try {
      await AuthService.signOut();
      setLoggedIn(false);
      history.push("/signin");
      showLoader(false);
      setCurrentUser({} as ILoggedInUser);
    } catch (err: any) {
      showNotification(NotificationTypes.danger, err.message);
      showLoader(false);
    }
  };
  const handleMouseOver = (key: number, event?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const targ = event?.target as HTMLElement;
    
    checkIfSubMenuIsOffOfViewport(targ, () => {
      matches && setShowSubMenus({ ...showSubMenus, [+key]: true })
    })
  };

  const handleMouseOut = (key: number) => {
    matches && setShowSubMenus({ ...showSubMenus, [+key]: false });
  };
  
  const closeSubDropdowns = (): void => {
    document.querySelectorAll(".sub-dropdown-show").forEach((subDropdown) => {
      subDropdown.classList.remove("sub-dropdown-show");
    });
  }

  const pageRedirectOrRefresh = (pageLink: string) => {
    ((history.location.pathname === pageLink) && history.location.search === "")
    ?
    window.location.reload()
    :
    window.location.href = `#${pageLink}`;
  }

  const handleSubDopdownClick = (
    event: any,
    link: string,
    hasSubLinks: boolean
  ) => {
    
    event.stopPropagation();

    if (link === "#") return;

    if (matches || !hasSubLinks) {
      pageRedirectOrRefresh(link)
      return;
    }
    const subMenuParent = event.currentTarget as HTMLElement;
    if (subMenuParent.classList.contains("sub-dropdown-show")) {
      pageRedirectOrRefresh(link);
      closeSubDropdowns()
    } else {
      closeSubDropdowns();
      subMenuParent.classList.add("sub-dropdown-show");
    }
  };

  const handleMenuClick = (key: number, link: string) => {
    if (matches) {
      pageRedirectOrRefresh(link);
      return;
    }
    const hideSubMenus = () => {
      for (let [objKey] of Object.entries(showSubMenus)) {
        showSubMenus[+objKey] = false;
      }
      closeSubDropdowns();
    };
    if (showSubMenus[key]) {
      hideSubMenus();
      pageRedirectOrRefresh(link)
      setShowSubMenus({ ...showSubMenus, [key]: false });
    } else {
      hideSubMenus();
      setShowSubMenus({ ...showSubMenus, [key]: true });
    }
  };

  const filteredMenuLinks = (menuLinks: Array<DropDownMenuLink>) => menuLinks.filter(
    (link) =>
      !AuthService.checkIfUserHasPermission(
        link.invisible ? link.invisible : [],
        currentUser.role
      )
  );
    

  const onBrandLogoClick = () =>
    history.location.pathname === "/dashboard" && window.location.reload();

  const removeExtraStyles = () => {
    const className = `sub-dropdown-item--bottom`;
    const element = document.querySelector(`.${className}`) as HTMLElement;
    if (element) {
      element.classList.remove(className)
      element.classList.add('invisible')
    }
  }

  const redirectAfterChangedTenant = (tenantId: string) => {
    setCurrentTenantId(tenantId);
    setTenantToLS(tenantId)
    if (location?.pathname.includes('details')) {
      history.goBack();
    }
    if (location?.pathname.includes('edit')) {
      console.log('herrorororor')
      history.push(location?.pathname.includes('jobs') ? '/jobs' : location?.pathname.includes('guards') ? '/guards' : '')
    }
    setTimeout(() => {window.location.reload()}, 500)
  }

  return (
    <Navbar className='primary-nav' expand='md'>
      <div className='container'>
        <Navbar.Brand href='#dashboard' className='nav-logo d-flex align-self-start p-0' onClick={() => onBrandLogoClick()}>
          <img src={Logo} alt='Brand Logo' />
        </Navbar.Brand>
        <Navbar.Brand className='nav-logo d-flex align-self-start p-0'>
        { AuthService.checkIfUserHasPermission(
          [Roles.SuperAdmin],
          currentUser.role
          ) &&
              (<CustomSelectControl
                options={
                  tableData.length
                    ? tableData.filter(v => v.isActive).map(({ id, name }) => ({
                        id: id || '',
                        name,
                      }))
                    : []
                }
                value={currentTenantId}
                placeholder={'Select client'}
                onChange={(tenantId) => redirectAfterChangedTenant(tenantId)}
                className='custom-react-select--clientId custom-react-select--fixed-size custom-react-select--tenant  d-block mr-auto'
              />)
            }
             { AuthService.checkIfUserHasPermission(
                  [Roles.Admin],
                  currentUser.role
                ) && currentClient?.organizationName && <h5 className="mt-2">{currentClient.organizationName}</h5>
              }
        </Navbar.Brand>
              
        <Navbar.Toggle aria-controls='main-navigation' />

        <Navbar.Collapse
          id='main-navigation'
          className='flex-column pt-3 pt-md-0'>
          <Nav className='ml-md-auto mb-md-3 align-items-center'>
            <div className="d-none d-lg-block mr-0 mr-md-4 mb-3 mb-md-0 px-2">
              <GeneratePasswordButton />
            </div>
            <span className="font-weight-bold">{moment().format('LL')}</span>
            <NavDropdown
              key={"user-account-dropdown"}
              id={`user-account-dropdown`}
              className={
                "d-flex flex-column align-items-start justify-content-md-center mr-0 text-md-center"
              }
              title={
                <>
                  <SVGAccount className='mr-2' />
                  {currentUser.firstName} {currentUser.lastName}
                  <SVGMenuArrow className='ml-1' />
                </>
              }
              onMouseOver={() => handleMouseOver(0)}
              onMouseOut={() => handleMouseOut(0)}
              rootCloseEvent='mousedown'
              show={showSubMenus[0]}>
              <NavDropdown.Item
                key={"my-profile"}
                href={`#/account/my-profile`}>
                My Profile
              </NavDropdown.Item>
              {
                AuthService.checkIfUserHasPermission([Roles.Admin, Roles.ASP, Roles.SuperAdmin], currentUser.role) &&
                <NavDropdown.Item key={'recent-activity'} href={`#/account/recent-activity`}>Recent Activities</NavDropdown.Item>
              }
             
              <NavDropdown.Item
                key={"logout"}
                href={`#`}
                onClick={(e) => onLogoutClick(e)}>
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <div className="d-block d-lg-none mr-0 mr-md-3 mb-3 mb-md-0 ml-auto ">
            <GeneratePasswordButton />
          </div>
          <Nav className='ml-md-auto'>
            {Object.entries(dropdownsContent).map(
              ([key, dropdown]) =>
                !AuthService.checkIfUserHasPermission(
                  dropdown.invisible ? dropdown.invisible : [],
                  currentUser.role
                ) && (
                  <NavDropdown
                    key={key}
                    title={
                      <>
                        {dropdown.title}
                        {dropdown.menuLinks &&
                        filteredMenuLinks(dropdown.menuLinks).length !== 0 ? (
                          <SVGMenuArrow className='ml-1' />
                        ) : null}
                      </>
                    }
                    id={`menu-${dropdown.title}`}
                    className={
                      "flex-column align-items-start justify-content-md-center"
                    }
                    rootCloseEvent='mousedown'
                    onMouseOver={(event) => handleMouseOver(+key, event)}
                    onMouseOut={(event) => handleMouseOut(+key)}
                    onMouseLeave={removeExtraStyles}
                    show={showSubMenus[+key]}
                    onClick={() => handleMenuClick(+key, dropdown.linkRoute)}
                    renderMenuOnMount={false}>
                    {dropdown.menuLinks &&
                      filteredMenuLinks(dropdown.menuLinks).map((link) => (
                        <NavDropdown.Item
                          className={`sub-dropdown`}
                          key={link.title}
                          onClick={(e) =>
                            handleSubDopdownClick(
                              e,
                              link.link,
                              link.subLinks && link.subLinks.length
                                ? true
                                : false
                            )
                          }
                        >
                          {link.title}
                          {link.subLinks &&
                          filteredMenuLinks(link.subLinks).length !== 0 ? (
                            <SVGMenuArrow className='ml-1' />
                          ) : null}
                          <div className='sub-dropdown-item invisible'
                            // style={{ marginTop: subMenuMarginTop ? subMenuMarginTop : '0' }}
                          >
                            {link.subLinks &&
                              link.subLinks.length !== 0 &&
                              filteredMenuLinks(link.subLinks).map(
                                (subLink) => (
                                  <NavDropdown.Item
                                    className={"sub-dropdown"}
                                    key={subLink.title}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                    href={`#${subLink.link}`}
                                  >
                                    {subLink.title}
                                  </NavDropdown.Item>
                                )
                              )}
                          </div>
                        </NavDropdown.Item>
                      ))}
                  </NavDropdown>
                )
            )}
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default Navigation;
