import { ConfigHelper } from '../../helpers/ConfigHelper';
import { IEntity } from '../interfaces/IEntity';
import { IServiceConfig } from '../interfaces/IServiceConfig';
import { IQueryParams } from './../interfaces/IUrlBuilder';
import { BaseDataService } from "./BaseDataService";
import {Reports} from "../enums/Reports";

export interface IResourceServiceConfig extends IServiceConfig {

}

export interface IResourceData<T> extends IEntity {
    data: T;
}

export default class ResourceDataService<T> extends BaseDataService {

    constructor(config: IResourceServiceConfig | string) {
        super(typeof (config) === 'string' ? ConfigHelper.getDefaultConfig(config) : ConfigHelper.ensureConfig(config));
    }

    getAll = async (extraUrl: string | undefined = undefined): Promise<IResourceData<T>> =>  await super.request(`${this.url}${extraUrl ? extraUrl : ''}`, 'GET');

    query = async (params: IQueryParams, needQuestionMark = true, method = 'GET'): Promise<IResourceData<T>> => {
        const param = params ? this.config.urlBuilder?.getQueryParams(params as IQueryParams, needQuestionMark) : '';
        const data = await super.request(`${this.url}${param}`, method);
        return data
    }

    queryString = async (query: string): Promise<IResourceData<T>> => {
        const data = await super.request(`${this.url}?${query}`);
        return data
    }

    select = async (id: string | number): Promise<IResourceData<T>> => await super.request(`${this.url}/${id}`, 'GET');

    getWithBody = async (obj: any): Promise<IResourceData<T>> => await super.request(this.url, 'POST', obj);

    getDocument = async (responseType: string, page: string,
        typeFile: string, query?: string ): Promise<IResourceData<T>> => {
        return await super.request(`${this.url}/${page}?format=${typeFile}${query ? query : ''}`,
        'GET', null, this.headers, responseType);
    }

    getFile = async (responseType: string, page: string, typeFile: string, id?: string | number,
        params?: string, query?: string ): Promise<IResourceData<T>> => {
        if (id && params) {
            return await super.request(
                `${this.url}/${page}/${id}/${typeFile}?include=${params}${query ? query : ''}`,
            'GET', null, this.headers, responseType);
        }
        // TODO: refactor condtion
        else if (page === Reports.holidays || page === 'report/shifts' ) {
            return await super.request(`${this.url}/${page}?format=${typeFile}&${query ? query : ''}`, 'GET', null, this.headers, responseType);
        } else {
            return await super.request(`${this.url}/${page}/${typeFile}`, 'GET', null, this.headers, responseType);
        }
    }
    sendFile = async (file: FormData ): Promise<T> => {
        return await super.request(this.url, 'POST', file,  this.headers.tenantId ? {tenantId: this.headers.tenantId} : {}, '', true)
    }

}
