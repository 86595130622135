import { IVocabulary } from './../../common/interfaces/vocabulary/IVocabulary';
import { Vocabulary } from './../../common/models/Vocabulary';
import React from 'react'
import { IJobDetails } from '../../common/interfaces/jobs/IJob';
import { IHoliday } from '../../common/interfaces/IHoliday';

interface IJobDetailsTabsContext {
    isLoading: boolean
    job: IJobDetails
    vocabulary: Vocabulary[]
    removeGuardVocabulary: IVocabulary[]
    allHolidays: IHoliday[]
    fetchData: (id?: string | undefined) => Promise<void>
}

const JobDetailsTabsContext = React.createContext<IJobDetailsTabsContext>({} as IJobDetailsTabsContext);

export default JobDetailsTabsContext;