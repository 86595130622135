import  { useEffect, useState } from 'react';
import Geocode from "react-geocode";

Geocode.setApiKey(process.env.REACT_APP_MAP_KEY || '');
Geocode.setLanguage("en");
const useCoordinatesByAddress = (address: string, province: string) => {
    
    const [coordinates, setCoordinates] = useState<{lat: number, lng: number}>({lat: 0, lng: 0});
	useEffect(() => {
		if (province) {
			Geocode.fromAddress(address).then(
				(response) => {
					const { lat, lng } = response.results[0].geometry.location;
					console.log(lat, lng);
					setCoordinates({lat, lng})
				},
				(error) => {
					console.error(error);
				}
			);
		}
	}, [address, province]);
	return {coordinates};
};

export default useCoordinatesByAddress;
