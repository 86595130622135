import React, { useState, FC } from 'react'
import CheckInOutModal from '../../components/modal/CheckInOutModal'
import { SVGComments, SVGMarker, SVGPlus, SVGReplace } from '../../assets/icons/SvgIcons'
import BaseModal from '../../components/modal/BaseModal'
import SectionTitle from '../../components/titles/SectionTitle'
import DataTable from '../../components/tables/DataTable'
import AvailableGuards from '../guards/AvailableGuards'
import { IJobReports, IJobsRightNow } from '../../common/interfaces/jobs/IJob'
import { useEffect } from 'react'
import { useVocabulary } from '../../customHooks/vocabulary/useVocabulary'
import { VocabularyEnum } from '../../common/enums/VocabularyEnum'
import { useReason } from '../../customHooks/useReason'
import { IVocabulary } from '../../common/interfaces/vocabulary/IVocabulary'
import { useReplaceGuard } from '../../customHooks/useReplaceGuard'
import { ShiftPeriod } from '../../customHooks/useShiftPeriod'
import JobsMapComponent from '../map/JobsMapComponent'
import { useSingleMarkerOnMap } from '../../customHooks/useSingleMarkerOnMap'
import { momentDateFormat, momentHoursFormat, timeWithoutSecondsFormat } from '../../utils/DateFormatting'
import { DataService } from '../../common/services/DataService'
import AppContext from '../../context/AppContext'
import { useContext } from 'react'
import ConfirmationModal from '../modal/ConfirmationModal'
import { useCheckLate } from '../../customHooks/useCheckLate'
import moment from 'moment'
import ActiveJobsOnMapModal from '../modal/jobs/ActiveJobsOnMapModal'
import { matchOptionWithName } from '../../utils/MatchOptionWithName'
import { generateQueryShiftUrl } from '../../helpers/GenerateQueryUrl'
import LastRefreshBtn from '../buttons/LastRefreshBtn'
import AutocompleteField from '../autosuggestField/AutocompleteField'
import ShiftStatusIcons from './ShiftStatusIcons'
import ShiftAssignedSuccessModal from '../modal/jobs/ShiftAssignedSuccessModal'
import useAssignGuard from '../../customHooks/useAssignGuard'
import { IAvailableGuard } from '../../common/interfaces/guards/IGuard'
import UpdateShiftStatusModal from '../modal/ dashboard/UpdateShiftStatusModal'
import UpdateShiftStatusContext from '../../context/dashboard/UpdateShiftStatusContext'
import { useRole } from 'customHooks/useRole'
import { jobsListTableHead } from "../../content/jobs/JobsContent";
import { useVerifyRolesTHead } from "../../customHooks/useVerifyRolesTHead";
import AddEditCommentModal from '../modal/ dashboard/AddEditCommentModal';
import CustomCheckBox from 'components/checkbox/CustomCheckBox'
import { useCheckGuard } from 'customHooks/modals/useCheckGuard'
import BulkActionsModal from 'components/modal/ dashboard/BulkActionsModal';
import CustomTooltip from 'components/custom-tooltip/CustomTooltip'
import GeoCovidLocationIcons from './GeoCovidLocationIcons'
import ActionDropdown from './ActionDropdown'
import FilterJobList from './FilterJobList'
import { DashboardModals } from 'common/enums/Modals'
import { JobsActiveRightNowModals } from 'common/models/Modals'
import useClientComment from 'customHooks/useClientComment'
import previousDateIcon from '../../assets/icons/previousDateIcon.svg'

type Props = {
    shiftVocabulary: IVocabulary[]
    generatedUrl: string
    currentShiftPeriod: ShiftPeriod
    jobsListTable: any
    jobsListFilter: any
    onCheckSubmit: () => void;
    onRefreshClick: () => void;
    reload?: boolean;
    commentActionCallback?: () => Promise<IJobReports[] | undefined>;
}

type SelectedShiftState = { id: number, body: IJobsRightNow }

const JobsActiveRightNow: FC<Props> = ({
    shiftVocabulary, currentShiftPeriod, jobsListTable,
    jobsListFilter, onCheckSubmit, onRefreshClick, commentActionCallback
}) => {
    const baseUrl = `job/shifts/${generateQueryShiftUrl.currentDay}`;
    const checkSvc = new DataService({ url: 'shift' })

    const [showModal, setShowModal] = useState<JobsActiveRightNowModals>({
        ...new JobsActiveRightNowModals({})
    })
    const [activeJobsOnMapModal, setActiveJobsOnMapModal] = useState<boolean>(false)
    const [selectedShift, setSelectedShift] = useState<SelectedShiftState>({ id: 0, body: {} as IJobsRightNow })
    const [checkTime, setCheckTime] = useState<string>(timeWithoutSecondsFormat())
    const [successCheckModal, setSuccessCheckModal] = useState<{ text: string, modal: boolean }>({ text: '', modal: false })
    const { handleCheckActions, handleBulkAction } = useCheckGuard()
    const { handleRemoveClientComment, handleAddEditClientComment, showErrorMessage, comment, setComment, onCommentChange, validForm, setShowErrorMessage } = useClientComment({})

    const replacementVocabulary = useVocabulary(VocabularyEnum.replacement, true)
    const absenceVocabulary = useVocabulary(VocabularyEnum.absence, true)

    const replacementReasonHook = useReason()
    const absentReasonHook = useReason()

    const { setSelectedGuard, modalContent, setReplaceModal, replaceModal,
        onReplaceGuardSaveClick, onReplaceGuardConfirmClick, selectedGuard } = useReplaceGuard()
    const { viewLocationInMap, jobItemInFocus, onViewLocationInMapClick, setViewLocationInMap } = useSingleMarkerOnMap('shiftId')
    const appContext = useContext(AppContext);
    const { setShiftStatus } = useCheckLate()
    const { filter, generateFilterQuery, setFilter } = jobsListFilter
    const { onAssignGuardSubmit: saveAssignedGuard } = useAssignGuard()
    const { isClientRole, isASPAndAdminRole, isSupervisorRole, isSuperAdminRole } = useRole()
    const { theadTable, addVerifyRoles } = useVerifyRolesTHead()
    const [defaultComment, setDefaultComment] = useState('');
    const [isEditable, setIsEditable] = useState(false);
    const [selectedIds, setSelectedIds] = useState([] as string[]);
    useEffect(() => {
        setFilter({
            ...filter,
            minutesFromNow: 30,
            checkInType: 0,
            checkOutType: 0
        })
    }, [])

    useEffect(() => {
        filter.minutesFromNow && jobsListTable.fetchData(1, 0, `${baseUrl}${generateFilterQuery().substring(1)}`, true)
    }, [filter.minutesFromNow, filter.shiftPeriod, filter.zone, filter.schoolBoardId, filter.checkInType, filter.checkOutType])

    useEffect(() => {
        (filter.keyWord === '' && filter.hasValue) && jobsListTable.fetchData(1, 0, `${baseUrl}${generateFilterQuery().substring(1)}`, true)
    }, [filter.keyWord])

    useEffect(() => {
        jobsListFilter.getCities()
        jobsListFilter.getZones()
        jobsListFilter.getSchoolBoard()
    }, [])

    useEffect(() => {
        replacementVocabulary.vocabulary.length !== 0 && replacementReasonHook.setReasons([...replacementVocabulary.vocabulary, ...[{ id: 0, name: "Other" }]])
        absenceVocabulary.vocabulary.length !== 0 && absentReasonHook.setReasons([...absenceVocabulary.vocabulary, ...[{ id: 0, name: "Other" }]])
    }, [replacementVocabulary.vocabulary, absenceVocabulary.vocabulary])

    useEffect(() => {
        addVerifyRoles(jobsListTableHead.thead)
    }, [jobsListTableHead])

    const onCancelModal = () => { 
        setShowModal({...new JobsActiveRightNowModals({})});
        setComment('');
        setShowErrorMessage('');
    }

    const openModal = (e: React.MouseEvent<any> | null, type: DashboardModals, shiftId: number) => {
        const shift = jobsListTable.tableData.find((shift: IJobsRightNow) => shift.shiftId === shiftId)
        shift && setSelectedShift({
            id: shiftId,
            body: shift
        })
        setCheckTime(shift && timeWithoutSecondsFormat(type === DashboardModals.out ? shift.timeTo : shift.aspStartDate))
        e !== null && e.preventDefault();
        const modals = { ...showModal };

        for (let [key,] of Object.entries(modals)) {
            key === type ? (modals[type] = true) : (modals[key] = false);
        }

        setShowModal(modals)
    }

    const shiftStatus = (shiftId: number, showAsForClient?: boolean) => (
        setShiftStatus(shiftId, jobsListTable.tableData, currentShiftPeriod.id, showAsForClient)
    );

    // tentantCheckIn
    const onGuardTempCheckInClick = async (shiftId: number) => {
        appContext.showLoader(true)
        const shift = jobsListTable.tableData.find((shift: IJobsRightNow) => shift.shiftId === shiftId)
        try {
            const checkTime = timeWithoutSecondsFormat(moment.utc())
            await checkSvc.create({ time: checkTime }, shift.shiftId, 'check-in?isTentant=true')

            jobsListTable.setTableData(jobsListTable.tableData.map((item: IJobsRightNow) => {
                if (item.shiftId === shift.shiftId) {
                    item.shiftStatus.tentantCheckIn = true
                    item.checkInDateTime = checkTime
                }
                return item
            }))

            onCheckSubmit()
            setSuccessCheckModal({
                modal: true,
                text: `<span class="font-weight-bold">${shift.guardName}</span> has been tentatively checked in for <span class="font-weight-bold">${shift.jobName}</span>`
            })
            onCancelModal()
            setSelectedShift({} as SelectedShiftState)

            appContext.showLoader(false)
        } catch (error: any) {
            appContext.showLoader(false)
        }
    }
    const bulkActionsCallback = async () => {
        setSelectedIds([]);
        onCancelModal();
        await jobsListTable.fetchData(1, 0, `${baseUrl}${generateFilterQuery().substring(1)}`, true)
    }

    const onGuardCheck = async () => {

        let type = showModal.checkIn ? "checkIn" : (showModal.absent ? "absent" : "checkOut");
        if (type === "absent" && (absentReasonHook.reasonIsEmpty('reasonForAbsence', 'reasonForAbsence--other'))) return;
        appContext.showLoader(true)

        handleCheckActions({
            checkTime: checkTime,
            shiftId: selectedShift.body.shiftId,
            reason: absentReasonHook.reason,
            checkType: type
        }, async () => {
            await handleAddEditClientComment(selectedShift.id)
            await jobsListTable.fetchData(1, 0, `${baseUrl}${generateFilterQuery().substring(1)}`, true)
            onCheckSubmit()
            setSuccessCheckModal({
                modal: true,
                text: `<span class="font-weight-bold">${selectedShift.body.guardName}</span> has been ${showModal.checkIn ? "checked in" : (showModal.absent ? "marked as absent" : "checked out")} for <span class="font-weight-bold">${selectedShift.body.jobName}</span>`
            })
            onCancelModal()
            setSelectedShift({} as SelectedShiftState)

            appContext.showLoader(false)
        })
    }

    const onGuardReplaceClick = (shiftId: number, shiftPeriod: string | number | undefined) => {
        const shift = jobsListTable.tableData.find((shift: IJobsRightNow) => shift.shiftId === shiftId)
        if (shift) {
            setShowModal({ ...showModal, replaceGuard: true })
            setSelectedShift({ id: shiftId, body: { ...shift, shiftPeriod } })
        }
    }

    const onAssignGuardClick = (shiftId: number) => {
        const shift = jobsListTable.tableData.find((shift: IJobsRightNow) => shift.shiftId === shiftId)
        if (shift) {
            setShowModal({ ...showModal, assignGuard: true })
            setSelectedShift({ id: shiftId, body: shift })
        }
    }

    const onSuccessGuardReplace = async () => {
        await onReplaceGuardConfirmClick(selectedShift.body, replacementReasonHook.reason, 'shiftId')
        jobsListTable.fetchData(1, 0, `${baseUrl}${generateFilterQuery().substring(1)}`, true)
    }

    const onAssignGuardSubmit = async (guard: IAvailableGuard, shiftId: number) => {
        saveAssignedGuard(guard, shiftId, 'assign', () => {
            setSelectedGuard(guard)
            setShowModal({ ...showModal, successModal: true, assignGuard: false })
            jobsListTable.fetchData(1, 0, `${baseUrl}${generateFilterQuery().substring(1)}`, true)
            onRefreshClick()
        })
    }

    const updateShiftStatusSubmit = () => {
        jobsListTable.fetchData(1, 0, `${baseUrl}${generateFilterQuery().substring(1)}`, true)
        setShowModal({ ...showModal, updateStatusModal: false, successUpdate: true })
        setComment('');
    }

    const previousDayIcon = (shift: IJobsRightNow) => {
      if (moment(shift.aspStartDate).format('LL') === moment().subtract(1, 'day').format('LL') && jobsListFilter.filter.minutesFromNow === '-1440') {
        return `
          <div>
            <img src="${previousDateIcon}" />
          </div>
        `
      }

      return `<div></div>`;
    }

    return (
        <>
            {
                (showModal.checkIn || showModal.checkOut || showModal.absent) &&
                <BaseModal
                    show={showModal.checkIn || showModal.checkOut || showModal.absent}
                    title={showModal.checkIn ? "Check In" : (showModal.absent ? "Absent" : "Check Out")}
                    onSubmit={() => onGuardCheck()}
                    onCancel={() => onCancelModal()}
                    submitBtnText={showModal.checkIn ? "Check In" : (showModal.absent ? "Absent" : "Check Out")}
                >
                    <CheckInOutModal
                        type={showModal.checkIn ? "checkin" : (showModal.absent ? "absent" : "checkout")}
                        absenceReasons={absentReasonHook.reasons}
                        checkTime={checkTime}
                        onTimeChange={time => setCheckTime(time)}
                        reasonsHook={absentReasonHook}
                        selectedItem={selectedShift.body}
                        comment={comment}
                        setComment={setComment}
                        onCommentChange={onCommentChange}
                    />
                </BaseModal>
            }
            <section>
                <div className="row">
                    <div className="col-12 py-4 d-flex flex-row align-items-center flex-wrap justify-content-between">
                        <SectionTitle title={'Active Job Shifts'} />
                        <div className="d-flex flex-wrap mt-2 mt-lg-0">
                            <div className="d-flex flex-row ml-0 mb-2 mb-md-0 mr-2">
                                <div className="form-group mb-0 mx-0 w-100 mx-md-2">
                                    <AutocompleteField
                                        isAsync={true}
                                        onlyActive
                                        includes="Job,Guard,School,SchoolBoard"
                                        placeholder='Search for Job Shift'
                                        onSearch={async (value) => {
                                            jobsListFilter.onSearchChange(value, 'keyWord')
                                            await jobsListTable.fetchData(0, 0, `${baseUrl}${generateFilterQuery(false, true, value).substring(1)}`, true)
                                        }}
                                        onClear={() =>
                                            jobsListFilter.onFilterSearchClearClick(() => jobsListTable.fetchData(1, 0, `${baseUrl}${generateFilterQuery(true).substring(1)}`, true))}
                                    />
                                </div>
                            </div>
                            <LastRefreshBtn onRefresh={() => {
                                onRefreshClick();
                                jobsListTable.fetchData(1, 0, `${baseUrl}${generateFilterQuery().substring(1)}`, true);
                            }} />
                        </div>
                    </div>

                    <FilterJobList
                        filter={filter}
                        jobsListFilter={jobsListFilter}
                        setActiveJobsOnMapModal={setActiveJobsOnMapModal}
                        setFilter={setFilter}
                        shiftVocabulary={shiftVocabulary}
                    />

                    {React.useMemo(() => (
                        <div className="col-12 pt-4">
                            {selectedIds.length !== 0 &&
                                <div className="col-12 d-flex flex-row justify-content-center align-items-center mb-4">
                                    <h5 className="mb-0">Bulk Action for Selected Shifts:</h5>
                                    <button className="btn btn-aqua-blue mx-2" onClick={() => setShowModal(prev => ({ ...prev, bulkTempCheckIn: true }))}>Temp Check-In</button>
                                    <button className="btn btn-aqua-blue mx-2" onClick={() => setShowModal(prev => ({ ...prev, bulkMarkAbsent: true }))}>Mark Absent</button>
                                    <button className="btn btn-aqua-blue mx-2" onClick={() => setShowModal(prev => ({ ...prev, bulkConfirmCheckIn: true }))}>Confirm Check-In</button>
                                </div>
                            }
                            <DataTable
                                thead={theadTable}
                                tbody={
                                  jobsListTable.tableData ?
                                  jobsListFilter.filter.minutesFromNow === '-1440' ?
                                  jobsListTable.tableData
                                    .sort((a: IJobsRightNow, b: IJobsRightNow) => +moment(a.aspStartDate) - +moment(b.aspStartDate))
                                    .map((shift: IJobsRightNow) => (
                                    {
                                        id: shift.shiftId,
                                        jobLocationLink: shift.jobName,
                                        shiftPeriod: shift.shiftPeriod ? matchOptionWithName(shift.shiftPeriod as string, shiftVocabulary) : '',
                                        guard: shift.guardName ? shift.guardName : '-',
                                        aspStart: shift.aspStartDate ? momentHoursFormat(shift.aspStartDate, shift.timeFrom, true) : '--',
                                        shiftTime: `<div class="d-flex justify-content-between align-items-center" style="min-width: 125px;">
                                                      ${previousDayIcon(shift)} ${momentHoursFormat(shift.timeFrom)} - ${momentHoursFormat(shift.timeTo, shift.timeFrom)}
                                                    </div>`,
                                        zone: shift.zone,
                                        tentantCheckIn: shift.shiftStatus.tentantCheckIn,
                                        jobId: shift.id,
                                        latesOrAbsences: shift.latesOrAbsences,
                                        clientLatesOrAbsences: shift.clientLatesOrAbsences,
                                        lastComment: shift.lastComment || '',
                                        inGeofence: shift.inGeofence,
                                        covidScreen: shift.covidScreen,
                                        coordinates: shift.coordinates
                                    }))
                                  :
                                  jobsListTable.tableData.map((shift: IJobsRightNow) => (
                                    {
                                        id: shift.shiftId,
                                        jobLocationLink: shift.jobName,
                                        shiftPeriod: shift.shiftPeriod ? matchOptionWithName(shift.shiftPeriod as string, shiftVocabulary) : '',
                                        guard: shift.guardName ? shift.guardName : '-',
                                        aspStart: shift.aspStartDate ? momentHoursFormat(shift.aspStartDate, shift.timeFrom, true) : '--',
                                        shiftTime: `<div class="d-flex justify-content-between align-items-center" style="min-width: 125px;">
                                                      ${previousDayIcon(shift)} ${momentHoursFormat(shift.timeFrom)} - ${momentHoursFormat(shift.timeTo, shift.timeFrom)}
                                                    </div>`,
                                        zone: shift.zone,
                                        tentantCheckIn: shift.shiftStatus.tentantCheckIn,
                                        jobId: shift.id,
                                        latesOrAbsences: shift.latesOrAbsences,
                                        clientLatesOrAbsences: shift.clientLatesOrAbsences,
                                        lastComment: shift.lastComment || '',
                                        inGeofence: shift.inGeofence,
                                        covidScreen: shift.covidScreen,
                                        coordinates: shift.coordinates
                                    }
                                )) : []}
                                // ignoreCols={[...(isClientRole() || isSupervisorRole() ? [0, 4, 7, 8, 9, 10, 11, 12, 13, 14] : [0, 8, 7, 9, 10, 11, 12, 13, 14])]}
                                ignoreCols={[...(isClientRole() || isSupervisorRole() ? [0, 1, 4, 7, 8, 9, 10, 11, 12, 13, 14] : [0, 1, 8, 7, 9, 10, 11, 12, 13, 14])]}
                                ignoreTheadCols={[...(isClientRole() || isSupervisorRole() ? [0, 4, 8, 10, 11, 12, 13] : [])]}
                                tableClass={'table-info--notFixed table--dashboard'}
                                isLoading={jobsListTable.isLoading}
                                defaultSortedColumn={3}
                                tableName={"Active Job Shifts"}
                                additionalTextToItemsCount={"Within Selected Time Frame"}
                                itemsCount={jobsListTable.allRecords}
                                itemsPerPage={+jobsListTable.tableData.length}
                                showTableLengthData
                                showTableLastRefresh
                                onSwitchChange={() => {
                                    setIsEditable(prev => !prev);
                                    setSelectedIds([]);
                                }}
                                customMarkupAtStart={(id, rowItem: IJobsRightNow, index: number) => (
                                    <>
                                    {
                                        isASPAndAdminRole() && (
                                            <td className='align-middle'>
                                                <CustomCheckBox
                                                    id={id}
                                                    checked={selectedIds.includes(String(id))}
                                                    onChange={() => {
                                                        setSelectedIds(prev => !prev.includes(String(id)) ? [...prev, String(id)] : prev.filter(sId => sId !== String(id)))
                                                    }}
                                                    disabled={!isEditable}
                                                />
                                            </td>
                                        )
                                    }
                                        <td className='align-middle isLink'>
                                            <div>
                                                {
                                                    rowItem.lastComment &&
                                                    <CustomTooltip
                                                        bodyText={rowItem.lastComment}
                                                        keyValue={`comments-${index}`}
                                                    >
                                                        <span><SVGComments className="mr-2"/></span>
                                                    </CustomTooltip>
                                                }
                                                <a href={`#/jobs/${rowItem.jobId}/details`}>{rowItem.jobLocationLink}</a>
                                            </div>
                                        </td>
                                    </>
                                )}
                            >
                                {
                                    (jobItemId, rowItem: IJobsRightNow) => (
                                        <React.Fragment key={jobItemId}>
                                            <td className="align-middle">
                                                <ShiftStatusIcons
                                                    guardIconStatus={shiftStatus(jobItemId)?.guardIconClass}
                                                    checkInStatus={shiftStatus(jobItemId)?.checkInClassName}
                                                    checkOutStatus={shiftStatus(jobItemId)?.checkOutClassName}
                                                    isVisibleForASP={isASPAndAdminRole()}
                                                    keyValue={jobItemId}
                                                />
                                            </td>
                                            {/* Client Shift Status */}
                                            {
                                                !isClientRole() &&
                                                <td className="align-middle">
                                                    <ShiftStatusIcons
                                                        guardIconStatus={shiftStatus(jobItemId, true)?.guardIconClass}
                                                        checkInStatus={shiftStatus(jobItemId, true)?.checkInClassName}
                                                        checkOutStatus={shiftStatus(jobItemId, true)?.checkOutClassName}
                                                        isVisibleForASP={false}
                                                        keyValue={jobItemId}
                                                    />
                                                </td>
                                            }

                                            <td className="align-middle">
                                                <div>
                                                    <span className={`d-flex align-items-center justify-content-center table-info__counter table-info__counter${rowItem.latesOrAbsences === 0 ? "--best" : "--good"}`}>
                                                        {rowItem.latesOrAbsences}
                                                    </span>
                                                </div>
                                            </td>
                                            {/* Client Late */}
                                            {!isClientRole() &&
                                                <td className="align-middle">
                                                    <div>
                                                        <span className={`d-flex align-items-center justify-content-center table-info__counter table-info__counter${rowItem.clientLatesOrAbsences === 0 ? "--best" : "--good"}`}>
                                                            {rowItem.clientLatesOrAbsences}
                                                        </span>
                                                    </div>
                                                </td>
                                            }
                                            {!isClientRole() &&
                                                <GeoCovidLocationIcons rowItem={rowItem} />
                                            }

                                            <td className="align-middle">
                                                <div className="d-flex justify-content-between">
                                                    {
                                                        !isClientRole() && !isSupervisorRole() &&
                                                        <>
                                                            {
                                                                shiftStatus(jobItemId)?.assigned ?
                                                                    <button
                                                                        className={`btn ${(
                                                                            shiftStatus(jobItemId)?.assigned && (!shiftStatus(jobItemId)?.checkIn && !shiftStatus(jobItemId)?.checkOut && !shiftStatus(jobItemId)?.absent)) ?
                                                                            'btn-aqua-blue' :
                                                                            'btn-disabled'} mr-1`}
                                                                        onClick={() => (shiftStatus(jobItemId)?.assigned && (!shiftStatus(jobItemId)?.checkIn && !shiftStatus(jobItemId)?.checkOut && !shiftStatus(jobItemId)?.absent))
                                                                            && onGuardReplaceClick(jobItemId, rowItem.shiftPeriod)}
                                                                    >
                                                                        {
                                                                            (shiftStatus(jobItemId)?.assigned && (!shiftStatus(jobItemId)?.checkIn && !shiftStatus(jobItemId)?.checkOut && !shiftStatus(jobItemId)?.absent)) ?
                                                                                <SVGReplace width={16} height={16} /> :
                                                                                <SVGReplace width={16} height={16} disabled={true} />
                                                                        }

                                                                    </button>
                                                                    :
                                                                    <button
                                                                        className={`btn btn-aqua-blue mr-1`}
                                                                        onClick={() => onAssignGuardClick(jobItemId)}
                                                                    >
                                                                        <SVGPlus width={16} height={16}/>
                                                                    </button>
                                                            }
                                                        </>
                                                    }
                                                    <button className="btn btn-aqua-blue mr-1" onClick={() => onViewLocationInMapClick(jobsListTable.tableData, jobItemId)}>
                                                        <SVGMarker width={16} height={16}/>
                                                    </button>
                                                    <ActionDropdown
                                                        jobItemId={jobItemId}
                                                        rowItem={rowItem}
                                                        onGuardTempCheckInClick={onGuardTempCheckInClick}
                                                        openModal={openModal}
                                                        setDefaultComment={setDefaultComment}
                                                        shiftStatus={shiftStatus}
                                                    />
                                                </div>
                                            </td>
                                        </React.Fragment>
                                    )
                                }

                            </DataTable>
                        </div>
                    ), [jobsListTable, shiftStatus, shiftVocabulary])}

                </div>
            </section>
            {
                viewLocationInMap && <BaseModal
                    show={viewLocationInMap}
                    onCancel={() => setViewLocationInMap(false)}
                    className={'view-job-location'}
                    cancelBtnText={"Close"}
                >
                    <JobsMapComponent
                        jobMarkers={[{
                            jobName: jobItemInFocus.jobName,
                            jobId: jobItemInFocus.jobId,
                            shiftId: jobItemInFocus.shiftId,
                            id: jobItemInFocus.id,
                            location: {
                                latitude: jobItemInFocus.location.latitude,
                                longititude: jobItemInFocus.location?.longititude ? jobItemInFocus.location.longititude : jobItemInFocus.location.longitude
                            },
                            geoFence: jobItemInFocus.geoFence,
                            guardName: jobItemInFocus.guardName,
                            checkInDateTime: jobItemInFocus.checkInDateTime,
                            checkOutDateTime: jobItemInFocus.checkOutDateTime,
                            invisible: false,
                            zoneId: jobItemInFocus.zoneId,
                            timeFrom: jobItemInFocus.timeFrom,
                            timeTo: jobItemInFocus.timeTo,
                        }]}
                        defaultCenter={{
                            lat: +jobItemInFocus.location.latitude,
                            lng: jobItemInFocus.location?.longititude ? +jobItemInFocus.location.longititude : +jobItemInFocus.location.longitude
                        }}
                        showMarkerDetails
                        currShiftPeriodId={currentShiftPeriod?.id}
                        jobsNow={jobsListTable.tableData}
                        showGuardPosition
                        mapAreaAsPin
                        mapZoom={15}
                        // removeLoadScript
                        showGeofenceOnLoad
                        showJobDetailsOnLoad
                        hideGuardIcon={false}
                        blurInfoModal={true}
                    />
                </BaseModal>
            }
            {
                isASPAndAdminRole() && (
                    <BulkActionsModal
                        onCancelModal={onCancelModal}
                        showModal={showModal}
                        handleBulkAction={handleBulkAction}
                        bulkActionCallback={bulkActionsCallback}
                        selectedIds={selectedIds.join(',')}
                    />
                )
            }

            {isASPAndAdminRole() &&
                <>
                    {/* Replacement Modal */}
                    {showModal.replaceGuard &&
                        <BaseModal
                            show={showModal.replaceGuard}
                            onCancel={() => setShowModal({ ...showModal, replaceGuard: false })}
                            className="available-guards-modal"
                            submitBtnText={'Save'}
                            cancelBtnText={'Close'}
                            onSubmit={() => onReplaceGuardSaveClick(
                                replacementReasonHook.reasonIsEmpty('reasonForReplacement', 'reasonForReplacement--other'),
                                selectedShift.body, replacementReasonHook.reason)
                                .then(() => setShowModal({ ...showModal, replaceGuard: false }))
                            }
                        >
                            <AvailableGuards
                                onSubmit={guard => setSelectedGuard(guard)}
                                selectedItem={{
                                    ...selectedShift.body,
                                    shiftPeriod: shiftVocabulary.find(item => item.name.includes(currentShiftPeriod.label.split(' ')[0]))?.id
                                }}
                                title={`Replacement <span class="font-weight-bold">${selectedShift.body.guardName}</span> for <span class="font-weight-bold">${selectedShift.body.firstStreet} ${selectedShift.body.secondStreet || ""}</span>
                         <br /> <h5 class="font-weight-bold">${momentDateFormat(selectedShift.body.startDate)} - Shift ${momentHoursFormat(selectedShift.body.timeFrom)} - ${momentHoursFormat(selectedShift.body.timeTo, selectedShift.body.timeFrom)}</h5>`}
                                showReasons={true}
                                reasons={[...replacementVocabulary.vocabulary, ...[{ id: 0, name: "Other" }]]}
                                reasonsHook={replacementReasonHook}
                            />
                        </BaseModal>
                    }

                    {
                        (replaceModal.confirm) &&
                        <BaseModal
                            show={replaceModal.confirm}
                            onCancel={() => setReplaceModal({ confirm: false, success: false })}
                            cancelBtnText={'Cancel'}
                            submitBtnText={'Confirm'}
                            onSubmit={() => onSuccessGuardReplace()}
                        >
                            <div dangerouslySetInnerHTML={{ __html: modalContent }} />
                        </BaseModal>
                    }

                    {
                        replaceModal.success &&
                        <BaseModal
                            show={replaceModal.success}
                            onCancel={() => {
                                replacementReasonHook.setReason({} as IVocabulary)
                                setReplaceModal({ confirm: false, success: false })
                            }}
                            cancelBtnText={'Close'}
                        >
                            <div dangerouslySetInnerHTML={{ __html: modalContent }} />
                            <small className="text-danger">A notification has been sent to the guard with the details of this new shift.</small>
                        </BaseModal>
                    }

                    {
                        successCheckModal.modal &&
                        <BaseModal
                            show={successCheckModal.modal}
                            onCancel={() => setSuccessCheckModal({ text: '', modal: false })}
                            cancelBtnText={'Close'}
                        >
                            <ConfirmationModal text={successCheckModal.text} />
                        </BaseModal>
                    }
                </>
            }
            {
                activeJobsOnMapModal && <ActiveJobsOnMapModal
                    onCancel={() => setActiveJobsOnMapModal(false)}
                    shiftVocabulary={shiftVocabulary}
                    currentShiftPeriod={currentShiftPeriod}
                    schoolBoards={jobsListFilter.schoolBoards}
                    cities={jobsListFilter.cities}
                    zones={jobsListFilter.zones}
                />
            }
            
            {
                showModal.assignGuard && <BaseModal
                    show={showModal.assignGuard}
                    onCancel={() => onCancelModal()}
                    className="available-guards-modal"
                >
                    <AvailableGuards
                        title={`Assign Guard for Selected Job Shift for <span class="font-weight-bold">${selectedShift.body.jobName}</span>`}
                        onSubmit={guard => onAssignGuardSubmit(guard, selectedShift.id)}
                        selectedItem={{
                            ...selectedShift.body,
                            shiftPeriod: shiftVocabulary.find(item => item.name.includes(currentShiftPeriod.label.split(' ')[0]))?.id
                        }}
                    />
                </BaseModal>
            }

            {
                showModal.successModal && <ShiftAssignedSuccessModal
                    show={showModal.successModal}
                    onClose={() => onCancelModal()}
                    selectedGuard={selectedGuard}
                    selectedShift={selectedShift.body}
                    vocabulary={shiftVocabulary}
                />
            }
            {
                showModal.addComment && <AddEditCommentModal
                    show={showModal.addComment}
                    defaultValue={defaultComment}
                    onCancel={onCancelModal}
                    jobId={selectedShift.id}
                    submitCallback={() => {
                        jobsListTable.fetchData(1, 0, `${baseUrl}${generateFilterQuery().substring(1)}`, true)
                        commentActionCallback && commentActionCallback()
                    }}
                    title={`${defaultComment ? 'Edit' : 'Add'} a Comment for the City`}
                    submitText={`${defaultComment ? 'Edit' : 'Add'} a Comment`}
                />
            }
            <UpdateShiftStatusContext.Provider
                value={{
                    vocabulary: [...replacementVocabulary.vocabulary, ...[{ id: 0, name: "Other" }]],
                }}
            >
                {
                    showModal.updateStatusModal && <UpdateShiftStatusModal
                        onCancel={onCancelModal}
                        onSubmit={updateShiftStatusSubmit}
                        shift={selectedShift.body}
                        showReasonField
                        comment={comment}
                        setComment={setComment}
                        onCommentChange={onCommentChange}
                        handleAddEditClientComment={handleAddEditClientComment}
                    />
                }

                {
                    showModal.successUpdate &&
                    <BaseModal
                        show={showModal.successUpdate}
                        onCancel={() => setShowModal({ ...showModal, successUpdate: false })}
                        cancelBtnText={'Close'}
                    >
                        <ConfirmationModal text={`
                            Shift details for <span class="font-weight-bold">${selectedShift.body.jobName}</span> has been updated.
                        `} />
                    </BaseModal>
                }
                {
                    showModal.removeComment &&
                    <BaseModal
                        show={showModal.removeComment}
                        onCancel={() => setShowModal({ ...new JobsActiveRightNowModals({}) })}
                        onSubmit={() => handleRemoveClientComment(selectedShift.body.shiftId, () => {
                            setShowModal({ ...new JobsActiveRightNowModals({removeCommentSuccess: true}) })
                            jobsListTable.fetchData(1, 0, `${baseUrl}${generateFilterQuery().substring(1)}`, true)
                            commentActionCallback && commentActionCallback()
                        })}
                        submitBtnText={'Remove'}
                        cancelBtnText={'No'}
                    >
                         <ConfirmationModal text={`
                        Are you sure that you want to remove client comment from <span class="font-weight-bold">${selectedShift.body.jobName}</span> ?
                        `} />
                    </BaseModal>
                }
                 {
                    showModal.removeCommentSuccess &&
                    <BaseModal
                        show={showModal.removeCommentSuccess}
                        onCancel={() => setShowModal({ ...new JobsActiveRightNowModals({}) })}
                        cancelBtnText={'Close'}
                    >
                        <ConfirmationModal text={`
                            Client comment for <span class="font-weight-bold">${selectedShift.body.jobName}</span> has been removed.
                        `} />
                    </BaseModal>
                }

            </UpdateShiftStatusContext.Provider>
        </>
    )
}

export default JobsActiveRightNow
