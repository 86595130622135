import React, {FC, useContext} from 'react'
import {SVGDownload, SVGPrint} from '../../assets/icons/SvgIcons';
import {Reports} from '../../common/enums/Reports';
import {useDowloandFile} from '../../customHooks/useDownloadFile';
import {Dropdown} from "react-bootstrap";
import AppContext from '../../context/AppContext';

type Props = {
    title?: string
    page: string
    className?: string
    tabName?: string
    id?: string | number,
    query?: string
    showForShiftReport?: boolean
    hidePrint?: boolean
    hideDownload?: boolean
    hidePdf?: boolean
    hideXlsx?: boolean
    showPrint?: boolean
}

const ReportActions: FC<Props> = ({title, className, page, tabName, id, query, showForShiftReport = true,
    hidePrint, hideDownload, hidePdf, hideXlsx, showPrint
    }) => {
    const {dowloandFile} = useDowloandFile();
    const { showLoader } = useContext(AppContext);

    const onActionClick = async (action: Reports, type: Reports) => {
        showLoader(true)
        if (action === Reports.download) {
            if (type === Reports.pdf) {
                await dowloandFile(page, Reports.pdf, Reports.download, id, tabName, query)

            } else {
                await dowloandFile(page, Reports.excel, Reports.download, id, tabName, `&${query}`)
            }
        } else {
            await dowloandFile(page, Reports.pdf, Reports.print, id, tabName, query)
        }
        showLoader(false)
    }

    return (
        <div className={`data-reports d-flex flex-row align-items-center ${className ? className : ''}`}>
            { title && <p className="mb-0 text-dark mr-2">{title}</p> }
            <div className="d-flex flex-row">
                {showForShiftReport && <Dropdown className="content-none">
                    <Dropdown.Toggle
                        id={`dropdown-action`}
                        className="btn-aqua-blue"
                    >
                        <SVGDownload/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        { !hidePdf && <Dropdown.Item
                            onClick={() => onActionClick(Reports.download, Reports.pdf)}>Pdf</Dropdown.Item>
                        }
                        { !hideXlsx && <Dropdown.Item
                            onClick={() => onActionClick(Reports.download, Reports.excel)}>Xlsx</Dropdown.Item>
                        }
                    </Dropdown.Menu>

                </Dropdown>}

                { !hideDownload && !showForShiftReport && 
                    <button
                        type="button"
                        className={"btn btn-aqua-blue ml-2"}
                        onClick={() => onActionClick(Reports.download, Reports.excel)}><SVGDownload/></button>
                    }


                { !hidePrint && (showForShiftReport || showPrint) && 
                    <button
                    type="button"
                    className={"btn btn-aqua-blue ml-2"}
                    onClick={() => onActionClick(Reports.print, Reports.pdf)}><SVGPrint/></button>
                }

               
            </div>
        </div>
    )
}

export default ReportActions
