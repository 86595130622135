import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { IGuard, IGuardAvailability } from '../../common/interfaces/guards/IGuard'
import { NotificationTypes } from '../../common/interfaces/INotification'
import { IValidationBody, ValidationRules } from '../../common/interfaces/IValidation'
import { Guard, GuardDevice } from '../../common/models/Guard'
import { DataService } from '../../common/services/DataService'
import Layout from '../../components/layout/Layout'
import ScheduleForm from '../../components/schedule/ScheduleForm'
import SectionTitle from '../../components/titles/SectionTitle'
import AppContext from '../../context/AppContext'
import { highlightError, removeHighlightError, validateFiled } from '../../utils/Validation'
import AssignDeviceForm from '../../components/guards/details/AssignDeviceForm'
import GuardInfoForm from '../../components/guards/details/GuardInfoForm'
import { GuardIncludesEnum } from '../../common/enums/GuardEnums'
import { useModelDetails } from '../../customHooks/useModelDetails'
import { useVocabulary } from '../../customHooks/vocabulary/useVocabulary'
import { VocabularyEnum } from '../../common/enums/VocabularyEnum'
import { useCalendarFormV2 } from '../../customHooks/useCalendarFormV2'
import { useHolidays } from '../../customHooks/useHolidays'
import { IHoliday } from '../../common/interfaces/IHoliday'
import { ICityDropdown } from '../../common/interfaces/dropdowns/ICityDropdown'
import ResourceDataService from '../../common/services/ResourceDataService'
import { AddNewItemEnum } from '../../common/enums/AddNewItemEnum'
import { guardFieldsValidationToBeIgnored } from 'utils/ValidationFields'
import React from 'react'
import useCountryField from 'customHooks/useCountryField'
import { OptionTypeBase } from 'react-select'
import { isObjectEmpty } from 'utils/IsObjectEmpty'
import { matchOptionWithName } from 'utils/MatchOptionWithName'

type RouteParams =  {id: string}

const AddEditGuard: FC<RouteComponentProps<RouteParams>> = (props) => {

    const { showLoader, showNotification } = useContext(AppContext)
    const {currentClient} = useContext(AppContext)
    const [userId, setUserId] = useState(props.match.params.id || undefined)
    const [guardHasDevice, setGuardHasDevice] = useState<boolean>(false)
    const [dropdownOpts, setDropdownOpts] = useState<{ cities: ICityDropdown[] }>({ cities: [] })

    const brandVocabulary = useVocabulary(VocabularyEnum.deviceBrand)
    const {
		countries,
		populateAddressFields,
		setCountryName,
		currentStates,
		postalCodeMask,
	} = useCountryField();

    const { allHolidays,getAllHolidays, prepopulateHolidays } = useHolidays();

    const { onCalendarSubmit, setCalendarFormData: setGuardData, onCalendarChange,
        onHolidayChange, onRemoveItemFromCalendar, trackHolidays, holidays, calendarFormData: guard,
        onChange
    } = useCalendarFormV2<IGuard>("guardAvailabilities", new Guard(), allHolidays)

    const { detailsModel, isLoading } = useModelDetails<IGuard>("guard", new Guard(), { include: [GuardIncludesEnum.availabilities, GuardIncludesEnum.device] })
    const svc = new DataService<IGuard>({ url: 'guard' })

    useEffect(() => {
        if (props.match.path.includes('/add')) {
            setGuardData(new Guard())
        }
        removeHighlightError()
    }, [props.match.path])

    useEffect(() => {
        showLoader(true)
        brandVocabulary.getVocabulary();
        getAllHolidays()
        getOptionsForDropdowns()
    }, [])
    
    useEffect(() => {
        setUserId(props.match.params.id || undefined)
    }, [props])

    // TODO: Maybe refactor logic
    useEffect(() => {
        if (props.match.params.id !== undefined) {
            if (isLoading === false) {
                if (allHolidays.length !== 0 && holidays.current.original.length !== 0) {
                    ifGuardHasDevice()
                    const data = prepopulateHolidays(detailsModel,
                        allHolidays.map(holiday => {
                            holiday.remove = false;
                            return holiday
                        }), 'guardAvailabilities',
                        (schedule, scheduleId) => trackHolidays(schedule, scheduleId)) as IGuard;
                    setGuardData({...data, employeeId: data.employeeId.toLowerCase().replace(data.institutionCode?.toLowerCase() || '', '')})
                    return
                }

                setGuardData(detailsModel)
            }
        }
    }, [isLoading, allHolidays, holidays.current.original])
    // TODO: Maybe refactor logic
    useEffect(() => {
        if (props.match.params.id === undefined) {
            if (JSON.stringify(guard) !== JSON.stringify(detailsModel)) {
                return
            }
            setGuardData(new Guard())
        }

    }, [props.match.params])

    const getOptionsForDropdowns = useMemo(() =>
        async () => {
            const citySvc = new ResourceDataService<ICityDropdown[]>({ url: "city/dropdown" })
            try {
                const resCities = await citySvc.getAll();
                setDropdownOpts({
                    cities: resCities.data,
                })
            } catch (e: any) {
                showNotification(NotificationTypes.danger, e.message)
            }
        }
    , [])

    const ifGuardHasDevice = () => {
        const fieldsToCheck = ['aspMobile', 'brand', 'model', 'serialNumber'];
        const nonEmptyFields = []
        for (let [deviceField, deviceValue] of Object.entries(detailsModel.device!)) {
            if (fieldsToCheck.includes(deviceField)) {
                deviceValue !== null && nonEmptyFields.push(deviceValue)
            }
        }

        setGuardHasDevice(nonEmptyFields.length === 0 ? false : true);
    }

    const onFormSubmit = async (e: any) => {
        e.preventDefault();
        let errors: IValidationBody[] = [];
        const errorsResult = (value: any, fieldName: string) => [...errors, ...validateFiled(value as string, fieldName, ValidationRules.required)];
        for (let [guardField, guardValue] of Object.entries(guard)) {
            const skipFields = guardFieldsValidationToBeIgnored;
            if (!skipFields.includes(guardField)) {
                errors = errorsResult(guardValue, guardField)

                if (guardField === "guardAvailabilities" ) {
                    let availabilityErrors: IValidationBody[] = []
                    guardValue.forEach((availability:IGuardAvailability) => {
                        availabilityErrors = [...availabilityErrors, ...validateFiled(availability.timeFrom as string, 'timeFrom', ValidationRules.notZero)]
                        availabilityErrors = [...availabilityErrors, ...validateFiled(availability.timeTo as string, 'timeTo', ValidationRules.notZero)]
                        availabilityErrors = [...availabilityErrors, ...validateFiled(availability.repeatOn as string, `repeatOn-${availability.uid ? availability.uid : availability.id}`, ValidationRules.required)]
                    })
                    errors = [...errors, ...availabilityErrors]
                }
            }
        }

        errors = [...errors, ...validateFiled(guard.homeMobile as string, "homeMobile", ValidationRules.isValidPhone)]
        errors = [...errors, ...validateFiled(guard.personalMobile as string, "personalMobile", ValidationRules.isValidPhone)]
        errors = [...errors, ...validateFiled(guard.postalCode as string, "postalCode", ValidationRules.isValidPostalCode)]
        errors = [...errors, ...validateFiled(guard.email as string, "email", ValidationRules.isValidEmail)]

        if (guard.device && !isObjectEmpty(guard.device, true)) {
            let emptyDeviceFields: IValidationBody[] = [];
            let guardDevice = {...guard.device}

            if (userId !== undefined && !guardHasDevice) {
                guardDevice = {...new GuardDevice(), ...guard.device}
            }

            // Validation for Create mode
            for (let [deviceField, deviceValue] of Object.entries(guardDevice)) {
                emptyDeviceFields = [...emptyDeviceFields, ...validateFiled(deviceValue as string, deviceField, ValidationRules.required)]
            }
            
            // Validation for Edit mode
            if (userId !== undefined) {
                if (guard.device.brand) {
                    errors = [...errors, ...validateFiled(guard.device.model as string, "model", ValidationRules.required)]
                }
                if (guard.device.model) {
                    errors = [...errors, ...validateFiled(guard.device.brand as string, "brand", ValidationRules.required)]
                }
            }
            if (emptyDeviceFields.length < 4 ) {
                errors = [...errors, ...emptyDeviceFields.filter(field => field.fieldName === "brand" || field.fieldName === "model")]
            }

            errors = [...errors, ...validateFiled(guard.device.aspMobile as string || "", "aspMobile", ValidationRules.isValidPhone)]
        }
        
        if (errors.length !== 0) {
            console.log('Errors: ', errors)
            const errorMsg = errors.some(err => err.fieldName.includes('latitude') || err.fieldName.includes("longitude")) 
            ? "Please fill in guard coordinates" : "Please fill in all fields correctly";
            showNotification(NotificationTypes.warning, errorMsg)
            highlightError(errors)
        } else {
            showLoader(true)
            try {
                const guardCopy = {...guard}
                guardCopy.email = guardCopy.email === "" ? null : guardCopy.email
                const fullName = `${guard.firstName} ${guard.middleName} ${guard.lastName}`
                removeHighlightError()

                const body = {...guardCopy, guardAvailabilities: guardCopy.guardAvailabilities.map(availability => {
                    if (availability.holidays.length !== 0) {
                        availability.holidays = availability.holidays
                        .filter((holiday: IHoliday) => holiday.remove)
                    }
                    return availability
                })}
                
                const response = userId !== undefined ? await svc.update(body, userId) : await svc.create(body)
                showNotification(NotificationTypes.success,
                    userId !== undefined  ? `Guard - ${fullName} has been updated` : `Guard - ${fullName} has been created`
                )
                showLoader(false)
                props.history.push(`/guards/${response.data}/details`)
            } catch (e: any) {
                removeHighlightError()
                showNotification(NotificationTypes.danger, e.message)
                showLoader(false)
            }
        }
    }

    const breadcrumbsLinks = () => {
        const baseLinks = [{
            title: 'Guards',
            link: 'guards'
        }]
        if (userId) {
            return [...baseLinks, ...[{ title: `${guard.firstName} ${guard.middleName} ${guard.lastName}`, link: `guards/${guard.id}/details` }]]
        } else { return baseLinks }
    }

    const addNewItemToDropdown = (option: any, type?: AddNewItemEnum) => {
        setDropdownOpts({
            ...dropdownOpts,
            cities: [...dropdownOpts.cities, ...[{ 
                id: option.id,
                name: option.cityName,
                zoneViewModels: []
            }]].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
        })
        onChange([
            { value: option.id, name: "cityId" },
            { value: option.cityName, name: "city" },
        ])
    }

    const populateFields = async (response: OptionTypeBase) => {
		populateAddressFields(response, ({province, postalCode, city}) => {
            const existedCity = dropdownOpts.cities.find(v => v.name === city);
            if (!existedCity) {
                setDropdownOpts({
                    ...dropdownOpts,
                    cities: [...dropdownOpts.cities, ...[{ 
                        id: -1,
                        name: city,
                        zoneViewModels: []
                    }]].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
                })
            }
            setGuardData((prev) => ({
                    ...prev,
                    postalCode,
                    province,
                    cityId: existedCity?.id ? prev.cityId || existedCity?.id || 0 : -1,
                    city: existedCity?.name ? prev.city || existedCity?.name || '' : city
                }))
            }  
		);
	};


    useEffect(() => {
        guard.country && countries.length && setCountryName(matchOptionWithName(guard.country || currentClient.country, countries)?.toString() || 'Canada');
		setGuardData(prev => ({...prev, country: guard.country || currentClient.country}))
	}, [guard.country, currentClient.country, countries]);
    const onCountrySelected = () => {
        guard.country  && setGuardData((prev) => ({
			...prev,
			firstStreet: '',
			postalCode: '',
            firstAddress: '',
			province: '',
			city: '',
		}));
    }

    const onCitySelected = () => {
        guard.city && setGuardData((prev) => ({
			...prev,
			firstStreet: '',
            firstAddress: '',
			postalCode: '',
			province: '',
		}));
    }

    return (
        <Layout
        breadcrumbs={{
            links: breadcrumbsLinks(),
            currentPageTitle: `${userId ? 'Edit Guard': 'Add a Guard'}`
        }}
        >
            <div className="row mb-3">
                <div className="col-12">
                    <SectionTitle title={`${userId ? 'Edit a Guard': 'Add a Guard'}`}/>
                </div>
            </div>
            <section>
                <form autoComplete="nope">
                    <div className="row mb-3">
                       <div className="col-lg-11 col-xl-9 mx-auto">
                            <GuardInfoForm
                                guard={guard}
                                countries={countries}
                                onChange={(e, fieldName) => onChange(e, fieldName)}
                                vocabulary={currentStates}
                                populateFields={populateFields}
                                postalCodeMask={postalCodeMask}
                                cities={dropdownOpts.cities}
                                onCountrySelected={onCountrySelected}
                                onCitySelected={onCitySelected}
                                addNewItemToDropdown={(option, type) => addNewItemToDropdown(option, type)}
                            />
                       </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-11 col-xl-9 mx-auto">
                            <h5 className="mb-4">User Role</h5>
                            <div className="form-group">
                                <div className="custom-control custom-checkbox d-flex align-items-center mt-0 mt-sm-4">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="isSupervisor"
                                        name='isSupervisor'
                                        checked={guard.isSupervisor}
                                        onChange={() => onChange(!guard.isSupervisor, 'isSupervisor')}
                                    />
                                    <label className="custom-control-label mb-0" htmlFor="isSupervisor">This Guard is also an ASP Supervisor</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {
                            React.useMemo(() => (
                                <div className="col-lg-11 col-xl-9 mx-auto py-4">
                                    <h5 className="mb-4">Add Guard Availability</h5>
                                    {
                                        guard && guard.guardAvailabilities.length !==0 && guard.guardAvailabilities.map((item, index) =>
                                            <ScheduleForm
                                                key={item.uid ? item.uid : item.id}
                                                onChange={(event, fieldName) => {
                                                    onCalendarChange(event, fieldName, item.uid ? item.uid : item.id)
                                                }}
                                                data={item}
                                                hideEndDate={true}
                                                subtitle={`${index + 1}. Availability`}
                                                trackHolidays={(holidayId: number, yesNoValue: boolean) =>
                                                    onHolidayChange(holidayId, yesNoValue, item.uid ? item.uid : item.id as number, 'guardAvailabilities', guard)
                                                }
                                                holidaysTitle={`There is/are holiday(s) that fall within this guard availability schedule. Do you want to <span class="font-weight-bold">remove holiday(s)</span> from guard availability?`}
                                                onRemoveItemFromCalendar={scheduleId => onRemoveItemFromCalendar(scheduleId, 'guardAvailabilities', guard)}
                                                tooltipDates={"Select or edit dates for the full periods that the Guard is available."}
                                                tooltipHours={"Select the hour, minutes and period of day (AM or PM)."}
                                                tooltipRepeatOn={"Select the days of the week during which this schedule should repeat weekly."}
                                            />
                                        )
                                    }
                                    <button
                                        type="button"
                                        className="btn btn-outline-aqua-blue text-dark-lighter mt-3"
                                        onClick={() => onCalendarSubmit(guard, updatedData => setGuardData(updatedData))}>
                                            Add another guard availability
                                    </button>
                                </div>
                            ), [guard.guardAvailabilities])
                        }
                        <AssignDeviceForm
                            device={guard?.device}
                            vocabulary={brandVocabulary.vocabulary}
                            onChange={(e, fieldName) => onChange(e, fieldName, "device")}
                        />

                        <div className="col-lg-11 col-xl-9 mx-auto d-flex py-2 py-md-5">
                            <Link className="btn btn-outline-aqua-blue" to="/guards">Cancel</Link>
                            <button type="submit" className="btn btn-aqua-blue ml-auto" onClick={e => onFormSubmit(e)}>Save Guard</button>
                        </div>
                    </div>
                </form>
            </section>
        </Layout>
    )
}

export default AddEditGuard
