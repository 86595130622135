import { messaging } from "common/config/firebaseInit";
import { ILoggedInUser } from "common/interfaces/IUser";
import { DataService } from "common/services/DataService";
import {  useState } from "react";
import firebase from 'firebase/app';
import { IPushNotificaion } from "common/interfaces/firebase/IPushNotificaion";

export const usePushNotifications = () => {
    const [fbNotifications, setFbNotifications] = useState<IPushNotificaion[]>([])
    // const [oldToken, setOldToken] = useState<string>("")
    
    const requestFBNotificationPermission = async (userToken?: string) => {
        if (!messaging) return;
        const currentToken = await messaging        
        .getToken({
            // vapidKey: 'BPUMlorPxwdzHlfegsVfKcCtvic9uCu1WKiTbscfcTH3LLsCDa7wfkS5qeek_P2zd3r9mVCbFXf7s5Utl8A7O8I'
            vapidKey: 'BJhG_eIMXuPHzPwpuZLeh3CtURCy4E_LK0xlpu8QxbwD0sAeFITqadXXf4CSN1ngsUizQXme_TdgOJnAd_5mmQc'
        })
        if (currentToken) {
            sendDeviceTokenToServer(currentToken)
            return true
        } else {
            console.log("No registration token available. Request permission to generate one.", currentToken)
            return false
        }
       
    };

    const sendDeviceTokenToServer = async (deviceToken: string) => {
        const svc = new DataService({ url: 'user/firebase-token' })
        try {
            await svc.updateWithoutId({ firebaseDeviceToken: deviceToken })
        } catch (error: any) {
            console.log("Can't send token", error)
        }
    }

    const recieveMessage = (callback: (msg: IPushNotificaion) => void) => {
        if (!messaging) return;
        messaging.onMessage((message: IPushNotificaion) => {
            // For Testing reason
            console.log('Message received. ', message);
            callback(message)
            return
        });
    }

    const initCloudMsg = async (user: ILoggedInUser) => {
        if (!firebase.messaging.isSupported()) return;

        const hasPermissions = await requestFBNotificationPermission(user.firebaseDeviceToken)
        // setOldToken(user.firebaseDeviceToken)
        hasPermissions && recieveMessage(msg => {
          setFbNotifications(prevState => [...prevState, ...[msg]])
        })
      }

    return {
        requestFBNotificationPermission, recieveMessage, fbNotifications, initCloudMsg, setFbNotifications
    };
}