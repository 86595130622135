import React, { FC, Fragment, useCallback, useContext, useEffect } from 'react';
import { useFromToFilter } from '../../../../customHooks/useFromToFilter';
import { momentDateFormat, momentHoursFormat } from '../../../../utils/DateFormatting';
import FromToFilter from '../../../guards/details/FromToFilter';
import DataTable from '../../../tables/DataTable';
import ReportActions from '../../../reports/ReportActions';
import { JobIncludesEnum } from '../../../../common/enums/JobEnums';
import JobDetailsTabsContext from '../../../../context/jobs/JobDetailsTabsContext';
import { useVerifyRolesTHead } from '../../../../customHooks/useVerifyRolesTHead';
import { holidaysTabTable } from '../../../../content/jobs/JobsContent';
import { IHolidayData, IJobHoliday, IScheduleHoliday } from 'common/interfaces/IHoliday';
import { matchWeekDays } from 'utils/MatchWeekDays';
import CustomCheckBox from '../../../checkbox/CustomCheckBox';
import { useState } from 'react';
import { SVGSave, SVGPencil } from 'assets/icons/SvgIcons';
import { DataService } from 'common/services/DataService';
import { NotificationTypes } from 'common/interfaces/INotification';
import AppContext from 'context/AppContext';
import moment from 'moment';
type Props = {
	holidays: IJobHoliday;
	fetchHolidays: (returnData: boolean, handleLoader: boolean) => Promise<IJobHoliday | undefined>;
	fetchData: () => Promise<void>;
};
interface IUpdatedHoliday {
	schedules: {
		id: number;
		holidays: IHolidayData[];
	}[];
}
const HolidaysTab: FC<Props> = ({ holidays, fetchHolidays, fetchData }) => {
	const { job, isLoading } = useContext(JobDetailsTabsContext);
	const { showNotification } = useContext(AppContext);
	const [isUpdateLoading, setIsUpdateLoading] = useState(false);
	const { onFilterMultiTable, formEmpty, onClear, filteredData, filterQuery } =
		useFromToFilter<IScheduleHoliday>(holidays.schedules);
	const [holidayData, setHolidayData] = useState(filteredData);
	const [isEditable, setIsEditable] = useState(false);
	const { theadTable, addVerifyRoles } = useVerifyRolesTHead();

	useEffect(() => {
		addVerifyRoles(holidaysTabTable.thead);
	}, [holidaysTabTable.thead]);

	useEffect(() => {
		!isEditable && setHolidayData(filteredData);
	}, [filteredData, isEditable]);

	useEffect(() => {
		setHolidayData(filteredData);
	}, [filteredData]);

	
	const filterSameDayHoliday = (originalHolidays: IHolidayData[]): IHolidayData[] => {
        const commonDateFormat = (date: string | Date) => moment.utc(date).format('YYYY-MM-DD');
		const filteredHolidays: IHolidayData[] = [];
        [...originalHolidays].forEach(holiday => {
            const index = filteredHolidays.findIndex((item: any) => commonDateFormat(item.startDate) === commonDateFormat(holiday.startDate) && commonDateFormat(item.endDate) === commonDateFormat(holiday.endDate))
            if (index > -1) {
				!filteredHolidays[index].name.includes(holiday.name) && (filteredHolidays[index].name = filteredHolidays[index].name + `, ${holiday.name}`)
            } else {
                filteredHolidays.push({...holiday})
            }
        })
		return filteredHolidays;
    }
	const onSaveChanges = async () => {
		const dataSvc = new DataService<IUpdatedHoliday>({ url: 'holiday/job' });
		const schedules = holidayData
			.map((schedule) => ({
				...schedule,
				holidays: filterSameDayHoliday(schedule.holidays).filter((holiday) => holiday.isApplied),
			}))
		try {
			setIsUpdateLoading(true);
			await dataSvc.update({ schedules }).finally(() => {
				fetchHolidays(false, true);
				fetchData();
				setIsEditable(false);
				setIsUpdateLoading(false);
			});
		} catch (error: any) {
			setIsUpdateLoading(false);
			showNotification(NotificationTypes.danger, error.message);
		}
	};

	const onApplied = (scheduleId: number, holidayId: number) => {
		setHolidayData((prev) =>
			prev.map((schedule) =>
				schedule.id === scheduleId
					? {
							...schedule,
							holidays: filterSameDayHoliday(schedule.holidays).map((holiday) =>
								holiday.id === holidayId
									? { ...holiday, isApplied: !holiday.isApplied }
									: holiday
							),
					  }
					: schedule
			)
		);
	};
	const onAppliedAllSchedule = (scheduleId: number, isApplied: boolean) =>
		setHolidayData((prev) =>
			prev.map((schedule) =>
				schedule.id === scheduleId
					? {
							...schedule,
							holidays: filterSameDayHoliday(schedule.holidays).map((holiday) => ({
								...holiday,
								isApplied,
							})),
					  }
					: schedule
			)
		);
	const onAppliedAll = (scheduleId: number) => {
		holidayData.some((schedule) =>
			schedule.id === scheduleId ? schedule.holidays.every((hol) => hol.isApplied) : false
		)
			? onAppliedAllSchedule(scheduleId, false)
			: onAppliedAllSchedule(scheduleId, true);
	};

	const renderedTable = 
		React.useMemo(
		() =>
			holidayData &&
			holidayData.length &&
			holidayData.map((schedule, i) => (
				<Fragment key={schedule.id}>
					<div className='d-flex justify-content-around align-items-center flex-wrap mt-2 py-3 mb-2 bg-alto over-header'>
						<p className="mb-0">
							<span>Schedule: </span>
							{momentDateFormat(schedule.startDate)} -{' '}
							{momentDateFormat(schedule.endsOn)}
						</p>
						<p className="mb-0">
							<span>ASP Start Time: </span>
							{momentHoursFormat(schedule.aspStartDate, schedule.timeFrom, true)}
						</p>
						<p className="mb-0">
							<span>Times: </span>
							{momentHoursFormat(schedule.timeFrom)} -{' '}
							{momentHoursFormat(schedule.timeTo, schedule.timeFrom)}
						</p>
						<p className="mb-0">
							<span>Repeat: </span>
							{matchWeekDays(schedule.repeatOn)}
						</p>
					</div>
					<DataTable
						thead={theadTable}
						tbody={filterSameDayHoliday([...schedule.holidays]).map((holiday: IHolidayData) => ({
							id: holiday.id ? holiday.id : null,
							schoolBoard: holiday.boardName || '-',
							holidayType: holiday.holidayType || '-',
							name: holiday.name || '-',
							startDate: momentDateFormat(holiday.startDate),
							endDate: momentDateFormat(
								holiday.endDate ? holiday.endDate : '-'
							),
							isApplied: holiday.isApplied,
						}))}
						tableClass="mb-4"
						ignoreCols={[0, 6]}
						defaultSortedColumn={3}
						onSelectAllCheckbox={() =>
							isEditable ? onAppliedAll(schedule.id) : null
						}
						hideSelectAll={!isEditable}
						isLoading={isLoading || isUpdateLoading}>
						{(holidayId, rowItem: IHolidayData) => (
							<>
								<td className='align-middle'>
									<CustomCheckBox
										id={`${holidayId}-${schedule.id}`}
										checked={rowItem.isApplied}
										onChange={() =>
											onApplied(schedule.id, holidayId)
										}
										disabled={!isEditable}
									/>
								</td>
							</>
						)}
					</DataTable>
				</Fragment>
			)),
		[holidayData, isEditable, isLoading, isUpdateLoading, theadTable]
	)

	return (
		<>
			{
				holidays.schedules?.length
					? 
				(
					<div>
						<div className='d-flex flex-wrap align-items-center justify-content-between mb-4'>
							<h5>Holidays</h5>
							<ReportActions
								title='Job holiday report:'
								id={job.id}
								tabName={JobIncludesEnum.holidays}
								page='job'
								query={filterQuery}
								showPrint
								showForShiftReport={false}
							/>
						</div>
						<div className='d-flex flex-wrap align-items-center '>
							<h6 className='pt-4 mb-3 mr-3'>
								# Holidays for this Job: {holidays.holidaysNumber}
							</h6>
							<FromToFilter
								isEmpty={formEmpty}
								onSubmit={(from, to) => onFilterMultiTable(from, to, '', '', 'holidays')}
								onClear={() => onClear()}
								isMinDate={false}
								schoolYear={job.schoolYear}
							/>
							
						</div>
						<div className="row">
							<div className='col '>
								{!isEditable ? (
									<button className={`btn btn-outline-aqua-blue btn-transparent btn-sm d-block ml-auto d-flex align-items-center h-auto`} onClick={() => setIsEditable(true)}>
										<SVGPencil className="mr-2"/>
										<span >Edit Applied Holiday</span>
									</button>
								) : (
									<div className="d-flex">
										<button className={`btn btn-outline-aqua-blue btn-sm d-flex align-items-center ml-auto h-auto mr-2`} onClick={onSaveChanges}>
                      <SVGSave />
                      <span className="pl-2 text-end">Save Applied Holiday</span>
										</button>
										<button className={`btn btn-aqua-blue btn-sm d-block h-auto`} onClick={() => setIsEditable(false)}>
											Cancel
										</button>
									</div>
								)}
							</div>
						</div>
						<div className='row'>
							<div className='col-md-12 ml-auto'>
								{renderedTable}
							</div>
						</div>
					</div>
				)
					: 
				(
					<div className='p-3'>
						<h5 className='text-center'>There is/are no holiday(s) that fall within this job schedules</h5>
					</div>
				)
			}
		</>
	);
};

export default HolidaysTab;
