import React, {FC, useContext, useState, useEffect} from 'react'
import {SVGCalendar} from '../../../assets/icons/SvgIcons'
import DatePicker from "react-datepicker";
import {IBaseModalProps} from '../../../common/interfaces/IModal'
import {Holiday} from '../../../common/models/Administration'
import {AddNewItemEnum} from '../../../common/enums/AddNewItemEnum'
import {NotificationTypes} from '../../../common/interfaces/INotification'
import {IDropdown} from '../../../common/interfaces/dropdowns/IDropdown'
import {IValidationBody, ValidationRules} from '../../../common/interfaces/IValidation'
import BaseModal from '../BaseModal'
import AddNewItemModal from '../AddNewItemModal'
import CustomSelectControl from '../../custom-select/CustomSelectControl'
import {validateFiled, highlightError, removeHighlightError} from '../../../utils/Validation'
import {datePickerFormat, setHoursAndMinutes} from '../../../utils/DateFormatting'
import {useDatapickerSvg} from '../../../customHooks/useDatapickerSvg'
import {useOnIputChange} from '../../../customHooks/useOnInputChange'
import {useAddNewItem} from '../../../customHooks/useAddNewItem'
import AppContext from '../../../context/AppContext'
import MulTipleCheckbox from '../../../components/miltiple-checkbox/miltiple-checkbox'
import {useMultipleCheckbox} from "../../../customHooks/useMultipleCheckbox";

interface Props extends IBaseModalProps {
    holidayTypes: IDropdown[]
    holidayNames: IDropdown[]
    schoolBoards: Array<{ id: number, name: string }>
    saveNewItem: (option: any, type: AddNewItemEnum) => void
}

const AddHolidayModal: FC<Props> = ({onCancel, onSubmit, holidayTypes, holidayNames, schoolBoards, saveNewItem}) => {
    const [showMainModal, setShowMainModal] = useState<boolean>(true)
    const {showDatapicker, changeDatapicker} = useDatapickerSvg()
    const {onChangeCheckbox, onRemoveCheckbox, allCheckbox} = useMultipleCheckbox()
    const {showAddNewItemModal, newItemModalTypo, onAddNewItemClick, onAddNewItemSubmit, onAddNewItemModalClose} = useAddNewItem();
    const {onChange, hookState: formData, setHookState} = useOnIputChange(new Holiday())
    const {showNotification} = useContext(AppContext)

    useEffect(() => {
        onChange(allCheckbox.length > 0 ? true : false, 'applyHolidayToAllShifts')
    }, [allCheckbox])

    useEffect(() => {
        return () => setHookState(new Holiday())
    }, [])
    
    const validateHolidayFields = (callback: () => void) => {
        let errors: IValidationBody[] = [];
        for (let [field, value] of Object.entries(formData)) {
            const skipFields = ['id', 'boardName', 'schoolBoardId', 'boardId']
            if (!skipFields.includes(field)) {
                errors = [...errors, ...validateFiled(value as string, field, ValidationRules.required)]
            }
        }
        if (errors.length !== 0) {
            showNotification(NotificationTypes.warning, "Please fill in all fields correctly")
            highlightError(errors)
        } else {
            removeHighlightError()
            callback()
        }
    }

    const onSaveDataClick = () => {
        onSubmit && validateHolidayFields(() => onSubmit(formData))
    }

    const handleModals = (showMainModal: boolean, type?: AddNewItemEnum) => {
        setShowMainModal(showMainModal)
        !showMainModal && type && onAddNewItemClick(type)
    }

    const checkIfHolidayIsPublic = (holidayType: string) => (
        holidayType.toLowerCase().includes('public')
    )

    return (
        <>
            <BaseModal
                show={showMainModal}
                onCancel={() => onCancel()}
                onSubmit={() => onSaveDataClick()}
                submitBtnText={'Save'}
                title="Add a Holiday"
            >

                <form onSubmit={e => e.preventDefault()}>
                    <div className="row">

                        <div className="col-md-12 form-group">
                            <div className="add-item-group d-flex flex-row align-items-center justify-content-between">
                                <label className="required mb-0">Holiday Type: </label>
                                <button type="button" className="btn btn-transparent p-0 text-primary ml-auto"
                                        onClick={() => handleModals(false, AddNewItemEnum.holidayType)}>Add Holiday Type
                                </button>
                            </div>
                            <CustomSelectControl
                                options={holidayTypes}
                                onChange={(_, label) => {
                                    checkIfHolidayIsPublic(label as string)
                                    ? onChange([
                                        {value: "", name: "boardId"},
                                        {value: "", name: "boardName"},
                                        {value: "", name: "schoolBoardId"},
                                        {value: label as string, name: "holidayType"},
                                    ])
                                    : onChange(label as string, "holidayType")
                                }}
                                value={formData.holidayType}
                                placeholder={"Select a Holiday Type"}
                                className={`custom-react-select--holidayType`}
                                valueIsLabel
                            />
                        </div>

                        {
                            !checkIfHolidayIsPublic(formData.holidayType) &&
                            <div className="col-md-12 form-group">
                                <div className="add-item-group d-flex flex-row align-items-center justify-content-between">
                                    <label>School Board: </label>
                                </div>
                                <CustomSelectControl
                                    options={schoolBoards}
                                    onChange={(value) => {
                                        onChange([
                                            {value: value, name: "boardId"},
                                            {value: value, name: "schoolBoardId"},
                                        ])
                                    }}
                                    value={formData.boardId}
                                    isMulti
                                    placeholder={"Select a School Board(s)"}
                                    className={`custom-react-select--boardId`}
                                    isClearable
                                />
                            </div>
                        }

                        <div className="col-md-12 form-group">
                            <div className="add-item-group d-flex flex-row align-items-center justify-content-between">
                                <label className="required mb-0">Holiday Name: </label>
                                <button type="button" className="btn btn-transparent p-0 text-primary ml-auto"
                                        onClick={() => handleModals(false, AddNewItemEnum.holidayName)}>Add Holiday Name
                                </button>
                            </div>
                            <CustomSelectControl
                                options={holidayNames}
                                onChange={(_, label) => onChange(label as string, "holidayName")}
                                value={formData.holidayName}
                                placeholder={"Select a Holiday Name"}
                                className={`custom-react-select--holidayName`}
                                valueIsLabel
                            />
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="required mr-3">Holiday Start Date: </label>
                            <div className="react-datepicker-custom-wrapper d-inline-block">
                                <DatePicker
                                    selected={datePickerFormat(formData.startDate)}
                                    onChange={date => {
                                        date && onChange(setHoursAndMinutes(date as Date), "startDate")
                                        changeDatapicker(2)
                                    }}
                                    dateFormat="dd/MM/yyyy"
                                    onInputClick={() => changeDatapicker(2)}
                                    open={showDatapicker[2]}
                                    onClickOutside={() => changeDatapicker(2)}
                                />
                                <div onClick={() => changeDatapicker(2)}><SVGCalendar/></div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <div className="ml-0 ml-lg-3">
                                <label className="required mr-4">Holiday End Date: </label>
                                <div className="react-datepicker-custom-wrapper d-inline-block">
                                    <DatePicker
                                        selected={datePickerFormat(formData.endDate)}
                                        onChange={date => {
                                            date && onChange(setHoursAndMinutes(date as Date), "endDate")
                                            changeDatapicker(1)
                                        }}
                                        dateFormat="dd/MM/yyyy"
                                        onInputClick={() => changeDatapicker(1)}
                                        open={showDatapicker[1]}
                                        onClickOutside={() => changeDatapicker(1)}
                                    />
                                    <div onClick={() => changeDatapicker(1)}><SVGCalendar/></div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-12 form-group d-flex align-items-center cursor-pointer"> 
                            <div className="custom-control custom-checkbox mr-2">
                                <MulTipleCheckbox
                                    id={'applyHolidayToAllShifts'}
                                    onChange={() => {
                                        onChangeCheckbox('applyHolidayToAllShifts')
                                        onChange(allCheckbox.length > 0 ? true : false, 'applyHolidayToAllShifts')
                                    }}
                                    onRemove={onRemoveCheckbox}
                                    defaultValue={formData.applyHolidayToAllShifts}
                                    /> 
                            </div>
                            <label htmlFor="applyHolidayToAllShifts" className={'mb-0 f20'}>Apply this holiday to all shifts for all school boards</label>
                        </div>

                    </div>
                </form>

            </BaseModal>

            {
                showAddNewItemModal && <AddNewItemModal
                    showModal={showAddNewItemModal}
                    titleBody={newItemModalTypo.title}
                    placeholderText={newItemModalTypo.placeholder}
                    onCancel={() => {
                        onAddNewItemModalClose()
                        setShowMainModal(true)
                    }}
                    onSubmit={fieldValue => {
                        onAddNewItemSubmit({itemName: fieldValue}, (newItem, type) => {
                            if (type === AddNewItemEnum.holidayType) {
                                onChange([
                                    {value: newItem.name, name: "holidayType"},
                                ])
                            }

                            if (type === AddNewItemEnum.holidayName) {
                                onChange([
                                    {value: newItem.name, name: "holidayName"},
                                ])
                            }
                            saveNewItem(newItem, type)
                        }, true)
                        setShowMainModal(true)
                    }}
                />
            }

        </>
    )
}

export default AddHolidayModal
