import React, {FC, useContext, useEffect} from 'react'
import { useState } from 'react'
import { SVGPlus } from '../../../../assets/icons/SvgIcons'
import { IAvailableGuard } from '../../../../common/interfaces/guards/IGuard'
import { IJobShiftsInNeedOfGuards } from '../../../../common/interfaces/jobs/IJob'
import { IJobModals } from '../../../../common/interfaces/jobs/IJobModals'
import { IVocabulary } from '../../../../common/interfaces/vocabulary/IVocabulary'
import JobDetailsTabsContext from '../../../../context/jobs/JobDetailsTabsContext'
import { momentDateFormat, momentHoursFormat } from '../../../../utils/DateFormatting'
import { getHolidayField } from '../../../../utils/GetFieldFromTable'
import { matchOptionWithName } from '../../../../utils/MatchOptionWithName'
import AvailableGuards from '../../../guards/AvailableGuards'
import BaseModal from '../../../modal/BaseModal'
import ShiftAssignedSuccessModal from '../../../modal/jobs/ShiftAssignedSuccessModal'
import DataTable from '../../../tables/DataTable'
import ReportActions from "../../../reports/ReportActions";
import {JobIncludesEnum} from "../../../../common/enums/JobEnums";
import { useRole } from 'customHooks/useRole'
import {shiftsInNeedTabTable} from "../../../../content/jobs/JobsContent";
import {useVerifyRolesTHead} from "../../../../customHooks/useVerifyRolesTHead";

type Props = {
    onSubmit: (guard: IAvailableGuard, shiftId: number) => void
    modalsControl: IJobModals
    toggleModal: (name: string, value: boolean) => void;
    vocabularyReasons: IVocabulary[]
}

const ShiftsInNeed: FC<Props> = ({ onSubmit, modalsControl, toggleModal, vocabularyReasons }) => {
    const { isLoading, job, vocabulary } = useContext(JobDetailsTabsContext)
    const [showModal, setShowModal] = useState<boolean>(false)
    const [selectedItem, setSelectedItem] = useState<{shift: IJobShiftsInNeedOfGuards, guard: IAvailableGuard}>({
        shift: {} as IJobShiftsInNeedOfGuards,
        guard: {} as IAvailableGuard
    })
    const { isClientRole, isASPAndAdminRole, isSupervisorRole } = useRole()
    const {theadTable, addVerifyRoles} = useVerifyRolesTHead()

    useEffect(() =>{
        addVerifyRoles(shiftsInNeedTabTable.thead)
    }, [shiftsInNeedTabTable])

    const onAssignGuardClick = (shiftId: number) => {
        setShowModal(true)
        const shift = job.shiftsInNeedOfGuards.find(shift => shift.id === shiftId)
        setSelectedItem({
            ...selectedItem,
            shift: shift ? shift : {} as IJobShiftsInNeedOfGuards
        })
    }

    return (
        <div className="p-2">
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                <h5>Shifts in Need of Guards</h5>
                <ReportActions title="Unassigned shifts report:" id={job.id} tabName={JobIncludesEnum.shiftsInNeed} page="job" showForShiftReport={false} showPrint/>
            </div>
            {
                React.useMemo(() => (
                    <DataTable
                        tableClass={"table-info--lastColHolidays table-info--notFixed"}
                        responsive="xl"
                        thead={theadTable}
                        tbody={
                            job.shiftsInNeedOfGuards ? job.shiftsInNeedOfGuards
                            .map(shift => (
                            {
                                id: shift.id,
                                shiftDate: momentDateFormat(shift.startDate),
                                reason: matchOptionWithName((shift.reason as string | number), vocabularyReasons) ? matchOptionWithName((shift.reason as string | number), vocabularyReasons) : `New Shift`,
                                shiftTimes: `${momentHoursFormat(shift.timeFrom)} - ${momentHoursFormat(shift.timeTo, shift.timeFrom)}`,
                                shiftPeriod: matchOptionWithName(shift.shiftPeriod, vocabulary),
                            }
                        )) : []}
                        ignoreCols={[0]}
                        isLoading={isLoading}
                    >
                        {
                            shiftId => (
                                <>
                                    { !isClientRole() && !isSupervisorRole() &&
                                        <td className="align-middle">
                                            <button
                                            type="button"
                                            className={`btn btn-aqua-blue`}
                                            onClick={() => onAssignGuardClick(shiftId)}
                                            ><SVGPlus /></button>
                                        </td>
                                    }
                                    <td className="align-middle">
                                        { getHolidayField(shiftId, job.shiftsInNeedOfGuards) }
                                    </td>
                                </>
                            )
                        }
                    </DataTable>
                ), [job.shiftsInNeedOfGuards, vocabulary, theadTable])
            }
            {
                isASPAndAdminRole() &&
                <>
                {
                    showModal && <BaseModal
                        show={showModal}
                        onCancel={() => setShowModal(false)}
                        className="available-guards-modal"
                        cancelBtnText={'Cancel'}
                    >
                        <AvailableGuards
                            onSubmit={guard => {
                                setSelectedItem({
                                    ...selectedItem,
                                    guard: guard
                                })
                                onSubmit(guard, selectedItem.shift.id)
                                setShowModal(false)
                            }}
                            title={`Assign Guard for Selected Job Shift for <h5 class="font-weight-bold">${job.jobName}</h5>`}
                            selectedItem={selectedItem.shift}
                        />
                    </BaseModal>
                }

                {
                    modalsControl.addGuardSuccess && <ShiftAssignedSuccessModal
                    show={modalsControl.addGuardSuccess}
                    onClose={() => toggleModal('addGuardSuccess',false)}
                    selectedGuard={selectedItem.guard}
                    selectedShift={{
                        ...selectedItem.shift,
                        jobName: job.jobName
                    }}
                    vocabulary={vocabulary}
                />
                }
                </>
            }
        </div>
    )
}

export default ShiftsInNeed
