import React, { FC } from 'react'
import SimpleBar from 'simplebar-react'
import { IVocabulary } from '../../../../common/interfaces/vocabulary/IVocabulary'
import { momentDateFormat, momentHoursFormat } from '../../../../utils/DateFormatting'
import { matchOptionWithName } from '../../../../utils/MatchOptionWithName'
import DataTable from '../../../tables/DataTable'

type TProps = {
    title: string
    shifts: Array<any>
    vocabulary: IVocabulary[]
    className?: string
}

const ConfirmBulkShiftBody: FC<TProps> = ({ title, shifts, vocabulary, className }) => {
  return (
    <div className={`row align-items-start ${className ? className : ''}`}>
        <div className="col-12 assign-guard-guards">
            <h4 className="mb-3" dangerouslySetInnerHTML={{ __html: title }} />
            

            <SimpleBar style={{ maxHeight: 200 }} autoHide={false}>
                <DataTable
                    thead={[{ label: "Job Name" }, { label: "Date" },  { label: "ASP Start Time"}, { label: 'Shift Times' }, { label: 'Guard Name' }, { label: 'Shift Period' }]}
                    tbody={shifts.length !== 0 ? shifts.map(shift => ({
                        jobName: shift.jobName,
                        shiftDate: momentDateFormat(shift.startDate),
                        aspStartDate: momentHoursFormat(shift.aspStartDate, shift.timeFrom, true) || '-',
                        shiftTimes: `${momentHoursFormat(shift.timeFrom)} - ${momentHoursFormat(shift.timeTo, shift.timeFrom)}`,
                        guardName: (shift?.firstName && shift?.lastName) ? `${shift?.firstName} ${shift?.lastName}` :  `-`,
                        shiftPeriod: matchOptionWithName(shift.shiftPeriod, vocabulary),
                    })): []}
                    tableClass={"table-success-bulk-shifts mb-0"}
                />
            </SimpleBar>
        </div>
    </div>
  )
}

export default ConfirmBulkShiftBody