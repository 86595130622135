import moment from 'moment'
import React from 'react'
import { FC } from 'react'
import { IJob, IJobDetails } from '../../common/interfaces/jobs/IJob'
import { DropdownsState } from '../../pages/jobs/AddEditJob'
import { momentDateFormat } from '../../utils/DateFormatting'
import { matchOptionWithName } from '../../utils/MatchOptionWithName'
import GeofenceMap from './GeofenceMap'
import "./MainJobData.scss"
import SectionTitle from 'components/titles/SectionTitle'

type Props = {
    job: IJob | IJobDetails
    dropdownOpts?: DropdownsState
    jobId?: string;
    jobDetailsPage?: boolean;
}

const MainJobData: FC<Props> = ({ job, dropdownOpts, jobId, jobDetailsPage = true }) => {
    const matchIdsWithLabels = (schoolId?: number) => {
        return {
            clientName:
                dropdownOpts ?
                (job.clientId && matchOptionWithName(job.clientId, dropdownOpts.clients))
                : job.clientName,
            clientEmail:
                dropdownOpts ?
                dropdownOpts && dropdownOpts.clients.find(client => client.id === job.clientId)?.clientContacts.find(contact => contact.id === job.clientContactId)?.email
                : job.email,
            clientPhone:
                dropdownOpts ?
                dropdownOpts && dropdownOpts.clients.find(client => client.id === job.clientId)?.clientContacts.find(contact => contact.id === job.clientContactId)?.phone
                : job.phone,
            city: dropdownOpts ? job.cityId && matchOptionWithName(job.cityId, dropdownOpts.cities) : job.city,
            zone: dropdownOpts ? dropdownOpts.zones : undefined,
            schoolBoard: dropdownOpts && dropdownOpts.schools,
            jobStartDate: job?.jobSchedules?.length !== 0 ? job?.jobSchedules?.sort((a,b) =>  new Date(a.startDate).getTime() - new Date(b.startDate).getTime())[0].startDate : '',
            jobEndDate: new Date(Math.max(...(job?.jobSchedules?.map(schedule => new Date(schedule.endsOn).getTime()) || []))),
            school: dropdownOpts && dropdownOpts.schools.find(school => school.id === schoolId)?.schools,
            schoolYear: dropdownOpts && dropdownOpts.clients.find(client => client.id === job.clientId)?.schoolYears.find(year => year.id === job.schoolYearId)
        }
    }

    const countGuards = (): number => {;
        let guardIds: number[] = [];
        job.jobSchedules.forEach(schedule => schedule.guardId && guardIds.push(schedule.guardId as number))
        return new Set(guardIds).size
    }

    const getSchoolYear = () => {
        if (job.schoolYear) {
            return `${moment(job.schoolYear.split('-')[0].trim()).format('MMM DD Y')} - ${moment(job.schoolYear.split('-')[1].trim()).format('MMM DD Y')}`
        }
    }

    return (
		<div>
			<div className="row">
				<div className={`col-10 col-lg-${jobDetailsPage ? 5 : 6} d-flex flex-column justify-content-between`}>
					<div>
						<div className="row mb-3">
							<div className="col-4 details-information__title mb-0">
								<h6 className="mb-0">City:</h6>
							</div>
							<div className="col-8 details-information__value">
								<p className="mb-0">{job.city || ''}</p>
							</div>
						</div>

						<div className="row mb-3">
							<div className="col-4 details-information__title mb-0">
								<h6 className="mb-0">Zone:</h6>
							</div>
							<div className="col-8 details-information__value">
								<p className="mb-0">
									{dropdownOpts && job.zoneId
										? matchOptionWithName(job.zoneId, matchIdsWithLabels().zone)
										: job.zone}
								</p>
							</div>
						</div>

						<div className="row mb-3">
							<div className="col-4 details-information__title mb-0">
								<h6 className="mb-0"># of Guards:</h6>
							</div>
							<div className="col-8 details-information__value">
								<p className="mb-0">{dropdownOpts ? countGuards() : job.guardsCount}</p>
							</div>
						</div>

						<div className="row mb-3">
							<div className="col-4 details-information__title mb-0">
								<h6 className="mb-0">Job Start Date:</h6>
							</div>
							<div className="col-8 details-information__value">
								<p className="mb-0">
									{dropdownOpts
										? momentDateFormat(matchIdsWithLabels().jobStartDate)
										: momentDateFormat(job.jobStartDate)}
								</p>
							</div>
						</div>

						<div className="row mb-3">
							<div className="col-4 details-information__title mb-0">
								<h6 className="mb-0">Job End Date:</h6>
							</div>
							<div className="col-8 details-information__value">
								<p className="mb-0">
									{dropdownOpts ? (
										<>{momentDateFormat(matchIdsWithLabels().jobEndDate)}</>
									) : (
										momentDateFormat(job.jobEndDate)
									)}
								</p>
							</div>
						</div>

						<div className="row mb-3">
							<div className="col-4 details-information__title mb-0">
								<h6 className="mb-0">School Year:</h6>
							</div>
							<div className="col-8 details-information__value">
								<p className="mb-0">
									{dropdownOpts
										? `${moment
												.utc(matchIdsWithLabels().schoolYear?.startDate)
												.format('MMM DD Y')} - ${moment
												.utc(matchIdsWithLabels().schoolYear?.endDate)
												.format('MMM DD Y')}`
										: getSchoolYear()}
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className={`col-10 col-lg-${jobDetailsPage ? 5 : 6} d-flex flex-column justify-content-between`}>
					<div className="main-job-data__related-schools">
						<div className="mb-3">
							<div>
								<div className="main-job-data__info-type-title mt-0">Job Location</div>

								<div className="row mb-3">
									<div className="col-4 details-information__title mb-0">
										<h6 className="mb-0">Geo Fence:</h6>
									</div>
									<div className="col-8 details-information__value">
										<p className="mb-0">{job.geoFence} meters</p>
									</div>
								</div>

								<div className="row mb-3">
									<div className="col-4 details-information__title mb-0">
										<h6 className="mb-0">Comments:</h6>
									</div>
									<div className="col-8 details-information__value">
										<p className="mb-0">{job.comments}</p>
									</div>
								</div>
							</div>
							<div className="main-job-data__info-type-title">Related Schools</div>
							{job.schools?.map((school) => (
								<div className="row" key={school.schoolBoardId}>
									<div className="col-4 mb-0">
										{dropdownOpts
											? school.schoolBoardId &&
											  matchOptionWithName(
													school.schoolBoardId,
													matchIdsWithLabels().schoolBoard
											  )
											: school.boardName}
									</div>
									<div className="col-8">
										<small className="font-italic pt-2">
											{dropdownOpts
												? school.schoolId &&
												  matchOptionWithName(
														school.schoolId,
														matchIdsWithLabels(school.schoolBoardId as number).school
												  )
												: school.schoolName}
										</small>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
			<div className="col-md-12 my-md-3 my-md-5 p-0">
				{job.latitude && job.longititude && (
					<GeofenceMap
						lat={+job.latitude}
						lng={+job.longititude}
						radius={job.geoFence ? +job.geoFence : (0 as number)}
					/>
				)}
			</div>
		</div>
	);
}

export default MainJobData
