import { AuthService } from "common/auth/AuthService"
import { Roles } from "common/enums/Roles"
import AppContext from "context/AppContext"
import { useContext, useCallback } from "react"

export const useRole = () => {
    const { currentUser } = useContext(AppContext)

    const isClientRole = useCallback(() => 
    AuthService.checkIfUserHasPermission([Roles.Client], currentUser.role), [currentUser]);

    const isASPAndAdminRole = useCallback(() => 
    AuthService.checkIfUserHasPermission([Roles.Admin, Roles.ASP, Roles.SuperAdmin], currentUser.role), [currentUser]);

    const isAdminRole = useCallback(() => 
    AuthService.checkIfUserHasPermission([Roles.Admin], currentUser.role), [currentUser])

    const isSupervisorRole = useCallback(() => 
    AuthService.checkIfUserHasPermission([Roles.Supervisor], currentUser.role), [currentUser])

    const isSuperAdminRole = useCallback(() => 
    AuthService.checkIfUserHasPermission([Roles.SuperAdmin], currentUser.role), [currentUser])

    return {
        isClientRole, isASPAndAdminRole, isAdminRole, isSupervisorRole, isSuperAdminRole
    }
}