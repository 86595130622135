import React, { FC, useRef, useState, ReactNode, useEffect, Fragment, useContext } from 'react'
import { LoadScript, GoogleMap, Marker, InfoWindow, Circle } from '@react-google-maps/api';
import Pin from '../../assets/icons/jobPin.svg'
import PinTentant from '../../assets/icons/jobPinTentant.svg'
import PinOnTime from '../../assets/icons/jobPinOnTime.svg'
import PinASPLate from '../../assets/icons/jobPinASPLate.svg'
import Guard from '../../assets/icons/guard.svg'
import { Link } from 'react-router-dom';
import { circleOptions } from '../../common/interfaces/maps/IMaps';
import { SVGGuardStatus, SVGInDoor, SVGOutDoor } from '../../assets/icons/SvgIcons';
import CustomTooltip from '../custom-tooltip/CustomTooltip';
import { useCheckLate } from '../../customHooks/useCheckLate';
import { IJobsRightNow } from '../../common/interfaces/jobs/IJob';
import { JobOnMapState } from '../../customHooks/useJobMarkers';
import useGuardLocation from '../../customHooks/useGuardLocation';
import { momentHoursFormat } from '../../utils/DateFormatting';
import { shiftStatusTooptipContent } from 'content/jobs/JobsContent';
import { useRole } from 'customHooks/useRole';
import useCoordinatesByAddress from 'customHooks/useCoordinatesByAddress';
import AppContext from 'context/AppContext';
import { matchOptionWithoutOther } from 'utils/MatchOptionWithName';
import { useVocabulary } from 'customHooks/vocabulary/useVocabulary';
import { VocabularyEnum } from 'common/enums/VocabularyEnum';

type Props = {
    // jobMarkers: JobOnMapState[]
    jobMarkers: JobOnMapState[]
    isLoading?: boolean
    showMarkerDetails?:boolean
    currShiftPeriodId?: number
    jobsNow?: IJobsRightNow[]
    showGuardPosition?:boolean
    mapAreaAsPin?: boolean
    mapZoom?: number
    removeLoadScript?: boolean
    showGeofenceOnLoad?: boolean
    children?: ReactNode
    showJobDetailsOnLoad?: boolean
    hideGuardIcon?: boolean
    blurInfoModal?: boolean
    defaultProvince?: string;
    defaultCenter?: {
        lat: number;
        lng: number;
    }
}


const JobsMapComponent: FC<Props> = ({ jobMarkers, showMarkerDetails, currShiftPeriodId, jobsNow, mapAreaAsPin, mapZoom, removeLoadScript,
    showGeofenceOnLoad, children, showJobDetailsOnLoad, hideGuardIcon = true, blurInfoModal = false, defaultProvince, defaultCenter
}) => {
    // const defaultMapCenter = useRef({ lat: 43.8137382, lng: -79.8403636 })
    const mapRef = useRef<google.maps.Map | null>(null);
    const { vocabulary: provinces } = useVocabulary(VocabularyEnum.province, true)
    const { vocabulary: states } = useVocabulary(VocabularyEnum.state, true)
    const {currentClient} = useContext(AppContext);
    const province = matchOptionWithoutOther(currentClient.province || '', [...provinces, ...states] || []) as string;
    const {coordinates} = useCoordinatesByAddress(
                `${currentClient.city}, ${province}, ${currentClient.firstAddress || ''}, ${currentClient.postalCode || ''}` || '',
                province
             )
    const defaultMapCenter = useRef({
        lat: mapAreaAsPin ? +jobMarkers[0].location.latitude : 43.692893, lng: mapAreaAsPin ? +jobMarkers[0].location.longititude : -79.4004406
    })
    const [infoModal, setInfoModal] = useState<{ showTooltip: boolean, infoModalIndx: number }>({ showTooltip: false, infoModalIndx: 0 })
    const { setShiftStatus } = useCheckLate()
    const { isASPAndAdminRole } = useRole()
    const { getGuardLocation, setGuardLocation, guardLocation } = useGuardLocation();
    useEffect(() => {
        // Works for single marker
        showJobDetailsOnLoad && handleMouseEvent(0, true, jobMarkers[0])
    }, [])

    const handleMouseEvent = (idx: number, state: boolean, marker?: JobOnMapState) => {
        if (state && marker?.guardName && marker.shiftId) {
            getGuardLocation(marker.shiftId)
        } else {
            hideGuardIcon && setGuardLocation(null)
        }
        setInfoModal({
            showTooltip: state,
            infoModalIndx: idx
        })
    }

    const shiftStatus = (shiftId: number) => {
        const status = setShiftStatus(shiftId, jobsNow ? jobsNow : [], currShiftPeriodId ? currShiftPeriodId : 0);
        return status
    }

    const setPinIcon = (marker: JobOnMapState) => {
        if (marker.shiftId) {
            if (shiftStatus(marker.shiftId)?.checkInClassName === 'check-tentant') {
                return PinTentant
            } else if (shiftStatus(marker.shiftId)?.checkInClassName === 'check-onTime') {
                return PinOnTime
            } else if (shiftStatus(marker.shiftId)?.checkInClassName === 'check-buffer') {
                return PinASPLate
            } else {
                return Pin
            }
        } else {
            return Pin
        }
    }

    useEffect(() => {
        if (mapRef.current) {
            const map = mapRef.current;
            const bounds = new window.google.maps.LatLngBounds();

            if (jobMarkers.length !== 0 && coordinates.lat) {
                jobMarkers.forEach(marker => {
                    bounds.extend({
                        lat: +marker.location.latitude,
                        lng: +marker.location.longititude
                    });
                });
            }

            if (!bounds.isEmpty()) {
                map.fitBounds(bounds);
            }
        }
    
    }, [jobMarkers, coordinates])

    const renderMap = React.useMemo(() => () => (
        <GoogleMap
            zoom={mapZoom ? mapZoom : 12}
            center={defaultCenter || coordinates}
            options={{
                streetViewControl: false
            }}
            mapContainerClassName="dialog-blur1"
            id="job-location-map"
            onMouseOut={() => !showJobDetailsOnLoad && handleMouseEvent(0, false)}
            onLoad={(map) => {
                mapRef.current = map;
            }}
            >
                {console.log(jobMarkers)}
            {
                jobMarkers.length !== 0 && jobMarkers.map((marker, index) => (
                    !marker.invisible &&
                    <React.Fragment  key={marker.id}>
                        <Marker
                            position={{ lat: +marker.location.latitude, lng: +marker.location.longititude }}
                            icon={{  url: setPinIcon(marker) }}
                            onMouseOver={() => !showJobDetailsOnLoad && handleMouseEvent(index, true, marker)}
                            zIndex={100}
                        >
                            {
                                (infoModal.showTooltip && index === infoModal.infoModalIndx) && (
                                <InfoWindow
                                    options={{
                                        disableAutoPan: true
                                    }}
                                    onCloseClick={() => handleMouseEvent(0, false)}
                                >
                                    <div className={`map-info-window`}>
                                        <p className="text-dark-lighter mb-0">Job:
                                            <b><Link to={`/jobs/${marker.id}/details`}>{ marker.jobName }</Link> </b>
                                        </p>
                                        <p className="text-dark-lighter mb-0">Job ID: <b>{ marker.jobId }</b></p>
                                        {
                                            marker.timeFrom && marker.timeTo &&
                                            <p className="text-dark-lighter mb-0">Times: <b>{ momentHoursFormat(marker.timeFrom) } - { momentHoursFormat(marker.timeTo) }</b></p>
                                        }

                                        { showMarkerDetails && marker.shiftId &&<div className="d-flex flex-column align-items-center mt-2">
                                            <div className={shiftStatus(marker.shiftId)?.guardIconClass || ""}>
                                                <CustomTooltip
                                                    bodyText={shiftStatusTooptipContent}
                                                    keyValue={marker.id}
                                                >
                                                    <span><SVGGuardStatus /></span>
                                                </CustomTooltip>
                                            </div>

                                            <div className="d-flex flex-row">
                                                <div className={`d-flex flex-row mr-2 ${shiftStatus(marker.shiftId)?.checkInClassName}`}>
                                                    <CustomTooltip
                                                        bodyText={`<strong>Check-In Status</strong> <br />
                                                        ${isASPAndAdminRole() ? "Purple: Tentatively<br />" : ""}
                                                        Green: On Time<br />
                                                        ${isASPAndAdminRole() ? "Yellow: Within ASP Buffer<br />" : ""}
                                                        Red: Late`}
                                                        keyValue={marker.id}
                                                    >
                                                        <span> <SVGInDoor /></span>
                                                    </CustomTooltip>
                                                </div>
                                                <div className={`d-flex flex-row ${shiftStatus(marker.shiftId)?.checkOutClassName}`}>
                                                    <CustomTooltip
                                                        bodyText={`<strong>Check-Out Status</strong> <br />
                                                        Green: On Time<br />
                                                        Red: Early`}
                                                        keyValue={marker.id}
                                                    >
                                                        <span><SVGOutDoor /></span>
                                                    </CustomTooltip>
                                                </div>
                                            </div>
                                        </div>
                                        }

                                    </div>
                                </InfoWindow>
                                )
                            }
                        </Marker>
                        {((infoModal.showTooltip && index === infoModal.infoModalIndx) || (showGeofenceOnLoad)) && (
                            <Circle
                                radius={marker.geoFence}
                                center={{
                                    lat: +marker.location.latitude,
                                    lng: +marker.location.longititude
                                }}
                                options={circleOptions}
                            />
                        )}
                    </React.Fragment>
                ))
            }
            {
                guardLocation !== null &&
                <Marker
                    position={{ lat: guardLocation.latitude !== null ? +guardLocation.latitude : 0, lng: guardLocation.longititude !== null ? +guardLocation.longititude : 0}}
                    icon={{  url: Guard }}
                    zIndex={200}
                />
            }
        </GoogleMap>
    ), [mapZoom, defaultCenter, coordinates, jobMarkers, guardLocation,   infoModal.showTooltip, infoModal.infoModalIndx, showMarkerDetails, showGeofenceOnLoad])

    return (
        <>
            {
                removeLoadScript ? renderMap() :
                <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_KEY as string}>
                {
                    renderMap()
                    // React.useMemo(() => (

                    // ), [jobMarkers, infoModal, guardLocation])
                }
            </LoadScript>
            }
            { children ? children : null }
        </>
    )
}
export default JobsMapComponent
