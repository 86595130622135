import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { FC } from 'react'
import { SVGPlus } from '../../assets/icons/SvgIcons'
import { IAvailableGuard } from '../../common/interfaces/guards/IGuard'
import { IVocabulary } from '../../common/interfaces/vocabulary/IVocabulary'
import { IJobShiftsInNeedOfGuards } from '../../common/interfaces/jobs/IJob'
import AvailableGuards from '../../components/guards/AvailableGuards'
import BaseModal from '../../components/modal/BaseModal'
import DataTable from '../../components/tables/DataTable'
import { useShiftPeriod } from '../../customHooks/useShiftPeriod'
import { useTableList } from '../../customHooks/useTableList'
import { momentDateFormat, momentHoursFormat } from '../../utils/DateFormatting'
import { matchOptionWithName } from '../../utils/MatchOptionWithName'
import {SortDataTypesEnum} from "../../customHooks/useSortableData";
import ShiftAssignedSuccessModal from '../../components/modal/jobs/ShiftAssignedSuccessModal'
import { useMatchArrayOfReasons } from '../../customHooks/useMatchArrayOfReasons'
import useAssignGuard from '../../customHooks/useAssignGuard'
import { useRole } from 'customHooks/useRole'

type Props = {
    shiftVocabulary: IVocabulary[]
    reasonVocabulary: IVocabulary[]
    bookLeaveVocabulary: IVocabulary[]
    guardDeactVocabulary: IVocabulary[]
    onSubmit?: (shiftId: number, guard: IAvailableGuard) => void
    reload?: boolean
}

const ShiftsRequireGuard: FC<Props> = ({ shiftVocabulary, reasonVocabulary, bookLeaveVocabulary, guardDeactVocabulary, onSubmit, reload }) => {
    const [showModal, setShowModal] = useState<boolean>(false)
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)
    const [selectedShift, setSelectedShift] = useState<IJobShiftsInNeedOfGuards>({} as IJobShiftsInNeedOfGuards);
    const [selectedGuard, setSelectedGuard] = useState<IAvailableGuard>({} as IAvailableGuard);
    const { tableData, isLoading, setTableData, fetchData } = useTableList<IJobShiftsInNeedOfGuards>('shift/unassigned/today', 0)
    const { format, currentHoursTime } = useShiftPeriod()
    const { matchShiftsReasonIdWithName } = useMatchArrayOfReasons();
    const { onAssignGuardSubmit: saveAssignedGuard } = useAssignGuard()
    const { isClientRole, isSupervisorRole } = useRole()

    useEffect(() => {
        reload && fetchData(0,0)
    }, [reload])

    const onAssignGuard = (shiftId: number) => {
        setShowModal(true)
        const shift = tableData.find(shift => shift.id === shiftId)
        setSelectedShift(shift ? shift : {} as IJobShiftsInNeedOfGuards)
    }

    const onAssignGuardSubmit = async (guard: IAvailableGuard, shiftId: number) => {
        saveAssignedGuard(guard, shiftId, 'assign', () => {
            setTableData(tableData.filter(shift => shift.id !== shiftId))
            setShowSuccessModal(true)
            onSubmit && onSubmit(shiftId, guard)
        })
    }

    return (
        <div className="col-12">
        {
            React.useMemo(() => (
                <DataTable
                    thead={[
                        { label: 'Job Name', sortable: true, dataType: SortDataTypesEnum.string },
                        { label: "Reason" },
                        { label: "Shift Times" },
                        { label: "Shift Period", sortable: true, dataType: SortDataTypesEnum.string },
                        { label: "Assign Guard", invisible: isClientRole() || isSupervisorRole()}]}
                    tbody={
                        tableData
                        .filter(shift =>
                            !moment(momentHoursFormat(shift.timeTo), format).isBefore(moment(currentHoursTime, format)))
                        .map(shift => (
                        {
                            id: shift.id,
                            jobName: shift.jobName,
                            reason: matchShiftsReasonIdWithName(shift.reason, bookLeaveVocabulary, reasonVocabulary, guardDeactVocabulary),
                            shiftTimes: `${momentHoursFormat(shift.timeFrom)} - ${momentHoursFormat(shift.timeTo, shift.timeFrom)}`,
                            shiftPeriod: matchOptionWithName(shift.shiftPeriod, shiftVocabulary),
                        }
                    ))}
                    isLoading={isLoading}
                    ignoreTheadCols={[...(isClientRole() || isSupervisorRole() ? [5] : [])]}
                    ignoreCols={[0]}
                    pagination={false}
                >
                    {
                        (shiftId: number) =>
                            !isClientRole() && !isSupervisorRole() &&
                            <td className="align-middle">
                                <div>
                                    <button
                                        className={`btn btn-aqua-blue`}
                                        onClick={() => onAssignGuard(shiftId)}
                                    >
                                        <SVGPlus />
                                    </button>
                                </div>
                            </td>
                    }
                </DataTable>
            ), [tableData, isLoading, reasonVocabulary, shiftVocabulary])
        }

        {
                showModal && <BaseModal
                    show={showModal}
                    onCancel={() => setShowModal(false)}
                    className="available-guards-modal"
                >
                    <AvailableGuards
                        onSubmit={guard => {
                            setSelectedGuard(guard)
                            onAssignGuardSubmit(guard, selectedShift.id)
                            setShowModal(false)
                        }}
                        title={`Assign Guard for Selected Job Shift for <span class="font-weight-bold">${selectedShift.jobName}</span>`}
                        selectedItem={selectedShift}
                    />
                </BaseModal>
            }

            {
                showSuccessModal && <ShiftAssignedSuccessModal
                    show={showSuccessModal}
                    onClose={() => setShowSuccessModal(false)}
                    selectedGuard={selectedGuard}
                    selectedShift={selectedShift}
                    vocabulary={shiftVocabulary}
                />
            }
    </div>
    )
}

export default ShiftsRequireGuard
