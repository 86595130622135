import { FC, useContext, useEffect, useMemo, useState } from 'react'
import BaseModal from '../BaseModal';
import EditScheduleConfirmationModal from './EditScheduleConfirmationModal';
import ScheduleForm from '../../schedule/ScheduleForm'
import { IBaseModalProps } from '../../../common/interfaces/IModal';
import { IActiveJobSchedules, IJob, IJobSchedule } from '../../../common/interfaces/jobs/IJob';
import { useCalendarForm } from '../../../customHooks/useCalendarForm';
import JobDetailsTabsContext from '../../../context/jobs/JobDetailsTabsContext';
import { IValidationBody, ValidationRules } from '../../../common/interfaces/IValidation';
import { highlightError, removeHighlightError, validateFiled } from '../../../utils/Validation';
import { useHolidays } from '../../../customHooks/useHolidays';
import { IHoliday } from "../../../common/interfaces/IHoliday";
import { IVocabulary } from "../../../common/interfaces/vocabulary/IVocabulary";
import { Job } from '../../../common/models/Job';
import AvailableGuards from '../../guards/AvailableGuards';
import { IAvailableGuard } from '../../../common/interfaces/guards/IGuard';
import { generateFullName } from '../../../utils/GenerateFullName';
import { useSchoolYear } from 'customHooks/useSchoolYear';

interface IProps extends IBaseModalProps {
    schedule?: IJobSchedule | IActiveJobSchedules | undefined
    activeJob?: IJob;
    getallHolidays?: IHoliday[];
    vocabulary?: IVocabulary[]
    descriptionNote?: string
    onSubmit: (data: IJobSchedule | IActiveJobSchedules, actionType?: 'edit' | 'add') => void
}

type TModalsState = { showMainModal: boolean, showConfirm: boolean, availableGuards: boolean }

const AddEditScheduleModal: FC<IProps> = ({ onCancel, onSubmit, schedule, activeJob, getallHolidays, vocabulary, descriptionNote }) => {
    const { job, allHolidays } = useContext(JobDetailsTabsContext)

    const { prepopulateHolidays } = useHolidays();
    const copiedJob = schedule ? (job ? JSON.parse(JSON.stringify(job)) : JSON.parse(JSON.stringify(activeJob))) : new Job()
    const { onCalendarChange, onRemoveItemFromCalendar, calendarFormData, onHolidayChange, trackHolidays, setCalendarFormData,
        setHolidays, holidays } = useCalendarForm<IJob>(copiedJob, "jobSchedules")
    // const [scheduleToEdit, setScheduletoEdit] = useState<IJobSchedule>({} as IJobSchedule)
    const defaultModalsState: TModalsState = { showMainModal: true, showConfirm: false, availableGuards: false };
    const [modals, setModals] = useState<TModalsState>(defaultModalsState)
    const [assignedGuard, setAssignedGuard] = useState<IAvailableGuard | undefined>(undefined)
    const {getYearById, getSchoolYears, schoolYearsData} = useSchoolYear()
    // useEffect(() => {
    //     console.log(calendarFormData.jobSchedules
    //         .find(item => item.id === (schedule ? schedule.id as number : 1) || item.uid === 1) || {} as IJobSchedule)
    //     setScheduletoEdit(calendarFormData.jobSchedules
    //         .find(item => item.id === (schedule ? schedule.id as number : 1) || item.uid === 1) || {} as IJobSchedule)
    // }, [calendarFormData])
    const scheduleToEdit = useMemo(() => calendarFormData.jobSchedules
        .find(item => item.id === (schedule ? schedule.id as number : 1) || item.uid === 1) || {} as IJobSchedule, [calendarFormData.jobSchedules, schedule])

    
    useEffect(() => {
        getSchoolYears(undefined, undefined)
    }, [])

    const formIsValid = () => {
        let errors: IValidationBody[] = [];
        errors = [...errors, ...validateFiled(scheduleToEdit.repeatOn as string, `repeatOn-${schedule ? (scheduleToEdit as IJobSchedule).id
            : scheduleToEdit.uid}`, ValidationRules.required)]
        if (errors.length !== 0) {
            highlightError(errors)
            return false
        } else {
            removeHighlightError()
            return true
        }
    }

    const onSaveChangesClick = (actionType: "edit" | "add") => {
        formIsValid() && setModals({
            ...defaultModalsState,
            showConfirm: actionType === "edit" ? true : false,
            availableGuards: actionType === "add" ? true : false,
            showMainModal: false
        })
    }

    const onConfrimClick = () => {
        const schedule = {
            ...scheduleToEdit,
            holidays: scheduleToEdit.holidays.filter(holiday => holiday.remove)
        }
        assignedGuard && (schedule.guardId = assignedGuard.id);
        assignedGuard && (schedule.assignedGuard = generateFullName(assignedGuard));
        assignedGuard && (schedule.jobId = job.id);
        onSubmit && onSubmit(schedule, assignedGuard ? "add" : "edit")
    }

    const onAssignGuardSubmit = (guard: IAvailableGuard) => {
        setAssignedGuard(guard)
        setModals({
            ...modals,
            availableGuards: false,
            showConfirm: true
        })
    }

    useEffect(() => {
        if (allHolidays && allHolidays.length !== 0) {
            const data = prepopulateHolidays(calendarFormData,
                allHolidays.map(holiday => {
                    holiday.remove = true;
                    return holiday
                }), 'jobSchedules',
                (schedule, scheduleId) => trackHolidays(schedule, '', scheduleId)) as IJob;
            setCalendarFormData(data)
        }

        if (getallHolidays && getallHolidays.length !== 0) {
            const data = prepopulateHolidays(calendarFormData,
                getallHolidays.map(holiday => {
                    holiday.remove = true;
                    return holiday
                }), 'jobSchedules',
                (schedule, scheduleId) => trackHolidays(schedule, '', scheduleId)) as IJob;
            setCalendarFormData(data)
        }
    }, [allHolidays, holidays, getallHolidays])

    useEffect(() => {
        if (allHolidays && allHolidays.length !== 0) {
            setHolidays({
                original: allHolidays.map(holiday => {
                    holiday.remove = true;
                    return holiday
                }), filtered: allHolidays.map(holiday => {
                    holiday.remove = true;
                    return holiday
                })
            })
        }
        if (getallHolidays && getallHolidays.length !== 0) {
            setHolidays({
                original: getallHolidays.map(holiday => {
                    holiday.remove = true;
                    return holiday
                }), filtered: getallHolidays.map(holiday => {
                    holiday.remove = true;
                    return holiday
                })
            })
        }
    }, [allHolidays, getallHolidays])

    return (
        <>
            {modals.showMainModal &&
                <BaseModal
                    show={modals.showMainModal}
                    onCancel={() => onCancel()}
                    onSubmit={() => onSaveChangesClick(schedule ? "edit" : "add")}
                    submitBtnText={"Save"}
                    title={`${schedule ? 'Edit' : 'Add'} Job Schedule for <span class="font-weight-bold">${job?.jobName || schedule?.jobName}</span>`}
                    className={'edit-schedule-modal'}
                >
                    {descriptionNote &&
                        <small className="text-danger">
                            <strong>NOTE: </strong>
                            {descriptionNote}
                        </small>
                    }
                    <form>
                        {Object.keys(scheduleToEdit).length !== 0 &&
                            <ScheduleForm
                                onChange={(event, fieldName) => {
                                    onCalendarChange(calendarFormData, event, fieldName, schedule ? schedule.id : scheduleToEdit.uid)
                                }}
                                data={scheduleToEdit}
                                showShiftPeriod={true}
                                hideEndDate={true}
                                onRemoveItemFromCalendar={scheduleId => onRemoveItemFromCalendar(scheduleId, 'jobSchedules', calendarFormData)}
                                trackHolidays={(holidayId: number, yesNoValue: boolean) =>
                                    onHolidayChange(holidayId, yesNoValue, schedule ? schedule.id as number : scheduleToEdit.uid as number, 'jobSchedules', calendarFormData)
                                }
                                holidaysTitle={`There is/are holiday(s) that fall within this job schedule. Do you want to <span class="font-weight-bold">remove holiday(s)</span> from job schedule?`}
                                schoolYear={job?.schoolYearDate ? job?.schoolYearDate : (job?.schoolYear ? job?.schoolYear.split('-').pop().trim() : undefined)}
                                tooltipDates={"Select dates during which the scheduled times should be part of this job."}
                                tooltipHours={"Select the actual shift times that should be repeated for this schedule. Start time should not include the ASP buffer time before the shift."}
                                tooltipRepeatOn={"Select the days of the week during which this schedule should be repeated each week within selected dates."}
                                schoolYearData={getYearById(schoolYearsData, job?.schoolYearId || 0) || undefined}
                            />
                        }
                    </form>

                </BaseModal>
            }
            {
                modals.showConfirm && <EditScheduleConfirmationModal
                    onCancel={() => setModals(defaultModalsState)}
                    onSubmit={() => onConfrimClick()}
                    originalSchedule={schedule ? schedule : scheduleToEdit}
                    editedSchedule={scheduleToEdit}
                    activeJob={activeJob}
                    vocabularyShift={vocabulary}
                    assignedGuard={assignedGuard}
                />
            }
            {
                modals.availableGuards && <BaseModal
                    show={modals.availableGuards}
                    onCancel={() => setModals(defaultModalsState)}
                    className="available-guards-modal"
                >
                    <AvailableGuards
                        title={`Assign a Guard for the New Job Schedule`}
                        onSubmit={onAssignGuardSubmit}
                        selectedItem={scheduleToEdit}
                        selectedJob={copiedJob}
                        scheduleAssign
                    />
                </BaseModal>
            }
        </>
    )
}

export default AddEditScheduleModal
