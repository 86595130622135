import React, { FC, useContext, useState } from 'react';
import moment from 'moment';

import BaseModal from '../BaseModal';
import { IBaseModalProps } from '../../../common/interfaces/IModal';
import { IValidationBody, ValidationRules } from '../../../common/interfaces/IValidation';
import { ITenantBody } from '../../../common/interfaces/tenant/ITenant';
import {
	highlightError,
	removeHighlightError,
	validateFiled,
	validatePhoneNumber,
} from 'utils/Validation';
import InputMask from 'react-input-mask';
import { NotificationTypes } from 'common/interfaces/INotification';
import AppContext from 'context/AppContext';
import DatePicker from 'react-datepicker';
import { filterDateFormat } from 'utils/DateFormatting';
import { useDatapickerSvg } from 'customHooks/useDatapickerSvg';
import { SVGCalendar } from 'assets/icons/SvgIcons';

interface IProps extends IBaseModalProps {
	show: boolean;
}
const fieldsDefaultValues = {
	identifier: '',
	name: '',
	connectionString: '',
	email: '',
	firstName: '',
	middleName: '',
	lastName: '',
	phoneNumber: '',
	mobileNumber: '',
	position: '',
	validUpto: filterDateFormat(new Date() as Date),
};
const AddTenantModal: FC<IProps> = ({ onSubmit, onCancel, show }) => {
	const { showNotification } = useContext(AppContext);
	const [formErrors, setFormErrors] = useState<IValidationBody[]>([] as IValidationBody[]);
	const { showDatapicker, changeDatapicker } = useDatapickerSvg();
	const [fields, setFields] = useState<ITenantBody>(fieldsDefaultValues);

	const onInputChange = React.useMemo(
		() => (key: string, value: moment.Moment | string) => {
			let errors: IValidationBody[] = [];
			if (key === 'connectionString' && (value as string).length > 500) {
				errors.push({
					errorMessage: 'The connection string must contain less then 500 characters.',
					fieldName: 'connectionString',
				});
				setFormErrors(errors);
				return;
			}
			setFormErrors([]);
			setFields({
				...fields,
				[key]: value,
			});
		},
		[fields]
	);

	const validateClientFields = (callback: () => void) => {
		let errors: IValidationBody[] = [];

		for (let [field, value] of Object.entries(fields)) {
			const skipFields = ['middleName', 'phoneNumber'];
			if (!skipFields.includes(field)) {
				errors = [
					...errors,
					...validateFiled(value as string, field, ValidationRules.required),
				];
			}
		}

		errors = [
			...errors,
			...validateFiled(
				fields.phoneNumber as string,
				'mobileNumber',
				ValidationRules.isValidPhone
			),
		];
		errors = [
			...errors,
			...validateFiled(fields.email as string, 'email', ValidationRules.isValidEmail),
		];

		if (errors.length !== 0) {
			showNotification(NotificationTypes.warning, 'Please fill in all fields correctly');
			highlightError(errors);
		} else {
			removeHighlightError();
			callback();
		}
	};

	const handleSubmit = async (data?: ITenantBody): Promise<void> => {
		const filteredFields = Object.fromEntries(
			Object.entries(fields).filter(([_key, value]) => value)
		) as ITenantBody;
		onSubmit &&
			(await onSubmit({
				data: filteredFields,
				callback: () => setFields(fieldsDefaultValues),
			}));
	};

	return (
		<>
			{
				<BaseModal
					show={show}
					title={'Add client'}
					onSubmit={() => validateClientFields(handleSubmit)}
					onCancel={onCancel}
					submitBtnText={'Add'}
					className='edit-user-info'>
					<form className='row'>
						<div className='form-group col-md-6 align-self-end column'>
							<label className='required' htmlFor='identifier'>
								Identifier
							</label>
							<input
								value={fields.identifier}
								onChange={(e) => onInputChange('identifier', e.target.value)}
								name='identifier'
								type='text'
								id='identifier'
								className='form-control'
								placeholder='Enter Identifier'
							/>
						</div>
						<div className='form-group col-md-6'>
							<label htmlFor='name' className='required'>
								Client name
							</label>
							<input
								type='text'
								name='name'
								className='form-control'
								id='name'
								placeholder='Enter client Name'
								autoComplete='nope'
								value={fields.name}
								onChange={(e) => onInputChange('name', e.target.value)}
							/>
						</div>

						<div className='form-group col-md-6 align-self-end'>
							<label htmlFor='firstName' className='required'>
								First name
							</label>
							<input
								type='text'
								name='firstName'
								className='form-control'
								id='firstName'
								placeholder='Enter first Name'
								autoComplete='nope'
								value={fields.firstName}
								onChange={(e) => onInputChange('firstName', e.target.value)}
							/>
						</div>
						<div className='form-group col-md-6'>
							<label htmlFor='middleName'>Middle name</label>
							<input
								type='text'
								name='middleName'
								className='form-control'
								id='middleName'
								placeholder='Enter middle Name'
								autoComplete='nope'
								value={fields.middleName}
								onChange={(e) => onInputChange('middleName', e.target.value)}
							/>
						</div>
						<div className='form-group col-md-6'>
							<label htmlFor='lastName' className='required'>
								Last name
							</label>
							<input
								type='text'
								name='lastName'
								className='form-control'
								id='lastName'
								placeholder='Enter last Name'
								autoComplete='nope'
								value={fields.lastName}
								onChange={(e) => onInputChange('lastName', e.target.value)}
							/>
						</div>

						<div className='form-group col-md-6'>
							<label htmlFor='email' className='required'>
								Email
							</label>
							<input
								type='text'
								name='email'
								className='form-control'
								id='email'
								placeholder='Enter email address'
								autoComplete='nope'
								value={fields.email}
								onChange={(e) => onInputChange('email', e.target.value)}
							/>
						</div>

						<div className='form-group col-md-6'>
							<label htmlFor='mobileNumber' className='required'>
								Mobile number
							</label>
							<InputMask
								mask={'999-999-9999'}
								type='text'
								name='mobileNumber'
								className='form-control'
								id='mobileNumber'
								placeholder='Enter mobile number'
								autoComplete='nope'
								value={fields.mobileNumber}
								onChange={(e: { target: { value: string | moment.Moment } }) =>
									onInputChange('mobileNumber', e.target.value)
								}
								onBlur={(e: any) =>
									validatePhoneNumber(e)
								}
							/>
						</div>

						<div className='form-group col-md-6'>
							<label htmlFor='phoneNumber'>Phone number</label>
							<InputMask
								mask={'999-999-9999'}
								type='text'
								name='phoneNumber'
								className='form-control'
								id='phoneNumber'
								placeholder='Enter Phone number'
								autoComplete='nope'
								value={fields.phoneNumber}
								onChange={(e: { target: { value: string | moment.Moment } }) =>
									onInputChange('phoneNumber', e.target.value)
								}
								onBlur={(e: any) =>
									validatePhoneNumber(e)
								}
							/>
						</div>

						<div className='form-group col-md-6'>
							<label htmlFor='position' className='required'>
								Position
							</label>
							<input
								value={fields.position}
								onChange={(e) => onInputChange('position', e.target.value)}
								name='position'
								type='text'
								id='position'
								className='form-control'
								placeholder='Enter position'
								autoComplete='nope'
							/>
						</div>
						<div className='form-group col-md-6'>
							<label htmlFor='validUpto' className='required'>
								Valid Up to
							</label>
							<div className='react-datepicker-custom-wrapper react-datepicker-custom-wrapper--full-width '>
								<DatePicker
									dateFormat='dd/MM/yyyy'
									minDate={new Date()}
									selected={new Date(fields.validUpto)}
									onChange={(date) => {
										onInputChange('validUpto', filterDateFormat(date as Date));
										changeDatapicker(1);
									}}
									className='form-control'
									onInputClick={() => changeDatapicker(1)}
									open={showDatapicker[1]}
									onClickOutside={() => changeDatapicker(1)}
								/>
								<div onClick={() => changeDatapicker(1)}>
									<SVGCalendar />
								</div>
							</div>
						</div>
						<div className='form-group col-md-12'>
							<label htmlFor='connectionString' className='required'>
								Connection string
							</label>
							<textarea
								name='connectionString'
								className='form-control'
								id='connectionString'
								placeholder='Enter connection string'
								autoComplete='nope'
								rows={5}
								value={fields.connectionString}
								onChange={(e) => onInputChange('connectionString', e.target.value)}
							/>
							{formErrors[0] && (
								<small className='text-danger pt-3'>
									{formErrors[0].errorMessage}
								</small>
							)}
						</div>
					</form>
				</BaseModal>
			}
		</>
	);
};

export default AddTenantModal;
