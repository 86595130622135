import React, { FC, useContext, useEffect, useState } from 'react';
import { SVGApprove, SVGCancel } from 'assets/icons/SvgIcons';
import DataTable from 'components/tables/DataTable';
import { tenantsContent } from 'content/dashboard/TablesContent';
import { useVerifyRolesTHead } from 'customHooks/useVerifyRolesTHead';
import Layout from '../../components/layout/Layout';
import SectionTitle from '../../components/titles/SectionTitle';
import { momentDateFormat } from 'utils/DateFormatting';
import AddTenantModal from '../../components/modal/administration/AddTenantModal';
import { useTenant } from '../../customHooks/useTenant';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import BaseModal from 'components/modal/BaseModal';
import { ITenant } from 'common/interfaces/tenant/ITenant';
import AppContext from 'context/AppContext';

const Tenants: FC = () => {
	const { theadTable, addVerifyRoles } = useVerifyRolesTHead();
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [selectedTenant, setSelectedTenant] = useState<ITenant>();
	const {
		showAddTenantModal,
		setShowAddTenantModal,
		addTenant,
		activateDeactivateRequest,
		currentPage,
		perPage,
		allRecords,
		onPagination,
		onPerPage,
		tableData,
		isLoading,
		fetchData,
		onSortCall,
	} = useTenant();

	useEffect(() => {
		addVerifyRoles(tenantsContent.thead);
	}, [tenantsContent]);

	return (
		<Layout
			breadcrumbs={{
				currentPageTitle: 'Clients',
			}}>
			<div className='row mb-4 justify-content-end'>
				<div className='col-12 mb-4 d-flex flex-row align-items-center justify-content-between'>
					<SectionTitle title='Clients' />
				</div>
				<div className='col-12'>
					<div className='row justify-content-end'>
						<button
							type='button'
							className={`btn btn-aqua-blue mb-3 ml-2`}
							onClick={() => setShowAddTenantModal(true)}>
							Add Client
						</button>
					</div>
				</div>

				<div className='col-12'>
					<DataTable
						tableClass='mt-4 table-info--notFixed table-info--optimal'
						thead={theadTable}
						tbody={
							tableData.length
								? tableData.map((tenant) => ({
										id: tenant.id,
										name: tenant.name,
										validUpto: `${momentDateFormat(tenant.validUpto)}`,
										connectionString: tenant.connectionString,
								  }))
								: []
						}
						isLoading={isLoading}
						ignoreCols={[0]}
						pagination
						currentPage={currentPage}
						itemsCount={allRecords}
						itemsPerPage={+perPage}
						onPager={onPagination}
						onPerPage={onPerPage}
						tableName={'Clients'}
						onSort={(orderBy, desc) => onSortCall(orderBy, desc)}
						showTableLengthData
						sortOnBackend
						defaultSortedColumn={1}>
						{(id) => (
							<td>
								<div>
									<button
										className={`btn btn-aqua-blue`}
										onClick={() => {
											setSelectedTenant(tableData.find((v) => v.id === id));
											setShowConfirmModal(true);
										}}>
										{tableData.find((v) => v.id === id)?.isActive ? (
											<SVGCancel />
										) : (
											<SVGApprove />
										)}
									</button>
								</div>
							</td>
						)}
					</DataTable>
				</div>
			</div>
			<AddTenantModal
				show={showAddTenantModal}
				onCancel={() => setShowAddTenantModal(false)}
				onSubmit={({ data, callback }) =>
					addTenant(data, async () => {
						callback();
						window.location.reload();
						return await fetchData(0, 0);
					})
				}
			/>
			<BaseModal
				show={showConfirmModal}
				onCancel={() => setShowConfirmModal(false)}
				submitBtnText="Confirm"
				title={`Are you sure that you want to ${
					selectedTenant?.isActive ? 'deactivate' : 'activate'
				} ${selectedTenant?.name} client`}
				onSubmit={() =>
					selectedTenant &&
					activateDeactivateRequest(
						{
							id: selectedTenant.id || '',
							type: tableData.find((v) => v.id === selectedTenant.id)?.isActive
								? 'deactivate'
								: 'activate',
						},
						async () => {
							setShowConfirmModal(false)
							window.location.reload();
							return await fetchData(0, 0);
						}
					)
				}
			/>
		</Layout>
	);
};

export default Tenants;
