import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { IActiveGuard } from '../../common/interfaces/guards/IActiveGuards'
import Layout from '../../components/layout/Layout'
import DataTable from '../../components/tables/DataTable'
import SectionTitle from '../../components/titles/SectionTitle'
import { activeGuardsListContent } from '../../content/dashboard/TablesContent'
import { useTableList } from '../../customHooks/useTableList'
import AppContext from '../../context/AppContext'
import { AuthService } from '../../common/auth/AuthService'
import { Roles } from '../../common/enums/Roles'
import { SVGEraser } from '../../assets/icons/SvgIcons'
import { useListFilter } from '../../customHooks/useListFilter'
import { momentDateFormat, momentHoursFormat } from '../../utils/DateFormatting'
import AutocompleteField from '../../components/autosuggestField/AutocompleteField'
import CustomSelectControl from '../../components/custom-select/CustomSelectControl'
import ReportActions from 'components/reports/ReportActions'
import { useSchoolYear } from 'customHooks/useSchoolYear'

const ActiveGuardsList = () => {
    const appContext = useContext(AppContext)
    const [searchIsActive, setSearchIsActive] = useState<boolean>(false)

    const { currentPage, perPage, allRecords, onPagination, onPerPage,  tableData, isLoading, fetchData, onSortCall, onClearFilterQuery } = useTableList<IActiveGuard>('guard')
    const { generateFilterQuery, filter, onFilterSearchClearClick, onFilterDropdownChange, onFilterClearClick } = useListFilter("guard")
    const {  getSchoolYears: setDefaultSchoolYears, schoolYears} = useSchoolYear()
    useEffect(() => {
        setDefaultSchoolYears();
    }, [])
    return (
        <Layout
            breadcrumbs={{ currentPageTitle: 'Guards' }}
        >
            <section>
                <div className="row">
                    <div className="col-12 pb-4 d-flex flex-column flex-sm-row justify-content-between align-items-sm-center">
                        <SectionTitle title="Active Guards"/>
                        <div className='d-flex justify-content-end mt-2 mt-sm-0'>
                            {
                                AuthService.checkIfUserHasPermission([Roles.Admin, Roles.ASP, Roles.SuperAdmin], appContext.currentUser.role) &&
                                <Link to="/guards/add" className="btn btn-aqua-blue ml-auto">Add a Guard</Link>
                            }
                            <ReportActions
                                className="ml-2"
                                page="activeguards"
                                hidePrint
                                hidePdf
                                showForShiftReport={false}
                            />
                        </div>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-12">
                        <div className="d-flex flex-row justify-content-start justify-content-md-end w-100-md-0">
                            <div className="form-group ml-0 w-100-md-0">
                                <AutocompleteField
                                    includes={'Guard'}
                                    onlyActive
                                    isAsync={true}
                                    placeholder='Enter guard name'
                                    onSearch={ async (value, id) => {
                                        setSearchIsActive(true);
                                        await fetchData(1, 0, generateFilterQuery(false, false, value), false, true)
                                    }}
                                    onClear={() => {
                                        setSearchIsActive(false);
                                        onFilterSearchClearClick(() => fetchData(1, 24, generateFilterQuery(true)))
                                        onClearFilterQuery()
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            <div className="col-md-12 p-0">
                <form className='filters-form-group mb-4 ' onKeyDown={(event) => {
                    if (event.key === 'Enter') fetchData(1, 0, generateFilterQuery(true, false))
                }}>
                    <div className='row'>
                        <div className='col-12'>
                            <h5>​Filter Guards List: </h5>
                        </div>
                        <div className='d-flex  w-100 flex-wrap ml-1 mr-1 align-items-center'>
                            <CustomSelectControl
                                isClearable={true}
                                options={[
                                    {id: 0, name: '0'},
                                    {id: 1, name: '1-5'},
                                    {id: 2, name: '6-10'},
                                    {id: 3, name: '11-16'},
                                    {id: 4, name: '16+'},
                                ]}
                                value={filter.lateAbsenceRange}
                                placeholder={'Select late/absent range'}
                                onChange={(e) => {
                                    onFilterDropdownChange(e, 'lateAbsenceRange');
                                    if (!e) {
                                        onClearFilterQuery();
                                        onFilterClearClick();
                                    }
                                }}
                                className='flex-grow-0 ml-2 custom-react-multi-select'
                                isMulti={true}
                            />

                            <CustomSelectControl
                                options={ schoolYears || []}
                                value={filter.schoolYearId}
                                placeholder={"Select School Year"}
                                onChange={e => {
                                    onFilterDropdownChange(+e, 'schoolYearId') 
                                }}
                                className="flex-grow-0 ml-2 custom-react-select--schoolYear custom-react-select-lg"
                            />  


                            <button
                                type='button'
                                className='btn btn-aqua-blue ml-2 mt-2 mt-lg-0'
                                onClick={() => {
                                    filter.hasValue ? fetchData(1, 24, generateFilterQuery()) : fetchData(1, 24)
                                }}>
                                Filter Guards
                            </button>
                            {filter.hasValue && (
                                <button
                                    type='button'
                                    className='btn btn-aqua-blue ml-2 mt-2 mt-lg-0'
                                    onClick={() => {
                                        setSearchIsActive(false);
                                        onClearFilterQuery();
                                        onFilterClearClick(() => fetchData(1, 0))
                                    }}>
                                    <SVGEraser/>
                                </button>
                            )}
                        </div>
                    </div>
                </form>

            </div>
                {
                    React.useMemo(() => (
                        <div className="row">
                            {
                                tableData &&
                                <div className="col-12">
                                    <DataTable
                                        thead={activeGuardsListContent.thead}
                                        tbody={tableData.map(item => (
                                            {
                                                id: item.id,
                                                employeeId: item.employeeId ? `<a class="text-uppercase" href="#/guards/${item.id}/details">${item.employeeId}</a>` : '--',
                                                fullNameLink: `<a href="#/guards/${item.id}/details">${item.firstName} ${item.middleName} ${item.lastName}</a>`,
                                                personalMobile: item.aspMobile ?
                                                    `<svg class="mr-2" width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="7" y="7" width="10" height="8">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6035 7C12.6035 7 11.6992 8.16972 11.4537 8.57358C11.2014 8.99061 11.099 9.21364 10.8293 8.91652C10.7134 8.78802 10.1379 8.4019 10.1379 8.4019C10.1379 8.4019 10.3381 10.4204 10.3297 10.7281C10.3211 11.0293 10.1769 11.164 9.99582 10.8358C9.86872 10.6074 9.14845 9.91518 9.14845 9.91518C9.14845 9.91518 9.00438 10.1204 8.80216 10.3805C8.65717 10.5662 8.7075 10.6222 8.4789 10.5455C8.23477 10.4645 7.32866 10.2577 7.32866 10.2577C7.32866 10.2577 7.59337 11.2682 7.66172 11.5602C7.67435 11.615 7.68435 11.6625 7.69093 11.704C7.71942 11.9024 7.6885 11.8531 7.38523 12.0459C7.10486 12.2234 7 12.3092 7 12.3092C7 12.3092 8.7246 13.4438 8.98254 13.672C9.24055 13.9008 9.16292 13.9815 9.02898 14.2812C8.88696 14.5988 8.71815 15 8.71815 15H14.3246C14.3246 15 14.2642 14.5988 14.2083 14.2812C14.1556 13.9815 14.0994 13.9008 14.4202 13.672C14.7397 13.4438 16.7712 12.3092 16.7712 12.3092C16.7712 12.3092 16.6897 12.2234 16.4583 12.0459C16.2067 11.8531 16.1622 11.9024 16.2456 11.704C16.263 11.6625 16.2849 11.615 16.3132 11.5602C16.4611 11.2682 17 10.2577 17 10.2577C17 10.2577 16.0369 10.4645 15.7717 10.5455C15.522 10.6222 15.5871 10.5662 15.4924 10.3805C15.3611 10.1204 15.2722 9.91518 15.2722 9.91518C15.2722 9.91518 14.3654 10.6074 14.1754 10.8358C13.9058 11.164 13.7973 11.0293 13.8706 10.7281C13.9467 10.4204 14.6939 8.4019 14.6939 8.4019C14.6939 8.4019 14.0133 8.78802 13.8622 8.91652C13.5121 9.21364 13.4704 8.99061 13.3314 8.57358C13.1953 8.16972 12.609 7 12.609 7H12.6035Z" fill="white"/>
                                                        </mask>
                                                        <g mask="url(#mask0)">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6035 7C12.6035 7 11.6992 8.16972 11.4537 8.57358C11.2014 8.99061 11.099 9.21364 10.8293 8.91652C10.7134 8.78802 10.1379 8.4019 10.1379 8.4019C10.1379 8.4019 10.3381 10.4204 10.3297 10.7281C10.3211 11.0293 10.1769 11.164 9.99582 10.8358C9.86872 10.6074 9.14845 9.91518 9.14845 9.91518C9.14845 9.91518 9.00438 10.1204 8.80216 10.3805C8.65717 10.5662 8.7075 10.6222 8.4789 10.5455C8.23477 10.4645 7.32866 10.2577 7.32866 10.2577C7.32866 10.2577 7.59337 11.2682 7.66172 11.5602C7.67435 11.615 7.68435 11.6625 7.69093 11.704C7.71942 11.9024 7.6885 11.8531 7.38523 12.0459C7.10486 12.2234 7 12.3092 7 12.3092C7 12.3092 8.7246 13.4438 8.98254 13.672C9.24055 13.9008 9.16292 13.9815 9.02898 14.2812C8.88696 14.5988 8.71815 15 8.71815 15H14.3246C14.3246 15 14.2642 14.5988 14.2083 14.2812C14.1556 13.9815 14.0994 13.9008 14.4202 13.672C14.7397 13.4438 16.7712 12.3092 16.7712 12.3092C16.7712 12.3092 16.6897 12.2234 16.4583 12.0459C16.2067 11.8531 16.1622 11.9024 16.2456 11.704C16.263 11.6625 16.2849 11.615 16.3132 11.5602C16.4611 11.2682 17 10.2577 17 10.2577C17 10.2577 16.0369 10.4645 15.7717 10.5455C15.522 10.6222 15.5871 10.5662 15.4924 10.3805C15.3611 10.1204 15.2722 9.91518 15.2722 9.91518C15.2722 9.91518 14.3654 10.6074 14.1754 10.8358C13.9058 11.164 13.7973 11.0293 13.8706 10.7281C13.9467 10.4204 14.6939 8.4019 14.6939 8.4019C14.6939 8.4019 14.0133 8.78802 13.8622 8.91652C13.5121 9.21364 13.4704 8.99061 13.3314 8.57358C13.1953 8.16972 12.609 7 12.609 7H12.6035Z" fill="url(#paint0_linear)"/>
                                                        </g>
                                                        <rect x="5.5" y="0.5" width="13" height="23" rx="0.5" stroke="#283044"/>
                                                        <rect x="9" y="21" width="6" height="1" rx="0.5" fill="#283044"/>
                                                        <defs>
                                                        <linearGradient id="paint0_linear" x1="9.36814" y1="14.0562" x2="17.0086" y2="14.0562" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#D82332" stop-opacity="0.01"/>
                                                        <stop offset="1" stop-color="#D82332"/>
                                                        </linearGradient>
                                                        </defs>
                                                    </svg>${item.aspMobile}`
                                                    : (item.personalMobile || '-'),
                                                aspStartDate: item.aspStartDate,
                                                assignedSchedules: item.assignedSchedules || 0,
                                                latesOrAbsences: item.latesOrAbsences || 0,
                                                lastCheckIn: item.lastCheckIn || ''
                                            }
                                        ))}
                                        isLoading={isLoading}
                                        ignoreCols={[0,6,7]}
                                        // onColClick={(key, trIndx) => handleColClick(key, trIndx, 'fullNameLink', 'guards')}
                                        pagination={true}
                                        currentPage={currentPage}
                                        itemsCount={allRecords}
                                        itemsPerPage={+perPage}
                                        onPager={page => onPagination(page)}
                                        onPerPage={value => onPerPage(value)}
                                        tableName={"Active Guards"}
                                        tableClass={'table-info--notFixed'}
                                        onSort={(orderBy, desc) => onSortCall(orderBy, desc, filter.keyWord)}
                                        showTableLengthData={true}
                                        sortOnBackend={true}
                                        searchIsActive={searchIsActive}
                                        defaultSortedColumn={1}
                                    >
                                        {
                                            (_id, rowItem: IActiveGuard) => (
                                                <>
                                                    <td className="align-middle">
                                                        <div>
                                                            <span className={`d-flex align-items-center justify-content-center table-info__counter table-info__counter${rowItem.latesOrAbsences === 0 ? "--best" : "--good"}`}>
                                                                { rowItem.latesOrAbsences || 0 }
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle">
                                                        <div>
                                                            { rowItem.lastCheckIn ? `${momentDateFormat(rowItem.lastCheckIn)} - ${momentHoursFormat(rowItem.lastCheckIn)}` : '' }
                                                        </div>
                                                    </td>
                                                </>
                                            )
                                        }
                                    </DataTable>
                                </div>
                            }
                        </div>
                    ), [tableData, isLoading])
                }
            </section>
        </Layout>
    )
}

export default ActiveGuardsList
