import React, {useContext, useEffect, useState} from 'react'
import { useHistory } from 'react-router-dom';
import { NotificationTypes } from '../../common/interfaces/INotification';
import { IValidationBody, ValidationRules } from '../../common/interfaces/IValidation';
import { DataService } from '../../common/services/DataService';
import BaseStartScreen from '../../components/base-start-screen/BaseStartScreen';
import AppContext from '../../context/AppContext';
import { useOnIputChange } from '../../customHooks/useOnInputChange';
import { highlightError, removeHighlightError, validateFiled } from '../../utils/Validation';

const ForgotPassword = () => {
    const {onChange , hookState} = useOnIputChange({ email: ''})
    const formData = hookState;
    const history = useHistory();
    const svc = new DataService({ url: 'account/forgot-password', omitToken: true })
    const { showLoader, showNotification, currentUser } = useContext(AppContext)
    const [emailError, setEmailError] = useState<boolean>(false)

    useEffect(() => {
        Object.keys(currentUser).length !== 0 && history.push('/dashboard')
    }, [])

    const validForm = () => {
        let errors: IValidationBody[] = [];

        errors = validateFiled(formData.email as string, 'Email', ValidationRules.required)
        let emailValid = formData.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

        if (errors.length === 0 && emailValid) {
            removeHighlightError()
            setEmailError(false)
            return true
        } else {
            highlightError([{ fieldName: 'email', errorMessage: '' }])
            setEmailError(true)
            return false
        }
    }

    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        showLoader(true)
        e.preventDefault()
        if (validForm()) {
            try {
                await svc.create(formData)
                history.push('/signin')
                showLoader(false)
                showNotification(NotificationTypes.success, 'Further instructions have been sent to your e-mail address')
            } catch (err: any) {
                showNotification(NotificationTypes.danger, err.message)
                showLoader(false)
            }
        } else {
            showLoader(false)
        }
    }

    return (
        <BaseStartScreen
            title={'Forgot Password'}
            agreement={''}
            linkTitle={'Sign In'}
            formValid={false}
            link={'/signin'}
            bottomText={'Already have an account?'}
        >
            <div className="form-group form-group--text text-center">
                <small className="d-block w-75 mx-auto mb-2 mb-lg-4">
                    Place your email in the field below.
                    We will send you a link with the
                    instructions to follow.
                </small>
            </div>
            <div className={`form-group mb-3`}>
                <label htmlFor="email">Email</label>
                <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={formData.email}
                    placeholder=""
                    onChange={e => onChange(e)}
                />
            </div>
            {
                emailError && formData.email &&
                <div className="form-group ml-auto">
                    <small className="text-danger pb-4">Email is not a valid email address</small>
                </div>
            }
            <div className="form-group mb-3">
                <button
                    type="submit"
                    className="btn btn-aqua-blue w-100 d-block"
                    onClick={e => handleSubmit(e)}
                >
                    Reset Passowrd</button>
            </div>
        </BaseStartScreen>
    )
}

export default ForgotPassword
