import { useState } from "react"
import { ICityDropdown } from "../common/interfaces/dropdowns/ICityDropdown"
import { ISchoolBoardsDropdown } from "../common/interfaces/dropdowns/ISchoolDropdown"
import { ISchoolYearDropdown } from "../common/interfaces/dropdowns/ISchoolYearDropdown"
import { IJobsFilter } from "../common/interfaces/IDataFilter"
import { IVocabulary } from "../common/interfaces/vocabulary/IVocabulary"
import { JobFilter } from "../common/models/JobFilter"
import ResourceDataService from "../common/services/ResourceDataService"
import { IZoneDropdown } from "common/interfaces/dropdowns/IZoneDropdown"

export const useListFilter = (baseUrl: string, clientId = 0, schoolYearId = 0, urlHasParams = false,
    defaultFilterValue: any = {}) => {
    const [filter, setFilter] = useState<IJobsFilter>(defaultFilterValue ? defaultFilterValue : new JobFilter(clientId, schoolYearId))
    const [filterQuery, setFilterQuery] = useState<string>("")
    const [schoolBoards, setSchoolBoards] = useState<ISchoolBoardsDropdown[]>([] as ISchoolBoardsDropdown[])
    const [cities, setCities] = useState<IVocabulary[]>([] as IVocabulary[])
    const [zones, setZones] = useState<IZoneDropdown[]>([] as IZoneDropdown[])
    const [filterIsClear, setFilterIsClear] = useState<boolean>(true)
    const [schoolYearsOpts, setSchoolYearsOpts] = useState<ISchoolYearDropdown[]>([] as ISchoolYearDropdown[]);

    const onFilterDropdownChange = (value: string | number | boolean, filterName: string) => {
        setFilter({ ...filter, [filterName]: value, hasValue: filterName !== 'keyWord' ? true : false })
    }
    
    const onFilterDropdownChangeAs = (value: string | number | boolean, filterName: string) => {
        setFilter((prevState => ({
            ...prevState, [filterName]: value, hasValue: true
        })))
    }

    const onFilterDefaultSchoolYear = (schoolYear: string | number) => {
        setFilter(new JobFilter(clientId, +schoolYear as number))
    }

    const onSearchChange = (value: string | number, filterName: string) => {
        setFilter({ ...filter, [filterName]: value })
    }

    const generateFilterQuery = (ignoreKeyWord = false, _includeDates = true, keyWord?: string, params?: string): string => {
        setFilterIsClear(false)
        let query = urlHasParams ? baseUrl : `${baseUrl}?`;
        // let query = includeDates ? `${baseUrl}?StartDate=${filter.startDate}&EndDate=${filter.endDate}` : `${baseUrl}?`;
        !keyWord && delete filter.keyWord;

        if (params) {
            query+= `${params ? params : ''}`
        }

        if ((filter.keyWord || keyWord) && !ignoreKeyWord) {
            // query+= `&KeyWord=${encodeURIComponent(keyWord as string || filter.keyWord as string)}${params ? params : ''}`
            query+= `&KeyWord=${encodeURIComponent(keyWord as string || filter.keyWord as string)}`
            return query
        }

        if (filter.startDate) {
            query+= `&StartDate=${filter.startDate}`
        }
        if (filter.endDate) {
            query+= `&EndDate=${filter.endDate}`
        }
        if (filter.timeFrom) {
            query+= `&TimeFrom=${filter.timeFrom}`
        }
        if (filter.timeTo) {
            query+= `&TimeTo=${filter.timeTo}`
        }
        if (filter.schoolBoardId) {
            query+= `&SchoolBoardId=${filter.schoolBoardId}`
        }
        if (filter.shiftPeriod) {
            query+= `&ShiftPeriod=${filter.shiftPeriod}`
        }
        if (filter.zone) {
            query+= `&ZoneId=${filter.zone}`
        }
        if (filter.minutesFromNow) {
            query+= `&MinutesFromNow=${filter.minutesFromNow}`
        }
        if (Number(filter.checkInType)) {
            query+= `&CheckInType=${filter.checkInType}`
        }
        if (Number(filter.checkOutType)) {
            query+= `&CheckOutType=${filter.checkOutType}`
        }
        if (clientId) {
            query+= `&ClientId=${clientId}`
        }
        if (schoolYearId) {
            query+= `&SchoolYearId=${schoolYearId}`
        }
        if (filter.schoolYearId) {
            query+= `&SchoolYearId=${filter.schoolYearId}`
        }
        if (filter.holidayType) {
            query+= `&HolidayType=${filter.holidayType}`
        }
        if (Number(filter.jobScheduleStatus)) {
            query+= `&JobScheduleStatus=${filter.jobScheduleStatus}`
        }
        if (filter.completedBy) {
            query += `&UserId=${filter.completedBy}`
        }
        if (filter.role) {
            query += `&Role=${filter.role}`
        }
        if (filter.lateAbsenceRange) {
            query += `&LateAbsenceRange=${filter.lateAbsenceRange}`
        }
        if (filter.jobId) {
            query += `&JobId=${filter.jobId}`
        }
        if (filter.guardId) {
            query += `&GuardId=${filter.guardId}`
        }
        query = query.replace("?=", "?")
        setFilterQuery(query)
        return query
    }

    const onFilterClearClick = (callback?: () => Promise<any>, filterValues?: { [index: string]: any }) => {
        setFilter({ ...new JobFilter(clientId, schoolYearId), ...(filterValues ? filterValues : {})})
        setFilterIsClear(true)
        callback && callback()
    }

    const onFilterSearchClearClick = (callback?: () => Promise<any>) => {
        setFilter({...filter, keyWord: ""})
        setFilterIsClear(true)
        callback && callback()
    }

    const getSchoolBoard = async () => {
        const schoolSvc = new ResourceDataService<ISchoolBoardsDropdown[]>({ url: "school/dropdown" })

        try {
            const resSchools = await schoolSvc.getAll();
            setSchoolBoards(resSchools.data)

        } catch (error: any) {
            console.log(error)
        }
    }

    const getSchoolYears = async () => {
        const schoolSvc = new ResourceDataService<ISchoolYearDropdown[]>({ url: "schoolYear" })

        try {
            const resSchoolYears = await schoolSvc.getAll();
            setSchoolYearsOpts(resSchoolYears.data)
        } catch (error: any) {
            console.log(error)
        }
    }

    const getCities = async () => {
        const citySvc = new ResourceDataService<ICityDropdown[]>({ url: "city/dropdown" })

        try {
            const cities = await citySvc.getAll();
            let filteredCities: IVocabulary[] =[]
            cities.data.forEach(city => {
                city.zoneViewModels.forEach(zone =>{
                    filteredCities.push({
                        id: zone.id,
                        name: zone.name
                    })
                })
            })
            setCities(filteredCities)
        } catch (error: any) {
            console.log(error)
        }
    }

    
    const getZones = async () => {
        const zoneSvc = new ResourceDataService<ICityDropdown[]>({ url: "zone/dropdown" })

        try {
            const zones = await zoneSvc.getAll();
           
            setZones(zones.data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())))
        } catch (error: any) {
            console.log(error)
        }
    }


    const ignoreSearchParams = (paramsString: string, ignoreParams: string[]) => {
        const splitUrl = (pieceNum: number) => paramsString.split('?')[pieceNum]
        const searchParams = new URLSearchParams(splitUrl(1))

        ignoreParams.forEach(param => {
            searchParams.delete(param)
        })
        return `${splitUrl(0)}?${searchParams.toString()}`;
    }


    return {
        generateFilterQuery, onFilterDropdownChange, filter, onFilterClearClick, schoolBoards, getSchoolBoard, getCities, onFilterSearchClearClick, cities, onSearchChange,
        filterIsClear, setFilter, getSchoolYears, schoolYearsOpts, onFilterDefaultSchoolYear,onFilterDropdownChangeAs, filterQuery, ignoreSearchParams, zones, getZones
    }
}
