import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { SVGCalendar, SVGEraser } from 'assets/icons/SvgIcons';
import { IGuardReportModel } from 'common/interfaces/IDataFilter';
import Layout from 'components/layout/Layout';
import SectionTitle from 'components/titles/SectionTitle';
import useReportActions from 'customHooks/reports/useReportActions';
import { useDatapickerSvg } from 'customHooks/useDatapickerSvg';
import { useSchoolYear } from 'customHooks/useSchoolYear';
import { datePickerFormat, setHoursAndMinutes } from 'utils/DateFormatting';
import { useGuardReportFilter } from 'customHooks/reports/useGuardReportFilter';
import AutocompleteField from 'components/autosuggestField/AutocompleteField';
import ListGuardReport from './ListGuardReport';
import ModalMultiSelect from 'components/custom-select/ModalMultiSelect';

const GuardReport = () => {
	const {
		generateFilterQuery,
		onFilterDropdownChange,
		filter,
		onFilterClearClick,
		onFilterDefaultSchoolYear,
	} = useGuardReportFilter('reports/guards');
	const {
		onChangeMultiSelectString,
		fetchAdditionalData,
		showTable,
		setShowTable,
	} = useReportActions(filter);
	const { showDatapicker, changeDatapicker } = useDatapickerSvg();
	const { schoolYears, getSchoolYears: setDefaultSchoolYears } = useSchoolYear();
	const [generateQuery, setGenerateQuery] = useState('');

	useEffect(() => {
		fetchAdditionalData();
		onFilterClearClick();
		setDefaultSchoolYears(onFilterDefaultSchoolYear);
	}, []);

	

	const handleCheckBox = (type: keyof IGuardReportModel, index: number | string) => {
		onChangeMultiSelectString(type, index, (result, type) => onFilterDropdownChange(result, type));
	};

	const onClickCreateReport = (): void => {
		setShowTable(true);
		setGenerateQuery(generateFilterQuery());
	};
	return (
		<Layout
			breadcrumbs={{
				links: [
					{
						title: 'Reports',
						link: 'reports',
					},
				],
				currentPageTitle: 'Guard Report',
			}}
			className={'reports-screen'}
		>
			<div className="row mb-3">
				<div className="col-12">
					<SectionTitle title={'Guard Report'} className="mb-3" />
					<p className="fs-14">This is an explanation of the report. The report is generated per schedule.</p>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="d-flex justify-content-between align-items-center">
						<h5 className="mb-0">Create Report</h5>

						<button
							type="button"
							className="btn btn-aqua-blue"
							onClick={() => {
								onFilterClearClick(() => setDefaultSchoolYears(onFilterDefaultSchoolYear));
								setShowTable(false);
							}}
						>
							<SVGEraser />
						</button>
					</div>
				</div>
			</div>

			<form
				onSubmit={(e) => {
					e.preventDefault();
				}}
				onKeyDown={(event) => {
					if (event.key === 'Enter') onClickCreateReport();
				}}
			>
				<div className="row mt-2">
					<div className="col-12 col-md-6">
						<ModalMultiSelect
							onChange={(value) => onFilterDropdownChange(value, 'schoolYears')}
							value={filter.schoolYears || ''}
							title="School Years"
							options={[...schoolYears].reverse()}
                            btnText={'Update School Years'}
						/>
					</div>
				</div>
				<div className="row mt-3">
					<div className="col-md-6 form-group d-flex flex-row align-items-end flex-wrap mb-md-4">
						<div className="col-6 pl-0">
							<div className="mb-0 d-flex align-items-center">
								<label>From: </label>
							</div>
							<div className="react-datepicker-custom-wrapper react-datepicker-custom-wrapper--full-width d-inline-block w-100">
								<DatePicker
									selected={datePickerFormat(filter.startDate!)}
									onChange={(date) => {
										date && onFilterDropdownChange(setHoursAndMinutes(date as Date), 'startDate');
										changeDatapicker(1);
									}}
									dateFormat="dd/MM/yyyy"
									onInputClick={() => changeDatapicker(1)}
									open={showDatapicker[1]}
									onClickOutside={() => changeDatapicker(1)}
								/>
								<div onClick={() => changeDatapicker(1)}>
									<SVGCalendar />
								</div>
							</div>
						</div>
						<div className="col-6 pr-0">
							<div className="mb-0 d-flex align-items-center">
								<label>To: </label>
							</div>
							<div className="react-datepicker-custom-wrapper react-datepicker-custom-wrapper--full-width d-inline-block w-100">
								<DatePicker
									selected={datePickerFormat(filter.endDate!)}
									onChange={(date) => {
										date && onFilterDropdownChange(setHoursAndMinutes(date as Date), 'endDate');
										changeDatapicker(2);
									}}
									dateFormat="dd/MM/yyyy"
									onInputClick={() => changeDatapicker(2)}
									open={showDatapicker[2]}
									onClickOutside={() => changeDatapicker(2)}
								/>
								<div onClick={() => changeDatapicker(2)}>
									<SVGCalendar />
								</div>
							</div>
						</div>
					</div>
					<div className="col-12 my-3">
						<div className="d-flex align-items-center">
							<h5 className="mb-0">Guard Details</h5>
						</div>
					</div>
					<div className="col-md-6 form-group shfit-autosegest mb-md-4">
						<label htmlFor="guardNameOrEmployeeNumber">Guard Name or Employee Number</label>
						<AutocompleteField
							showButton={false}
							includes={'Guard'}
							isAsync
							defaultValue={!filter.guardNameOrEmployeeNumber}
							placeholder="Enter Guard Name or Employee Number"
							onSearch={async (value) => {
								onFilterDropdownChange(value, 'guardNameOrEmployeeNumber');
							}}
							onChange={(value) => onFilterDropdownChange(value, 'guardNameOrEmployeeNumber')}
							id="guardNameOrEmployeeNumber"
							onClear={() => onFilterDropdownChange('', 'guardNameOrEmployeeNumber')}
						/>
					</div>
					<div className="col-md-6 form-group shfit-autosegest mb-md-4">
						<label>Guard Type</label>
						<div className="custom-control custom-checkbox d-flex flex-wrap align-items-center mt-2">
							<div className="mr-5">
								<input
									type="checkbox"
									checked={filter.guardType!.includes('1')}
									onChange={() => handleCheckBox('guardType', '1')}
									className="custom-control-input"
									id="Morning"
									name="Morning"
								/>
								<label className="custom-control-label mb-0" htmlFor="Morning">
									Full-time
								</label>
							</div>
							<div className="mr-5 ml-2">
								<input
									type="checkbox"
									checked={filter.guardType?.includes('2')}
									onChange={() => handleCheckBox('guardType', '2')}
									className="custom-control-input"
									id="Afternoon"
									name="Afternoon"
								/>
								<label className="custom-control-label mb-0" htmlFor="Afternoon">
									Casual
								</label>
							</div>
						</div>
					</div>
					<div className="col-md-6 form-group shfit-autosegest mb-md-4">
						<label>Attendance (% of lates/absents)</label>
						<div className="custom-control custom-checkbox d-flex flex-wrap align-items-center mt-2">
							<div className="mr-5">
								<input
									type="checkbox"
									checked={filter.lateAbsenceRange!.includes('0')}
									onChange={() => handleCheckBox('lateAbsenceRange', '0')}
									className="custom-control-input"
									id="lateAbsenceRange-0"
									name="lateAbsenceRange-0"
								/>
								<label className="custom-control-label mb-0" htmlFor="lateAbsenceRange-0">
									0
								</label>
							</div>
							<div className="mr-5 ml-2">
								<input
									type="checkbox"
									checked={filter.lateAbsenceRange?.includes('1')}
									onChange={() => handleCheckBox('lateAbsenceRange', '1')}
									className="custom-control-input"
									id="lateAbsenceRange-1"
									name="lateAbsenceRange-1"
								/>
								<label className="custom-control-label mb-0" htmlFor="lateAbsenceRange-1">
									1-10
								</label>
							</div>
							<div className="mr-5 ml-2">
								<input
									type="checkbox"
									checked={filter.lateAbsenceRange?.includes('2')}
									onChange={() => handleCheckBox('lateAbsenceRange', '2')}
									className="custom-control-input"
									id="lateAbsenceRange-2"
									name="lateAbsenceRange-2"
								/>
								<label className="custom-control-label mb-0" htmlFor="lateAbsenceRange-2">
									11-25
								</label>
							</div>
							<div className="mr-5 ml-2">
								<input
									type="checkbox"
									checked={filter.lateAbsenceRange?.includes('3')}
									onChange={() => handleCheckBox('lateAbsenceRange', '3')}
									className="custom-control-input"
									id="lateAbsenceRange-3"
									name="lateAbsenceRange-3"
								/>
								<label className="custom-control-label mb-0" htmlFor="lateAbsenceRange-3">
									26-50
								</label>
							</div>
							<div className="mr-5 ml-2">
								<input
									type="checkbox"
									checked={filter.lateAbsenceRange?.includes('4')}
									onChange={() => handleCheckBox('lateAbsenceRange', '4')}
									className="custom-control-input"
									id="lateAbsenceRange-4"
									name="lateAbsenceRange-4"
								/>
								<label className="custom-control-label mb-0" htmlFor="lateAbsenceRange-4">
									51+
								</label>
							</div>
						</div>
					</div>
					<div className="col-md-6 form-group shfit-autosegest mb-md-4">
						<label># of Departures from Geofence During Shifts</label>
						<div className="custom-control custom-checkbox d-flex flex-wrap align-items-center mt-2">
							<div className="mr-5">
								<input
									type="checkbox"
									checked={filter.numberOfDeparturesFromGeofence!.includes('0')}
									onChange={() => handleCheckBox('numberOfDeparturesFromGeofence', '0')}
									className="custom-control-input"
									id="numberOfDeparturesFromGeofence-0"
									name="numberOfDeparturesFromGeofence-0"
								/>
								<label className="custom-control-label mb-0" htmlFor="numberOfDeparturesFromGeofence-0">
									0
								</label>
							</div>
							<div className="mr-5 ml-2">
								<input
									type="checkbox"
									checked={filter.numberOfDeparturesFromGeofence?.includes('1')}
									onChange={() => handleCheckBox('numberOfDeparturesFromGeofence', '1')}
									className="custom-control-input"
									id="numberOfDeparturesFromGeofence-1"
									name="numberOfDeparturesFromGeofence-1"
								/>
								<label className="custom-control-label mb-0" htmlFor="numberOfDeparturesFromGeofence-1">
									1-5
								</label>
							</div>
							<div className="mr-5 ml-2">
								<input
									type="checkbox"
									checked={filter.numberOfDeparturesFromGeofence?.includes('2')}
									onChange={() => handleCheckBox('numberOfDeparturesFromGeofence', '2')}
									className="custom-control-input"
									id="numberOfDeparturesFromGeofence-2"
									name="numberOfDeparturesFromGeofence-2"
								/>
								<label className="custom-control-label mb-0" htmlFor="numberOfDeparturesFromGeofence-2">
									6-10
								</label>
							</div>
							<div className="mr-5 ml-2">
								<input
									type="checkbox"
									checked={filter.numberOfDeparturesFromGeofence?.includes('3')}
									onChange={() => handleCheckBox('numberOfDeparturesFromGeofence', '3')}
									className="custom-control-input"
									id="numberOfDeparturesFromGeofence-3"
									name="numberOfDeparturesFromGeofence-3"
								/>
								<label className="custom-control-label mb-0" htmlFor="numberOfDeparturesFromGeofence-3">
									11-15
								</label>
							</div>
							<div className="mr-5 ml-2">
								<input
									type="checkbox"
									checked={filter.numberOfDeparturesFromGeofence?.includes('4')}
									onChange={() => handleCheckBox('numberOfDeparturesFromGeofence', '4')}
									className="custom-control-input"
									id="numberOfDeparturesFromGeofence-4"
									name="numberOfDeparturesFromGeofence-4"
								/>
								<label className="custom-control-label mb-0" htmlFor="numberOfDeparturesFromGeofence-4">
									16+
								</label>
							</div>
						</div>
					</div>
					<div className="col-12 my-3">
						<div className="d-flex align-items-center">
							<h5 className="mb-0">Job Details for Desired Guard Report</h5>
						</div>
					</div>
					<div className="col-md-6 form-group shfit-autosegest mb-md-4">
						<label htmlFor="jobNameOrId">Job Name or Job ID</label>
						<AutocompleteField
							showButton={false}
							includes={'Job'}
							isAsync
							defaultValue={!filter.jobNameOrId}
							placeholder="Enter Name or Job ID"
							onSearch={async (value) => {
								onFilterDropdownChange(value, 'jobNameOrId');
							}}
							onChange={(value) => onFilterDropdownChange(value, 'jobNameOrId')}
							id="jobNameOrId"
							onClear={() => onFilterDropdownChange('', 'jobNameOrId')}
						/>
					</div>
					<div className="col-md-6 form-group shfit-autosegest mb-md-4">
						<label>Number of Assigned Schedules</label>
						<div className="custom-control custom-checkbox d-flex flex-wrap align-items-center mt-2">
							<div className="mr-5">
								<input
									type="checkbox"
									checked={filter.numberOfAssignedSchedule!.includes('0')}
									onChange={() => handleCheckBox('numberOfAssignedSchedule', '0')}
									className="custom-control-input"
									id="numberOfAssignedSchedule-0"
									name="numberOfAssignedSchedule-0"
								/>
								<label className="custom-control-label mb-0" htmlFor="numberOfAssignedSchedule-0">
									0
								</label>
							</div>
							<div className="mr-5 ml-2">
								<input
									type="checkbox"
									checked={filter.numberOfAssignedSchedule?.includes('1')}
									onChange={() => handleCheckBox('numberOfAssignedSchedule', '1')}
									className="custom-control-input"
									id="numberOfAssignedSchedule-1"
									name="numberOfAssignedSchedule-1"
								/>
								<label className="custom-control-label mb-0" htmlFor="numberOfAssignedSchedule-1">
									1
								</label>
							</div>
							<div className="mr-5 ml-2">
								<input
									type="checkbox"
									checked={filter.numberOfAssignedSchedule?.includes('2')}
									onChange={() => handleCheckBox('numberOfAssignedSchedule', '2')}
									className="custom-control-input"
									id="numberOfAssignedSchedule-2"
									name="numberOfAssignedSchedule-2"
								/>
								<label className="custom-control-label mb-0" htmlFor="numberOfAssignedSchedule-2">
									2
								</label>
							</div>
							<div className="mr-5 ml-2">
								<input
									type="checkbox"
									checked={filter.numberOfAssignedSchedule?.includes('3')}
									onChange={() => handleCheckBox('numberOfAssignedSchedule', '3')}
									className="custom-control-input"
									id="numberOfAssignedSchedule-3"
									name="numberOfAssignedSchedule-3"
								/>
								<label className="custom-control-label mb-0" htmlFor="numberOfAssignedSchedule-3">
									3
								</label>
							</div>
							<div className="mr-5 ml-2">
								<input
									type="checkbox"
									checked={filter.numberOfAssignedSchedule?.includes('4')}
									onChange={() => handleCheckBox('numberOfAssignedSchedule', '4')}
									className="custom-control-input"
									id="numberOfAssignedSchedule-4"
									name="numberOfAssignedSchedule-4"
								/>
								<label className="custom-control-label mb-0" htmlFor="numberOfAssignedSchedule-4">
									4+
								</label>
							</div>
						</div>
					</div>
				</div>

				<div className="row mt-4">
					<div className="col-12">
						<div className="d-flex justify-content-end">
							<button
								type="button"
								className="btn btn-aqua-blue ml-0 ml-sm-3 mt-2 mt-lg-0"
								onClick={() => onClickCreateReport()}
							>
								View Report
							</button>
						</div>
					</div>
				</div>
			</form>

			{showTable && (
				<div>
					<ListGuardReport queryFilter={generateQuery} />
				</div>
			)}
		</Layout>
	);
};

export default GuardReport;
