import React, { FC, useContext, useEffect } from 'react'
import { SVGApprove, SVGCancel } from '../../../assets/icons/SvgIcons'
import { AuthService } from '../../../common/auth/AuthService'
import { ActivationDeactivation } from '../../../common/enums/Actions'
import { Roles } from '../../../common/enums/Roles'
import { VocabularyEnum } from '../../../common/enums/VocabularyEnum'
import { IBaseRequestToDeactivationProps } from '../../../common/interfaces/IBaseActivationDeactivation'
import { IJobRequested } from '../../../common/interfaces/jobs/IJob'
import BaseModal from '../../../components/modal/BaseModal'
import DataTable from '../../../components/tables/DataTable'
import { deactivateJobsListContent } from "../../../content/jobs/JobsContent";
import AppContext from '../../../context/AppContext'
import useActivateDeactivate from '../../../customHooks/useActivateDeactivate'
import { useTableList } from '../../../customHooks/useTableList'
import { useVocabulary } from '../../../customHooks/vocabulary/useVocabulary'
import { momentDateFormat } from '../../../utils/DateFormatting'
import { matchOptionWithName } from '../../../utils/MatchOptionWithName'

interface IProps extends IBaseRequestToDeactivationProps { }

const RequestsToDeactivateJobs: FC<IProps> = ({ hideTbodyCols, hideTheadCols, pageSize, onSuccess, reload }) => {
    const { tableData, isLoading, handleColClick, setTableData, fetchData } = useTableList<IJobRequested>('job/requested', pageSize ? pageSize : 0)
    const { onRequestHandler, onRequestSubmitHandler, requestModals, modalText, onCloseRequestModals, itemInFocus, onSuccessResponse } = useActivateDeactivate<IJobRequested>('job');
    const { vocabulary } = useVocabulary(VocabularyEnum.jobDeactivation, true) 

    useEffect(() => {
        reload && fetchData(1, pageSize ? pageSize : 0, 'job/requested')
    }, [reload])

    const { currentUser } = useContext(AppContext)
    const itemsIsVisible = AuthService.checkIfUserHasPermission([Roles.Admin, Roles.SuperAdmin], currentUser.role)

    return (
        <>
            {
                React.useMemo(() => {
                    return (
                        <DataTable
                            thead={deactivateJobsListContent.thead}
                            tbody={tableData ?
                                tableData.map(item => ({
                                    id: item.id,
                                    jobNameLink: `<a href="#/jobs/${item.id}/details">${item.jobName}</a>`,
                                    requestDate: momentDateFormat(item.statusRequestDate) || '-',
                                    statusRequestedBy: item.statusRequestedBy || '-',
                                    assignedGuards: item.assignedGuards.length !== 0 ? item.assignedGuards.map(item => item.name).join(', ') : '-',
                                    relatedSchoolBoards: item.relatedSchoolBoards.map(item => item.name).join(', ') || '-',
                                    relatedSchools: item.relatedSchools.map(item => item.name).join(', ') || '-',
                                    reason: item.statusChangeReason ? matchOptionWithName(item.statusChangeReason, vocabulary) : '-',
                                }))
                                : []}
                            ignoreCols={[...[0], ...hideTbodyCols || []]}
                            ignoreTheadCols={hideTheadCols}
                            // onColClick={(key, trIndx) => handleColClick(key, trIndx, 'jobNameLink', 'jobs')}
                            tableClass={'table-info--notFixed'}
                        >
                            {(_id, rowItem: IJobRequested) => (
                                <>
                                    <td className="aling-middle">
                                        <div>
                                            <button
                                                className={`btn ${itemsIsVisible ? 'btn-aqua-blue' : 'btn-disabled'}`}
                                                onClick={() => itemsIsVisible && onRequestHandler(
                                                    {
                                                        title: `Are you sure you want to deactivate jobs for <span class="font-weight-bold">${rowItem.jobNameLink}</span>?`,
                                                        itemInFocus: rowItem
                                                    }
                                                )}
                                            >
                                                <SVGApprove />
                                            </button>
                                            <button
                                                className={`btn ${itemsIsVisible ? 'btn-aqua-blue' : 'btn-disabled'} ml-2`}
                                                onClick={() => itemsIsVisible && onRequestHandler(
                                                    {
                                                        title: `Are you sure you want to decline the deactivation request for <span class="font-weight-bold">${rowItem.jobNameLink}</span>?`,
                                                        itemInFocus: rowItem,
                                                        type: ActivationDeactivation.disapprove
                                                    }
                                                )}
                                            >
                                                <SVGCancel />
                                            </button>
                                        </div>
                                    </td>
                                </>
                            )}
                        </DataTable>
                    )
                }, [tableData, isLoading, vocabulary])
            }

            {
                (requestModals.showRequest || requestModals.showDissaprove) && <BaseModal
                    show={requestModals.showRequest || requestModals.showDissaprove}
                    onCancel={() => onCloseRequestModals()}
                    onSubmit={() => onRequestSubmitHandler(requestModals.showRequest ? ActivationDeactivation.approve : ActivationDeactivation.disapprove, 
                        { itemData: null, successCallback: id => { onSuccess && onSuccess(); setTableData(onSuccessResponse<IJobRequested>(id, tableData)) }},
                        requestModals.showRequest ? 
                        `<span class="font-weight-bold">${itemInFocus.jobNameLink}</span> has been deactivated. An automatic message has been sent to the assigned guard and their assigned shift list has been updated.` :
                         `<span class="font-weight-bold">${itemInFocus.jobNameLink}</span> has not been deactivated. An automatic message has been sent to requester <span class="font-weight-bold">${itemInFocus.statusRequestedBy || '[-]'}</span>`
                    )}
                    submitBtnText={"Confirm"}
                >
                    <h4 className="text-left mb-4" dangerouslySetInnerHTML={{ __html: modalText.title }} />
                </BaseModal>
            }

            {
                requestModals.showSuccess && <BaseModal
                    show={requestModals.showSuccess}
                    onCancel={() => onCloseRequestModals()}
                    cancelBtnText={"Close"}
                >
                    <h4 className="text-left mb-4" dangerouslySetInnerHTML={{ __html: modalText.title }} />
                </BaseModal>
            }
        </>
    )
}

export default RequestsToDeactivateJobs
