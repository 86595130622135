import { SVGCalendar, SVGEraser } from 'assets/icons/SvgIcons';
import { IBaseModalProps } from 'common/interfaces/IModal';
import { IJobSchedule, IActiveJobSchedules } from 'common/interfaces/jobs/IJob';
import { IDeactivatedShift } from 'common/interfaces/jobs/IShifts';
import { ViewShiftsOfScheduleModals } from 'common/models/Modals';
import { Vocabulary } from 'common/models/Vocabulary';
import ShiftActionsDropdown from 'components/shifts/ShiftActionsDropdown';
import DataTable from 'components/tables/DataTable';
import { viewShiftsOfScheduleThead } from 'content/dashboard/TablesContent';
import AppContext from 'context/AppContext';
import { useModals } from 'customHooks/modals/useModals';
import { useDatapickerSvg } from 'customHooks/useDatapickerSvg';
import { useListFilter } from 'customHooks/useListFilter';
import { useTableList } from 'customHooks/useTableList';
import React, { FC, useContext, useEffect } from 'react'
import { datePickerFormat, filterDateFormat, momentDateFormat, momentHoursFormat } from 'utils/DateFormatting';
import { getHolidayField } from 'utils/GetFieldFromTable';
import { matchOptionWithName } from 'utils/MatchOptionWithName';
import BaseModal from '../BaseModal';
import DatePicker from 'react-datepicker';
import CustomSelectControl from 'components/custom-select/CustomSelectControl';
import EditShiftModal from './EditShiftModal';
import { DataService } from 'common/services/DataService';
import { NotificationTypes } from 'common/interfaces/INotification';
import EditShiftSuccessModal from './EditShiftSuccessModal'
import ConfirmationModal from '../ConfirmationModal';
import RemoveShiftModal from './RemoveShiftModal';
import RemoveShiftModalSuccess from './RemoveShiftModalSuccess';
import { IVocabulary } from 'common/interfaces/vocabulary/IVocabulary';
import useUnassignGuard from 'customHooks/useUnassignGuard';
import { useVocabulary } from 'customHooks/vocabulary/useVocabulary';
import { VocabularyEnum } from 'common/enums/VocabularyEnum';
import AvailableGuards from 'components/guards/AvailableGuards';
import { useReplaceGuard } from 'customHooks/useReplaceGuard';
import { useReason } from 'customHooks/useReason';

interface IProps extends IBaseModalProps {
    jobName: string
    jobSchedule: IJobSchedule | IActiveJobSchedules
    shiftVocabulary: Vocabulary[]
    successCallback?: () => Promise<any>
}

const ViewShiftsOfScheduleModal: FC<IProps> = ({ jobName, jobSchedule, shiftVocabulary, onCancel, successCallback }) => {
    const { showLoader, showNotification } = useContext(AppContext)
    const baseUrl = `shift/schedule?ScheduleId=${jobSchedule.scheduleId
        ? jobSchedule.scheduleId
        : jobSchedule.id}&Deactivated=false`
    const { tableData: activeShifts, isLoading, currentPage, perPage, allRecords, onPagination,
        onPerPage, fetchData, onClearFilterQuery, onSortCall } = useTableList<IDeactivatedShift>(baseUrl, 24, true)
    const { generateFilterQuery, onFilterDropdownChange, filter, onFilterClearClick } = useListFilter(baseUrl, 0, 0, true, {
        startDate: jobSchedule.startDate, endDate: jobSchedule.endsOn
    })
    const { setModals, modals, itemInFocus: selectedShift, setItemInFocus } = useModals<ViewShiftsOfScheduleModals, any>({
        defaultState: new ViewShiftsOfScheduleModals({ showListModal: true })
    })
    const { onUnassignGuardSubmit } = useUnassignGuard()
    const { vocabulary: removeGuardVocabulary } = useVocabulary(VocabularyEnum.shiftReason, true)
    const { showDatapicker, changeDatapicker } = useDatapickerSvg();
    const svc = new DataService({ url: 'shift' })
    const {
        setSelectedGuard, modalContent, setReplaceModal,
        onReplaceGuardSaveClick, onReplaceGuardConfirmClick
    } = useReplaceGuard()
    const replacementVocabulary = useVocabulary(VocabularyEnum.replacement, true)
    const replacementReasonHook = useReason()

    useEffect(() => {
        replacementVocabulary.vocabulary.length !== 0 && replacementReasonHook.mergeReasonsWithOther(replacementVocabulary.vocabulary)
    }, [replacementVocabulary.vocabulary])

    const onEditShiftSubmit = async (shift: IDeactivatedShift) => {
        showLoader(true)
        try {
            const body = {
                timeFrom: shift.timeFrom,
                timeTo: shift.timeTo,
                guardId: shift.guardId
            }
            setItemInFocus(shift)
            await svc.update(body, shift.id, 'reactivate')
            successCallback && await successCallback()
            setModals(new ViewShiftsOfScheduleModals({ showRemoveShiftSuccessModal: true }))
            showLoader(false)
        } catch (error: any) {
            showLoader(false)
            showNotification(NotificationTypes.danger, error.message)
        }
    }

    const onEditShift = async (shift: IDeactivatedShift) => {
        setItemInFocus(shift)
        setModals(new ViewShiftsOfScheduleModals({ showEditShiftModal: true }))
    }

    const onRemoveShift = async (shift: IDeactivatedShift) => {
        setItemInFocus(shift)
        setModals(new ViewShiftsOfScheduleModals({ showRemoveShiftModal: true }))
    }

    const onRemoveShiftSubmit = async () => {
        try {
            await svc.update({}, selectedShift.id, 'deactivate')
            successCallback && await successCallback()
            setModals(new ViewShiftsOfScheduleModals({ showRemoveShiftSuccessModal: true }))
            showLoader(false)
        } catch (error: any) {
            showLoader(false)
            showNotification(NotificationTypes.danger, error.message)
        }
    }

    const onRemoveGuard = async (shift: IDeactivatedShift) => {
        setItemInFocus(shift)
        setModals(new ViewShiftsOfScheduleModals({ showRemoveGuardModal: true }))
    }

    const onRemoveGuardConfirmedClick = async (reason: string | IVocabulary) => {
        onUnassignGuardSubmit(reason, selectedShift.id, () => {
            setModals(new ViewShiftsOfScheduleModals({ showRemoveGuardSuccessModal: true }))
        })
    }

    const onReplaceGuard = async (shift: IDeactivatedShift) => {
        setItemInFocus(shift)
        setModals(new ViewShiftsOfScheduleModals({ showReplaceGuardModal: true }))
    }

    const onSuccessGuardReplace = async () => {
        await onReplaceGuardConfirmClick({...selectedShift, jobName: jobName}, replacementReasonHook.reason, 'id')
        setModals(new ViewShiftsOfScheduleModals({ showReplaceGuardSuccessModal: true }))
        showLoader(false)
    }

    return (
        <>
            <BaseModal
                show={modals.showListModal}
                onCancel={onCancel}
                showExitBtn
                className='modal--large'
            >
                <div className="d-flex flex-row align-items-center justify-content-between mb-4">
                    <h4 className="mb-0">View Shifts for <span className="font-weight-bold">{jobName}</span></h4>
                    <button className="btn btn-outline-aqua-blue" onClick={() => onCancel()}>Close</button>
                </div>

                <form className="filters-form-group filters-form-group--dashboard mb-4" onKeyDown={(event) => {
                    if (event.key === 'Enter') fetchData(1, 24, generateFilterQuery(true, false))
                }}>
                    <div className="row">
                        <div className="col-12">
                            <h5 className='font-weight-bold'>Filter Shift List</h5>
                        </div>
                        <div className="col-12 d-flex flex-wrap">

                            <CustomSelectControl
                                isClearable={true}
                                options={shiftVocabulary}
                                value={filter.shiftPeriod}
                                placeholder={"Select Shift Period"}
                                onChange={e => onFilterDropdownChange(e, "shiftPeriod")}
                                className="flex-grow-0 mb-2"
                            />

                            <div className="form-group ml-2">
                                <div className="react-datepicker-custom-wrapper react-datepicker-custom-wrapper--full-width d-inline-block w-100">
                                    <DatePicker
                                        selected={datePickerFormat(filter.startDate)}
                                        onChange={date => {
                                            date && onFilterDropdownChange(filterDateFormat(date as Date), 'startDate')
                                            changeDatapicker(1)
                                        }}
                                        dateFormat="dd/MM/yyyy"
                                        onInputClick={() => changeDatapicker(1)}
                                        open={showDatapicker[1]}

                                        onClickOutside={() => changeDatapicker(1)}
                                    />
                                    <div onClick={() => changeDatapicker(1)}><SVGCalendar /></div>
                                </div>
                            </div>

                            <div className="form-group ml-2">
                                <div className="react-datepicker-custom-wrapper react-datepicker-custom-wrapper--full-width d-inline-block w-100">
                                    <DatePicker
                                        selected={datePickerFormat(filter.endDate)}
                                        onChange={date => {
                                            date && onFilterDropdownChange(filterDateFormat(date as Date), 'endDate')
                                            changeDatapicker(2)
                                        }}
                                        dateFormat="dd/MM/yyyy"
                                        onInputClick={() => changeDatapicker(2)}
                                        open={showDatapicker[2]}
                                        onClickOutside={() => changeDatapicker(2)}
                                    />
                                    <div onClick={() => changeDatapicker(2)}><SVGCalendar /></div>
                                </div>
                            </div>

                            <button type="button" className="btn btn-aqua-blue ml-2"
                                onClick={() => fetchData(1, 24, generateFilterQuery())}>
                                Filter Shifts
                            </button>

                            {
                                filter.hasValue &&
                                <button type="button" className="btn btn-aqua-blue ml-2"
                                    onClick={() => {
                                        onClearFilterQuery();
                                        onFilterClearClick();
                                    }}>
                                    <SVGEraser />
                                </button>
                            }
                        </div>
                    </div>
                </form>

                {
                    React.useMemo(() => (
                        <DataTable
                            thead={viewShiftsOfScheduleThead.thead}
                            tbody={
                                activeShifts ?
                                    activeShifts.map(shift => ({
                                        id: shift.id,
                                        startDate: momentDateFormat(shift.startDate),
                                        guardLink: (shift.guardName && shift.guardId)
                                            ? `<a href="#/guards/${shift.guardId}/details">${shift.guardName}</a>` : '-',
                                        aspStartDate: momentHoursFormat(shift.aspStartDate!, shift.timeFrom, true),
                                        timeFrom: momentHoursFormat(shift.timeFrom),
                                        timeTo: momentHoursFormat(shift.timeTo, shift.timeFrom!),
                                        shiftPeriod: matchOptionWithName(shift.shiftPeriod, shiftVocabulary)
                                    }))
                                    : []
                            }
                            ignoreCols={[0]}
                            defaultSortedColumn={0}
                            isLoading={isLoading}
                            tableClass={'table-info--notFixed table-info--lastColHolidays'}
                            currentPage={currentPage}
                            itemsCount={allRecords}
                            itemsPerPage={+perPage}
                            tableName={"Active  Shifts"}
                            onPager={page => onPagination(page)}
                            onPerPage={value => onPerPage(value)}
                            onSort={(orderBy, desc) => onSortCall(orderBy, desc, filter.keyWord)}
                            showTableLengthData
                            sortOnBackend
                            pagination
                        >
                            {
                                (id, _rowItem, rowIndex) => (
                                    <>
                                        <td className="aling-middle">
                                            <ShiftActionsDropdown
                                                onEditShift={() => onEditShift(activeShifts[rowIndex])}
                                                onRemoveShift={() => onRemoveShift(activeShifts[rowIndex])}
                                                onRemoveGuard={() => onRemoveGuard(activeShifts[rowIndex])}
                                                onReplaceGuard={() => onReplaceGuard(activeShifts[rowIndex])}
                                                hideGuardActions={!activeShifts[rowIndex].guardId}
                                            />
                                        </td>
                                        <td className="align-middle">
                                            <div>
                                                {getHolidayField(id, activeShifts)}
                                            </div>
                                        </td>
                                    </>
                                )
                            }
                        </DataTable>
                    ), [isLoading, activeShifts])
                }
            </BaseModal>

            {/* Start Edit Shift */}
            {modals.showEditShiftModal &&
                <EditShiftModal
                    show={modals.showEditShiftModal}
                    onClose={() => setModals(new ViewShiftsOfScheduleModals({ showListModal: true }))}
                    shift={selectedShift}
                    onSubmit={shift => onEditShiftSubmit(shift as any)}
                    jobName={jobName}
                    modalTitle={'Edit Job Shift'}
                />
            }
            {/* End Edit Shift */}
            {
                modals.showEditShiftSuccessModal &&
                <EditShiftSuccessModal
                    jobName={jobName}
                    show={true}
                    onClose={onCancel}
                    shift={{} as any}
                />
            }
            {/* Remove Shift Modal */}
            {
                modals.showRemoveShiftModal &&
                <BaseModal
                    show={modals.showRemoveShiftModal}
                    onCancel={() => setModals(new ViewShiftsOfScheduleModals({ showListModal: true }))}
                    cancelBtnText={'No'}
                    submitBtnText={'Yes'}
                    onSubmit={onRemoveShiftSubmit}
                >
                    <ConfirmationModal text={`Are you sure you want to remove job shift from <span class="font-weight-bold">${jobName}</span>?`} />
                </BaseModal>
            }
            {/* Remove Shift Modal Success*/}
            {
                modals.showRemoveShiftSuccessModal &&
                <BaseModal
                    show={modals.showRemoveShiftSuccessModal}
                    onCancel={onCancel}
                    cancelBtnText={'Close'}
                >
                    <ConfirmationModal text={`Shift has been removed from <span class="font-weight-bold">${jobName}</span>`} />
                </BaseModal>
            }

            {/* Remove Guard From Shift */}
            {modals.showRemoveGuardModal &&
                <RemoveShiftModal
                    show={modals.showRemoveGuardModal}
                    titleBody={`Are you sure you want to remove <span class="font-weight-bold">${selectedShift.guardName}</span> from this shift?`}
                    onClose={() => setModals(new ViewShiftsOfScheduleModals({ showListModal: true }))}
                    onSubmit={(reason) => onRemoveGuardConfirmedClick(reason as string | IVocabulary)}
                    reasons={removeGuardVocabulary}
                    jobName={jobName}
                    shift={selectedShift}
                    shifts={shiftVocabulary}
                />
            }
            {
                modals.showRemoveGuardSuccessModal &&
                <RemoveShiftModalSuccess
                    show={modals.showRemoveGuardSuccessModal}
                    shift={selectedShift}
                    jobName={jobName}
                    onClose={onCancel}
                    userName={selectedShift.guardName}
                />
            }
            {/* Replacement Modal */}
            {modals.showReplaceGuardModal && <BaseModal
                show={modals.showReplaceGuardModal}
                onCancel={() => setModals(new ViewShiftsOfScheduleModals({ showListModal: true }))}
                className="available-guards-modal"
                submitBtnText={'Save'}
                cancelBtnText={'Close'}
                onSubmit={() => onReplaceGuardSaveClick(
                    replacementReasonHook.reasonIsEmpty('reasonForReplacement', 'reasonForReplacement--other'),
                    {...selectedShift, jobName: jobName}, replacementReasonHook.reason)
                    .then(() => setModals(new ViewShiftsOfScheduleModals({ showReplaceGuardConfirmModal: true })))
                }
            >
                <AvailableGuards
                    onSubmit={guard => setSelectedGuard(guard)}
                    selectedItem={selectedShift}
                    title={`Replacement <span class="font-weight-bold">${selectedShift.guardName}</span> for <span class="font-weight-bold">${jobName}</span>
                         <br /> <h5 class="font-weight-bold">${momentDateFormat(selectedShift.startDate)} - Shift ${momentHoursFormat(selectedShift.timeFrom)} - ${momentHoursFormat(selectedShift.timeTo, selectedShift.timeFrom!)}</h5>`}
                    showReasons
                    reasons={replacementReasonHook.reasons}
                    reasonsHook={replacementReasonHook}
                />
            </BaseModal>
            }

            {
                modals.showReplaceGuardConfirmModal &&
                <BaseModal
                    show={modals.showReplaceGuardConfirmModal}
                    onCancel={() => setModals(new ViewShiftsOfScheduleModals({ showReplaceGuardModal: true }))}
                    cancelBtnText={'Cancel'}
                    submitBtnText={'Confirm'}
                    onSubmit={() => onSuccessGuardReplace()}
                >
                    <div dangerouslySetInnerHTML={{ __html: modalContent }} />
                </BaseModal>
            }

            {
                modals.showReplaceGuardSuccessModal &&
                <BaseModal
                    show={modals.showReplaceGuardSuccessModal}
                    onCancel={() => {
                        replacementReasonHook.setReason({} as IVocabulary)
                        setReplaceModal({ confirm: false, success: false })
                        onCancel()
                    }}
                    cancelBtnText={'Close'}
                >
                    <div dangerouslySetInnerHTML={{ __html: modalContent }} />
                    <small className="text-danger">A notification has been sent to the guard with the details of this new shift.</small>
                </BaseModal>
            }
        </>
    )
}

export default ViewShiftsOfScheduleModal
