import { healthAndCheckFilter } from 'common/models/XGuardReportsFilters';
import DataTable from 'components/tables/DataTable';
import { healthAndSafetyThead } from 'content/xguard-reports/tableContent';
import { useTableList } from 'customHooks/useTableList';
import useXGuardReportsQuery from 'customHooks/xguard-reports/useXGuardReportsQuery';
import { datePickerFormat, momentDateFormat, setHoursAndMinutes } from 'utils/DateFormatting';
import Layout from '../../../components/layout/Layout';
import SectionTitle from '../../../components/titles/SectionTitle';
import DatePicker from 'react-datepicker';
import { SVGCalendar, SVGDownload, SVGMarker } from 'assets/icons/SvgIcons';
import { useDatapickerSvg } from 'customHooks/useDatapickerSvg';
import useTextMessage from 'customHooks/useTextMessage';
import AutocompleteField from 'components/autosuggestField/AutocompleteField';
import { useContext, useEffect, useMemo, useState } from 'react';
import AppContext from 'context/AppContext';
import { useDowloandFile } from 'customHooks/useDownloadFile';
import { Reports } from 'common/enums/Reports';
import { IHealthAndSafetyCheck } from 'common/interfaces/reports/xguard-reports/IHealthAndSafety';
import CustomSelectControl from 'components/custom-select/CustomSelectControl';
import { healthAndChecksResponceOpts } from 'content/dropdownOptionsConsts';
import CustomTooltip from 'components/custom-tooltip/CustomTooltip';
import BaseModal from 'components/modal/BaseModal';
import GeofenceMap from 'components/jobs/GeofenceMap';
import { useSchoolYear } from 'customHooks/useSchoolYear';

const HealthAndSafetyChecks = () => {
    const [reportDetails, setReportDetails] = useState<IHealthAndSafetyCheck | undefined>(undefined);
    const { showLoader } = useContext(AppContext);
    const { message: healthCheckQuestion } = useTextMessage<{ questionToGuard: string }>('reports/health-check-question');
    const [viewLocationInMap, setViewLocationInMap] = useState<boolean>(false);
    const {
        fetchData: fetchReports,
        tableData: reports,
        isLoading,
        currentPage,
        perPage,
        allRecords,
        onPagination,
        onSortCall,
        onPerPage,
    } = useTableList<IHealthAndSafetyCheck>('reports/health-and-safety', 24, false);
    const { generateQueryParams, onQueryParamChanged, queryParams } = useXGuardReportsQuery({
        baseUrl: 'reports/health-and-safety',
        defaultParams: healthAndCheckFilter,
    });
    const {getSchoolYears} = useSchoolYear();
    const { showDatapicker, changeDatapicker } = useDatapickerSvg();
    const { dowloandFile } = useDowloandFile();

    useEffect(() => {
        getSchoolYears(onQueryParamChanged, (schoolYearId) => fetchReports(1, 24, generateQueryParams(schoolYearId ? {schoolYearId: schoolYearId.toString()} : {})), true)
    }, [])

    const onDownload = async () => {
        // TODO: Check functionality after back-end updates
        showLoader(true);

        await dowloandFile('report/health-and-safety', Reports.excel, Reports.download, '', '', `&${generateQueryParams().split('?')[1]}`, true);

        showLoader(false);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        fetchReports(1, 24, generateQueryParams());
        event.preventDefault();
    };

    const response = (response: boolean | null) => {
        let baseClass = 'table-info__counter';
        switch (response) {
            case false:
                return { class: `${baseClass}--good`, text: 'no' };
            case null:
                return { class: `${baseClass}--worst`, text: 'no response' };
            default:
                return { class: ``, text: 'yes' };
        }
    };

    const onViewLocationInMapClick = (rowItem: IHealthAndSafetyCheck) => {
        setReportDetails(rowItem);
        setViewLocationInMap(true);
    };

    return (
        <Layout
            breadcrumbs={{
                links: [
                    {
                        title: 'Reports',
                        link: 'reports',
                    },
                ],
                currentPageTitle: 'Health and Safety Checks',
            }}
            className={'reports-screen'}>
            <div className='col-lg-10 mx-lg-auto'>
                <div className='row mb-3'>
                    <div className='col-12'>
                        <SectionTitle title={'Guard Health and Safety Checks Report'} />
                    </div>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className='col-md-4 mb-md-4'>
                            <div className='row'>
                                <div className='col-12 form-group'>
                                    <label>Guard Response:</label>
                                    <CustomSelectControl
                                        options={healthAndChecksResponceOpts || []}
                                        value={queryParams.response}
                                        placeholder={'Select Guard Response'}
                                        onChange={(e) => onQueryParamChanged(e, 'response')}
                                        className='flex-grow-0'
                                        isMulti
                                    />
                                </div>

                                {/* Start Guard Name Search */}
                                <div className='col-12 form-group shfit-autosegest'>
                                    <label htmlFor='guardNameOrEmployeeNumber'>Guard Name or Employee ID:</label>
                                    <AutocompleteField
                                        showButton={false}
                                        defaultValue={!queryParams.guardNameOrEmployeeNumber ? true : false}
                                        includes={'Guard'}
                                        isAsync={true}
                                        placeholder='Guard Name or Employee ID'
                                        onSearch={async (value) => {
                                            onQueryParamChanged(value, 'guardNameOrEmployeeNumber');
                                        }}
                                        onChange={(value) => onQueryParamChanged(value, 'guardNameOrEmployeeNumber')}
                                        onClear={() => onQueryParamChanged('', 'guardNameOrEmployeeNumber')}
                                    />
                                </div>
                                {/* End Guard Name Search */}
                            </div>
                        </div>

                        {/* Start Date Filters */}
                        <div className='col-md-4 form-group mb-md-4'>
                            <div className='row d-flex flex-column'>
                                <div className='col-12 mb-3'>
                                    <label>From: </label>
                                    <div className='react-datepicker-custom-wrapper react-datepicker-custom-wrapper--full-width d-inline-block w-100'>
                                        <DatePicker
                                            selected={datePickerFormat(queryParams.startDate)}
                                            onChange={(date) => {
                                                onQueryParamChanged(date ? setHoursAndMinutes(date as Date) : '', 'startDate');
                                                changeDatapicker(1);
                                            }}
                                            dateFormat='dd/MM/yyyy'
                                            onInputClick={() => changeDatapicker(1)}
                                            open={showDatapicker[1]}
                                            onClickOutside={() => changeDatapicker(1)}
                                            placeholderText='dd/mm/yyyy'
                                        />
                                        <div onClick={() => changeDatapicker(1)}>
                                            <SVGCalendar />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <label>To: </label>
                                    <div className='react-datepicker-custom-wrapper react-datepicker-custom-wrapper--full-width d-inline-block w-100'>
                                        <DatePicker
                                            selected={datePickerFormat(queryParams.endDate)}
                                            onChange={(date) => {
                                                onQueryParamChanged(date ? setHoursAndMinutes(date as Date) : '', 'endDate');
                                                changeDatapicker(2);
                                            }}
                                            dateFormat='dd/MM/yyyy'
                                            onInputClick={() => changeDatapicker(2)}
                                            open={showDatapicker[2]}
                                            onClickOutside={() => changeDatapicker(2)}
                                            placeholderText='dd/mm/yyyy'
                                        />
                                        <div onClick={() => changeDatapicker(2)}>
                                            <SVGCalendar />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Date Filters */}
                    </div>

                    <div className='row mb-5'>
                        <div className='col-md-6 d-flex align-items-center'>
                            <CustomTooltip bodyText={healthCheckQuestion}>
                                <u className='text-aqua-blue'>View Question to Guard</u>
                            </CustomTooltip>
                        </div>
                        <div className='col-md-6 text-right'>
                            <button type='submit' className='btn btn-outline-aqua-blue mr-2'>
                                Update Report
                            </button>
                            <button type='button' className='btn btn-aqua-blue' onClick={onDownload}>
                                <SVGDownload />
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            

            {useMemo(
                () => (
                    <div className='row'>
                        <div className='col-lg-10 mx-lg-auto'>
                            <DataTable
                                thead={healthAndSafetyThead.thead}
                                tbody={
                                    reports
                                        ? reports.map((report) => ({
                                              id: report.id,
                                              date: momentDateFormat(report.date) || '',
                                              guardName: report.guardName,
                                              employeeId: report.employeeId,
                                              response: report.response,
                                              location: report.location,
                                          }))
                                        : []
                                }
                                isLoading={isLoading}
                                ignoreCols={[0, 4, 5]}
                                currentPage={currentPage}
                                itemsCount={allRecords}
                                itemsPerPage={+perPage}
                                onPager={(page) => onPagination(page)}
                                onPerPage={(value) => onPerPage(value)}
                                onSort={(orderBy, desc) => onSortCall(orderBy, desc, queryParams.keyWord)}
                                defaultSortedColumn={0}
                                tableName={'Health and Safety Checks'}
                                tableClass={'table-info--notFixed'}
                                pagination
                                sortOnBackend
                                showTableLengthData>
                                {(_id, rowItem: IHealthAndSafetyCheck) => (
                                    <>
                                        <td className={`align-item ${response(rowItem.response).class}`}>
                                            <div>{response(rowItem.response).text}</div>
                                        </td>
                                        <td className='align-middle'>
                                            <button className='btn btn-aqua-blue' onClick={() => onViewLocationInMapClick(rowItem)}>
                                                <SVGMarker />
                                            </button>
                                        </td>
                                    </>
                                )}
                            </DataTable>
                        </div>
                    </div>
                ),
                [reports, isLoading, currentPage, allRecords, perPage, onPagination, onPerPage, onSortCall, queryParams.keyWord]
            )}

            {/* Start Modal */}
            {viewLocationInMap && (
                <BaseModal show onCancel={() => setViewLocationInMap(false)} className={'view-job-location'} cancelBtnText={'Close'}>
                    <GeofenceMap
                        lat={reportDetails ? +reportDetails?.location.latitude : 0}
                        lng={reportDetails ? +reportDetails?.location.longitude : 0}
                        showGuardPin
                    />
                </BaseModal>
            )}
            {/* End Modal */}
        </Layout>
    );
};

export default HealthAndSafetyChecks;
