import React, { useContext, useState } from 'react';
import CustomTooltip from '../custom-tooltip/CustomTooltip';

import { SVGInfo } from 'assets/icons/SvgIcons';
import ResourceDataService from 'common/services/ResourceDataService';
import { NotificationTypes } from 'common/interfaces/INotification';
import AppContext from 'context/AppContext';

const GeneratePasswordButton: React.FC = () => {
	const [password, setPassword] = useState<string | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [buttonText, setButtonText] = useState<string>('Guard Emergency Password');
	const { showNotification } = useContext(AppContext);

	const fetchPassword = async () => {
		setLoading(true);
		try {
			const sharedPasswordSvc = new ResourceDataService<string>({ url: 'setting/sharedpassword' });
			const result = await sharedPasswordSvc.getAll();
			setPassword(result.data);
			setButtonText(result.data);
		} catch (error) {
			console.error('Error fetching password', error);
		}
		setLoading(false);
	};

	const handleButtonClick = () => {
		if (!password) {
			fetchPassword();
		} else {
			navigator.clipboard.writeText(password);
			showNotification(NotificationTypes.success, `The generated password has been copied`);
		}
	};

	return (
		<div className="generate-password-button text-center">
			<button className="btn btn-aqua-blue" onClick={handleButtonClick} disabled={loading}>
				{loading ? 'Loading...' : buttonText}
			</button>
			<CustomTooltip placement='bottom' bodyText={'Click to generate or copy the daily password.'}>
				<button type="button" className="btn btn-transparent px-0 ml-3">
					<SVGInfo />
				</button>
			</CustomTooltip>
		</div>
	);
};

export default GeneratePasswordButton;
