import { useState} from "react"
import {IShiftJobReportModel} from "../common/interfaces/IDataFilter"
import {ShiftJobReportModel} from "../common/models/ShiftJobReportModel";
import moment from "moment";
import { setCustomTimeInUTC } from "../utils/DateFormatting";


export const useListFilterJobShiftReport = (baseUrl: string, urlHasParams = false, showGuard = false) => {
    const [filter, setFilter] = useState<IShiftJobReportModel>(new ShiftJobReportModel())
    const [filterIsClear, setFilterIsClear] = useState<boolean>(true)

    const onFilterDropdownChange = (value: string | number | boolean, filterName: string) => {
        setFilter({ ...filter, [filterName]: value, hasValue: true })
    }
    const onFilterDropdownChangeAsync = (value: string | number | boolean, filterName: string) => {
        setFilter((prevState => ({
             ...prevState, [filterName]: value, hasValue: true
        })))
    }

    const onFilterDefaultSchoolYear = (schoolYear: string | number) => {
        setFilter({...filter, schoolYearId: +schoolYear})
    }

    const generateDateTimeField = (inDate: string, inTime: string) => {
        const days = moment(inDate).format('YYYY-MM-DD');
        const hours = moment(inTime).format('HH:mm:ss');
        return moment(`${days} ${hours}`, "YYYY-MM-DD HH:mm:ss").utc().format();
    }

    const generateFilterQuery = (ignoreKeyWord = false): string => {
        setFilterIsClear(false)
        let query = urlHasParams ? baseUrl : `${baseUrl}?`;
        if (filter.startDate && !showGuard) {
            query+= `&StartDate=${filter.startDate}`
        }
        if (filter.endDate && !showGuard) {
            query+= `&EndDate=${filter.endDate}`
        }
        if (filter.timeFrom && !showGuard) {
            query+= `&TimeFrom=${filter.timeFrom}`
        }
        if (filter.timeTo && !showGuard) {
            // if (!filter.timeFrom) {
                // query+= `&TimeFrom=${moment(Date.now()).add(-1, 'hours').toISOString()}&TimeTo=${filter.timeTo}`
            // } else {
                query+= `&TimeTo=${filter.timeTo}`
            // }
        }
        if (filter.schoolBoard && !showGuard) {
            query+= `&SchoolBoard=${filter.schoolBoard}`
        }

        if (Number(filter.checkInType)) {
            query+= `&CheckInType=${filter.checkInType}`
        }
        if (Number(filter.checkOutType)) {
            query+= `&CheckOutType=${filter.checkOutType}`
        }

        if (filter.lateAbsenceRange) {
            query += `&LateAbsenceRange=${filter.lateAbsenceRange}`
        }
        if (filter.jobNameOrId) {
            query += `&Job=${filter.jobNameOrId}`
        }
        if (filter.shiftPeriod && !showGuard) {
            query+= `&ShiftPeriod=${filter.shiftPeriod}`
        }
        if (filter.holidayName) {
            query+= `&Holidays=${filter.holidayName}`
        }
        if (filter.zone && !showGuard) {
            query+= `&Zone=${filter.zone}`
        }
        if (filter.guardNameOrEmployeeNumber && !showGuard) {
            query+= `&Guard=${filter.guardNameOrEmployeeNumber}`
        }
        if (filter.guardStatusOnShift && !showGuard) {
            query+= `&GuardStatus=${filter.guardStatusOnShift}`
        }
        if (filter.guardCheckOut) {
            query+= `&CheckOutMethod=${filter.guardCheckOut}`
        }
        if (filter.guardCheckIn) {
            query+= `&CheckInMethod=${filter.guardCheckIn}`
        }
        if (filter.guardChecknStatusShift) {
            query+= `&CheckInStatus=${filter.guardChecknStatusShift}`
        }
        if (filter.guardCheckOutStatusShift) {
            query+= `&CheckOutStatus=${filter.guardCheckOutStatusShift}`
        }
        if (filter.coverageForShift) {
            query+= `&CoverageForShift=${filter.coverageForShift}`
        }

        if (filter.guardNameOrEmployeeNumber && showGuard ) {
            query+= `&GuardName=${filter.guardNameOrEmployeeNumber}`
        }
        if (filter.startDate && showGuard) {
            query+= `&DateTimeFrom=${generateDateTimeField(filter.startDate, filter.timeFrom ? filter.timeFrom : setCustomTimeInUTC(6).format())}`
        }
        if (filter.endDate && showGuard) {
            query+= `&DateTimeTo=${generateDateTimeField(filter.endDate, filter.timeTo ? filter.timeTo : setCustomTimeInUTC(18).format())}`
        }

        if (filter.clientId) {
            query+= `&ClientId=${filter.clientId}`
        }

        if (filter.schoolYearId) {
            query+= `&SchoolYearId=${filter.schoolYearId}`
        }

        return query.replace('?&', '?')
    }

    const onFilterClearClick = (callback?: () => Promise<any>) => {
        setFilter(new ShiftJobReportModel())
        setFilterIsClear(true)
        callback && callback()
    }

    const onFilterSearchClearClick = (callback?: () => Promise<any>) => {
        setFilter({...filter})
        setFilterIsClear(true)
        callback && callback()
    }

    return {
        generateFilterQuery, onFilterDropdownChange, filter, filterIsClear, setFilter, onFilterClearClick, onFilterSearchClearClick, onFilterDropdownChangeAsync, onFilterDefaultSchoolYear
    }
}
