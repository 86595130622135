import React, { FC, useEffect, useRef } from 'react';
import DataTable from '../../../components/tables/DataTable';
import { guardReport } from '../../../content/dashboard/TablesContent';
import { useTableList } from '../../../customHooks/useTableList';
import { IGuardReportModel } from '../../../common/interfaces/IDataFilter';
import { matchOptionWithName } from '../../../utils/MatchOptionWithName';
import { useVocabulary } from '../../../customHooks/vocabulary/useVocabulary';
import { VocabularyEnum } from '../../../common/enums/VocabularyEnum';

type Props = {
	queryFilter: string;
};

const ListGuardReport: FC<Props> = ({ queryFilter }) => {
	const { tableData, isLoading, perPage, allRecords, onPagination, onPerPage, currentPage, onSortCall, fetchData } =
		useTableList<IGuardReportModel>(`${queryFilter}`, '24', false);
	const { vocabulary: shiftVocabulary } = useVocabulary(VocabularyEnum.shift, true);
	const tableRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		fetchData(1, '24', queryFilter).then(() => tableRef.current?.scrollIntoView());
	}, [queryFilter]);

	return (
		<section>
			<div className="row">
				<div className="col-12 shift-report" ref={tableRef}>
					{React.useMemo(
						() => (
							<DataTable
								tableClass="mt-4"
								thead={guardReport.thead}
								tbody={
									tableData
										? tableData.map((guard) => ({
												id: guard.id,
												guardEmployee: guard.guardEmployeeNumber || '-',
												guardName: guard.guardName || '',
												numberOfAssignedSchedules: guard.numberOfAssignedSchedule || 0,
												numberOfAssignedShifts: guard.numberOfAssignedShifts || 0,
												numberOfLates: guard.numberOfLates || 0,
												minutesLateFromApsTime: guard.minutesLateFromASPTime || 0,
												numberOfAbsents: guard.numberOfAbsents || 0,
												percentOfLateOrAbsents: `${guard.persentOfLateOrAbsent || 0}%`,
												jobId: guard.jobId,
												jobName: guard.jobName,
												shiftPeriod: guard.shiftPeriod
													? matchOptionWithName(guard.shiftPeriod, shiftVocabulary)
													: '',
												numberOfTimesLeftGeofenceDuringShift:
													guard.numberOfTimesLeftGeofenceDuringShift || 0,
												percentOfEarlyCheckOuts: `${guard.persentOfEarlyCheckOuts || 0}%`,
												percentOfAdminCheckIns: `${guard.persentOfAdminCheckIns || 0}%`,
												averageLocationCoordinatesPerShift:
													guard.averageNumberOfCoordinatesPerShift || 0,
												aspMobile: guard.aspMobile || '-',
												appVersion: guard.version || '-',
												appBuild: guard.build || '-',
												deviceOS: guard.operationSystem || '-',
										  }))
										: []
								}
								isLoading={isLoading}
                                ignoreCols={[0]}
								pagination
								currentPage={currentPage}
								itemsCount={allRecords}
								itemsPerPage={+perPage}
								onPager={(page) => onPagination(page)}
								onPerPage={(value) => onPerPage(value)}
								tableName={'Guard Report'}
								onSort={(orderBy, desc) => onSortCall(orderBy, desc)}
								showTableLengthData
								sortOnBackend
								defaultSortedColumn={1}
							/>
						),
						[tableData, isLoading, currentPage, allRecords, perPage, shiftVocabulary]
					)}
				</div>
			</div>
		</section>
	);
};

export default ListGuardReport;
