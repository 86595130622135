import React, { useState } from "react";
import { inputDataChecker } from "../utils/InputDataChecker";

type Model = {
    [index: string]: any
}

export type MultipleDataChange = {
    name: string
    value: any;
}

export const useOnIputChange = <T extends Model> (mainObject: T) => {
    const [hookState, setHookState] = useState<T>(mainObject)

    const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string | Date | boolean | number | Array<MultipleDataChange>, fieldName?: string | null, subObject?: string) => {
 
        if (Array.isArray(event)) {
            const fieldsToBeUpdated = {};
            event.forEach(field => {
                const cleanData = inputDataChecker(field.value, field.name)
                Object.assign(fieldsToBeUpdated, {[cleanData.field]: cleanData.value});
            })
            setHookState({...hookState, ...fieldsToBeUpdated })
        } else {
            const cleanData = inputDataChecker(event, fieldName)
            if (subObject) {
                setHookState({ ...hookState, 
                    [subObject]: {
                        ...hookState[subObject],
                        [cleanData.field]: cleanData.value
                    }
                })
            } else {
                let fieldValue = cleanData.value;
                if (cleanData.field === 'institutionCode') {
                    fieldValue = fieldValue.toUpperCase()
                }
                setHookState({...hookState, [cleanData.field]: fieldValue })
            }
        }
    }

    return {
        hookState, onChange, setHookState
    }
}
// "react-error-overlay": "6.0.9"