import { VocabularyEnum } from "common/enums/VocabularyEnum";
import { guardMessagesFilter } from "common/models/XGuardReportsFilters";
import { IGuardMessage } from "common/interfaces/reports/xguard-reports/IGuardMessages";
import DataTable from "components/tables/DataTable";
import { guardMessagesThead } from "content/xguard-reports/tableContent";
import { useTableList } from "customHooks/useTableList";
import { useVocabulary } from "customHooks/vocabulary/useVocabulary";
import useXGuardReportsQuery from "customHooks/xguard-reports/useXGuardReportsQuery";
import { datePickerFormat, momentDateFormat, momentHoursFormat, setHoursAndMinutes } from "utils/DateFormatting";
import { matchOptionWithName } from "utils/MatchOptionWithName";
import Layout from "../../../components/layout/Layout";
import SectionTitle from "../../../components/titles/SectionTitle";
import DatePicker from "react-datepicker";
import { SVGCalendar, SVGDownload } from "assets/icons/SvgIcons";
import { useDatapickerSvg } from "customHooks/useDatapickerSvg";
import AutocompleteField from "components/autosuggestField/AutocompleteField";
import useReportActions from "customHooks/reports/useReportActions";
import {useContext, useEffect, useMemo, useState} from "react";
import AppContext from "context/AppContext";
import { useDowloandFile } from "customHooks/useDownloadFile";
import { Reports } from "common/enums/Reports";
import ReportsDetailsModal from "components/modal/reports/ReportsDetailsModal";
import ReportOptCheckBox from "components/reports/ReportOptCheckBox";
import {useParams} from 'react-router-dom';
import { useSchoolYear } from "customHooks/useSchoolYear";
import CustomSelectControl from "components/custom-select/CustomSelectControl";

const ArrivalReports = () => {
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false)
  const [reportDetails, setReportDetails] = useState<IGuardMessage | undefined>(undefined)
  const {id: messageId} = useParams<{ id?: string}>()

  const { showLoader } = useContext(AppContext)
  const { fetchData: fetchReports, tableData: guardMessages,
    isLoading, currentPage, perPage, allRecords, onPagination, onSortCall, 
    onPerPage } = useTableList<IGuardMessage>("reports/guardmessages", 24)
  const { vocabulary: topics } = useVocabulary(VocabularyEnum.contactFormTopicsOnGuardMobileApp, true)
  const {generateQueryParams, onQueryParamChanged,
    queryParams} = useXGuardReportsQuery({
      baseUrl: "reports/guardmessages",
      defaultParams: guardMessagesFilter
    })
  const {showDatapicker, changeDatapicker} = useDatapickerSvg()
  const { onChangeMultiSelectString } = useReportActions(queryParams);
  const { dowloandFile } = useDowloandFile()
  const { getSchoolYears: setDefaultSchoolYears, schoolYears } = useSchoolYear();
  useEffect(() => {
    setDefaultSchoolYears(onQueryParamChanged, yearId => 
      fetchReports(1, 24, generateQueryParams(yearId ? {schoolYearId: `${yearId}`} : {})),
      true
    );
  }, [])


  const handleCheckBox = (type: keyof IGuardMessage, index: number | string) => {
    onChangeMultiSelectString(type, index,
      (result, type) => {
        onQueryParamChanged(result, type as any)
      }
    )
  }
  const showRecievedMessage = () => {
    guardMessages.map((elem, index) => {
          if(elem.id === Number(messageId) ) {
            onTableRowClick && onTableRowClick(guardMessages[index]) // Modal with message
          }
      })
  }
  useEffect(() => { showRecievedMessage() }, [guardMessages, messageId])

  const onDownload = async () => {
    // TODO: Check functionality after back-end updates
    showLoader(true)
    
    await dowloandFile('report/guard-messages',
    Reports.excel, Reports.download, '', '',
    `&${generateQueryParams().split("?")[1]}`, true)

    showLoader(false)  
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    fetchReports(1, 24, generateQueryParams())
    event.preventDefault();
  }

  const onTableRowClick = (rowItem: IGuardMessage) => {
    rowItem && setReportDetails(rowItem)
    setShowDetailsModal(true)
  }

  const onModalCancel = () => {
    setReportDetails(undefined)
    setShowDetailsModal(false)
  }

  return (
    <Layout
      breadcrumbs={{
        links: [{
            title: 'Reports',
            link: 'reports'
        }],
        currentPageTitle: 'Messages from Guards'
      }}
      className={'reports-screen'}
    >
      <div className="row mb-3">
        <div className="col-12">
          <SectionTitle title={'Guard Messages'}/>
        </div>
      </div>

      <form
        onSubmit={handleSubmit}
      >
        <div className="row">
          {/* Start Date Filters */}
          <div
            className="col-md-4 form-group mb-md-4">
              <div className="row d-flex flex-column">
                <div className="col-12 mb-3">
                  <label>From: </label>
                  <div
                      className="react-datepicker-custom-wrapper react-datepicker-custom-wrapper--full-width d-inline-block w-100">
                      <DatePicker
                          selected={datePickerFormat(queryParams.startDate)}
                          onChange={date => {
                            onQueryParamChanged(date ? setHoursAndMinutes(date as Date) : '', 'startDate')
                            changeDatapicker(1);
                          }}
                          dateFormat="dd/MM/yyyy"
                          onInputClick={() => changeDatapicker(1)}
                          open={showDatapicker[1]}
                          onClickOutside={() => changeDatapicker(1)}
                          placeholderText="dd/mm/yyyy"
                      />
                      <div onClick={() => changeDatapicker(1)}><SVGCalendar/></div>
                  </div>
                </div>
                <div className="col-12">
                  <label>To: </label>
                  <div
                    className="react-datepicker-custom-wrapper react-datepicker-custom-wrapper--full-width d-inline-block w-100">
                    <DatePicker
                      selected={datePickerFormat(queryParams.endDate)}
                      onChange={date => {
                        onQueryParamChanged(date ? setHoursAndMinutes(date as Date) : '', 'endDate')
                        changeDatapicker(2);
                      }}
                      dateFormat="dd/MM/yyyy"
                      onInputClick={() => changeDatapicker(2)}
                      open={showDatapicker[2]}
                      onClickOutside={() => changeDatapicker(2)}
                      placeholderText="dd/mm/yyyy"
                    />
                    <div onClick={() => changeDatapicker(2)}><SVGCalendar/></div>
                  </div>
                </div>

              </div>
          </div>
          {/* End Date Filters */}

          <div className="col-md-4 mb-md-4">

            <div className="row">
              {/* Start Guard Name Search */}
              <div className="col-12 form-group shfit-autosegest">
                <label htmlFor="guardNameOrEmployeeNumber">Guard Name or Employee ID:</label>
                <AutocompleteField
                  showButton={false}
                  defaultValue={!queryParams.guardNameOrEmployeeNumber ? true : false}
                  includes={'Guard'}
                  isAsync={true}
                  placeholder='Guard Name or Employee ID'
                  onSearch={async (value) => {
                      onQueryParamChanged(value, 'guardNameOrEmployeeNumber')
                  }}
                  onChange={(value) => onQueryParamChanged(value, 'guardNameOrEmployeeNumber')}
                  onClear={() => onQueryParamChanged('', 'guardNameOrEmployeeNumber')}/>
              </div>
              {/* End Guard Name Search */}

              {/* Start KeyWord Search */}
              <div className="col-12 form-group shfit-autosegest mb-md-3">
                <label htmlFor="keyword">Keyword(s):</label>
                  <AutocompleteField
                    showButton={false}
                    defaultValue={!queryParams.keyWord ? true : false}
                    placeholder='Enter Keyword(s)'
                    onSearch={async (value) => {
                      onQueryParamChanged(value, 'keyWord')
                    }}
                    onChange={(value) => onQueryParamChanged(value, 'keyWord')}
                    onClear={() => onQueryParamChanged('', 'keyWord')}/>
                </div>
              {/* End KeyWord Search */}
            </div>
                
          </div>

           {/* Start CheckBoxes */}
           <div className="col-md-4 form-group mb-md-4">
            <label htmlFor="firstStreet">Topic:</label>
            <div>
            {
              topics ? topics.map(option => (
                !option.isDisabled &&
                <ReportOptCheckBox
                  optionType={queryParams.topic}
                  option={option}
                  handleCheckBox={handleCheckBox}
                  optionKey={"topic"}
                  key={option.id}
                />
              )) : null
            }
          </div>
            
        </div>

          {/* End CheckBoxes */}

        </div>

        <div className="row mb-5">
          <div className="col-12 text-right">
            <button
              type="submit"
              className="btn btn-outline-aqua-blue mr-2"
              >
              Update Report
            </button>
            <button
              type="button"
              className="btn btn-aqua-blue"
              onClick={onDownload}
            ><SVGDownload /></button>
          </div>
        </div>

      </form>

      {
        useMemo(() => (
          <div className="row">
            <div className="col-12">
              <DataTable
                thead={guardMessagesThead.thead}
                tbody={guardMessages ? guardMessages.map(message => ({
                  id: message.id,
                  date: momentDateFormat(message.created) || "",
                  time: momentHoursFormat(message.created) || "",
                  guardName: message.guardName,
                  employeeId: `${message.employeeId || ''}`.toUpperCase(),
                  topic: matchOptionWithName(message.topic, topics),
                  message: message.message,
                  attachments: message.attachments,
                  created: message.created
                })) : []}
                isLoading={isLoading}
                ignoreCols={[0, 7, 8]}
                currentPage={currentPage}
                itemsCount={allRecords}
                itemsPerPage={+perPage}
                onPager={page => onPagination(page)}
                onPerPage={value => onPerPage(value)}
                onRowClick={rowItem => {
                  onTableRowClick(rowItem) 
                }}
                onColClick={(_key, _id, _item, _idIndx, trIndex) => trIndex !== undefined && onTableRowClick(guardMessages[trIndex])}
                onSort={(orderBy, desc) => onSortCall(orderBy, desc, queryParams.keyWord)}
                defaultSortedColumn={0}
                tableName={"Guard Messages"}
                tableClass={'table-info--notFixed table-arrival-reports table-info-hover'}
                pagination
                sortOnBackend
                showTableLengthData
              />
            </div>
          </div>
        ), [guardMessages, isLoading, currentPage, allRecords, perPage,
          topics, onPagination, onPerPage, onSortCall, queryParams.keyWord])
      }

      {
        showDetailsModal && reportDetails && 
        <ReportsDetailsModal
          report={reportDetails}
          title={'Guard Message Details'}
          onCancel={onModalCancel}
          vocabulary={[undefined, topics]}
        />
      }
    </Layout>
  )
}

export default ArrivalReports
