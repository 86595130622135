import { useLocalStorage } from 'customHooks/useLocalStorage';
import { useTenant } from 'customHooks/useTenant';
import React, { FC, useEffect } from 'react'
import { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import AppContext from '../../context/AppContext';
import { Roles } from '../enums/Roles';
import { AuthService } from './AuthService';

interface IPrivateRouteProps extends RouteProps {
    component: any;
    roles?: Array<Roles>
}

const ProtectedRoute: FC<IPrivateRouteProps> = (props: IPrivateRouteProps) => {
    const { component: Component, ...rest } = props;
    const { authenticated, currentUser, setLoggedIn } = useContext(AppContext);
    const {tableData, currentTenantId} = useTenant()
    const isVisibeByRole = () => {
        if (!props.roles) return true;
        if (AuthService.checkIfUserHasPermission([Roles.SuperAdmin], currentUser.role) && !currentTenantId && tableData.filter(v => v.isActive).length) return false;
        return AuthService.checkIfUserHasPermission(props.roles, currentUser.role)
    }
    return (
        <Route
            {...rest}
            render={(routeProps) =>
                authenticated ? (
                    isVisibeByRole() ? <Component {...routeProps} /> :
                    <Redirect
                        to={{
                            pathname: AuthService.checkIfUserHasPermission([Roles.SuperAdmin], currentUser.role) && !currentTenantId ? '/signin' : '/',
                            state: { from: routeProps.location }
                        }}
                    />
                ) : (
                        <Redirect
                            to={{
                                pathname: '/signin',
                                state: { from: routeProps.location}
                            }}
                        />
                    )
            }
        />
    );
};

export default ProtectedRoute;