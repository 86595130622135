// @flow

import React, { useEffect, useState } from 'react';
import Select, { components } from "react-select"
import { SVGDropdownArrows } from '../../assets/icons/SvgIcons'

type Props = {
  options: Array<{id: string | number, name: string, isDisabled?: boolean}>
  onChange: (value: string, label?: string) => void
  placeholder?: string
  defaultValue?: any
  value?: any
  valueIsLabel?: boolean
  className?: string
  isMulti?: boolean
  isClearable?: boolean
  hiddenOptions?: number[]
  isDisabled?: boolean
  isLoading?: boolean;
  reverse?: boolean;
  sort?: boolean;
}

export type CustomControlOptions = {
  value: string,
  label: string
}

const DropdownIndicator = (
    props: any // TODO: add correct type
  ) => {
    return (
      <components.DropdownIndicator {...props}>
        <SVGDropdownArrows />
      </components.DropdownIndicator>
    );
  };

const CustomSelectControl: React.FC<Props> = ({ options, onChange, placeholder, defaultValue, className, isMulti, value, isClearable, valueIsLabel, hiddenOptions, isDisabled, isLoading, reverse, sort }) => {
  const [compiledOpts, setCompiledOpts] = useState<CustomControlOptions[]>([] as CustomControlOptions[]);

  useEffect(() => {
    let opts: CustomControlOptions[] = options
    .filter(opt => opt.id !== null)
    .map(opt => ({
      value: opt.id.toString(),
      label: opt.name,
      isDisabled: opt.isDisabled
    }))
    if (hiddenOptions && hiddenOptions.length !== 0) {
      opts = opts.filter((_opt, index) => !hiddenOptions.includes(index))
    }
    if (reverse) {
      opts = opts.reverse();
    }
    if (sort) {
      opts = opts.sort((a, b) => a.label.trim().toLowerCase().localeCompare(b.label.trim().toLowerCase()));
    }
    setCompiledOpts(opts)
  }, [options, hiddenOptions])

  const setValue = () => {
    if (isMulti) {
      let splitedArr = "";
      value && (splitedArr = value.toString().split(';'))

      let compiledOptions = options.filter(item => splitedArr.includes(item[valueIsLabel ? 'name' : 'id'].toString())).map(item => ({
        value: item.id.toString(),
        label: item.name,
        isDisabled: item.isDisabled
      }))
      return compiledOptions;
    } else if (options.length !== 0 ) {
      const opt = options.find(opt => +opt.id === +value || opt.name === value || opt.id === value);
      if (opt) {
        return {
          value: opt.id.toString(),
          label: opt.name,
          isDisabled: opt.isDisabled
        }
      } else { return null }
    } else { return null }
  }

  const handleChange = (option: CustomControlOptions[] | CustomControlOptions) => {
    let optionValue = "";

    if(option === null) { onChange(""); return }

    if (Array.isArray(option)) {
      optionValue = option.map(opt => opt.value).join(';');
      onChange(optionValue)
    } else {
      onChange(option.value, option.label)
    }
  }

  return (
    <>
      {
        compiledOpts &&
          <Select
            className={`custom-react-select ${className ? className : ""}`}
            classNamePrefix="custom-react-select"
            options={compiledOpts}
            placeholder={placeholder ? placeholder : "Select..."}
            onChange={(value: any) => handleChange(value)}
            value={setValue()}
            defaultValue={defaultValue}
            components={{ DropdownIndicator }}
            isMulti={isMulti ? true : false}
            isClearable={isClearable}
            isOptionDisabled={option=> option.isDisabled}
            isDisabled={isDisabled}
            isLoading={isLoading}
          />
      }
    </>
  )
}

export default CustomSelectControl
