import React, { FC } from 'react';
import { IClient } from '../../common/interfaces/clients/IClient';
import { IVocabulary } from '../../common/interfaces/vocabulary/IVocabulary';
import { momentDateFormat } from '../../utils/DateFormatting';
import { matchOptionWithName } from '../../utils/MatchOptionWithName';

type Props = {
	client: IClient;
	vocabulary: IVocabulary[];
};

const ClientInfo: FC<Props> = ({ client, vocabulary }) => {
	return (
		<section className="my-4 details-information">
			<div className="row">
				<div className="col-md-6">
					<div className="row mb-3">
						<div className="col-4 col-md-4 details-information__title mb-0">
							<h6 className="mb-0">Organization Name:</h6>
						</div>
						<div className="col-8 col-md-8 details-information__value">
							<p className="mb-0">{client.organizationName}</p>
							{/* <p className="mb-0">Van Halen City</p> */}
						</div>
					</div>
					<div className="row mb-3">
						<div className="col-4 col-md-4 details-information__title mb-0">
							<h6 className="mb-0">Main Address:</h6>
						</div>
						<div className="col-8 col-md-8 details-information__value">
							<p className="mb-0">
								{client.firstAddress} <br />
								{client.secondAddress || ''} {client.secondAddress && <br />}
								{client.city}, {matchOptionWithName(client.province, vocabulary)} <br />
								<span className="text-uppercase">{client.postalCode}</span>{" "}
								{client.country || ''}
							</p>
						</div>
					</div>
					<div className="row mb-3 mt-3 align-items-center">
						<div className="col-4 col-md-4 details-information__title mb-0">
							<h6 className="mb-0">First Active School Year:</h6>
						</div>
						<div className="col-8 col-md-8 details-information__value">
							<p className="mb-0">
								{client.firstSchoolYear?.startDate && client.firstSchoolYear?.endDate
									? `${momentDateFormat(client.firstSchoolYear?.startDate)} - ${momentDateFormat(
											client.firstSchoolYear?.endDate
									  )}`
									: '-'}
							</p>
						</div>
					</div>
					<div className="row mb-3 align-items-center">
						<div className="col-4 col-md-4 details-information__title mb-0">
							<h6 className="mb-0">Latest Active School Year:</h6>
						</div>
						<div className="col-8 col-md-8 details-information__value">
							<p className="mb-0">
								{client.lastSchoolYear?.startDate && client.lastSchoolYear?.endDate
									? `${momentDateFormat(client.lastSchoolYear?.startDate)} - ${momentDateFormat(
											client.lastSchoolYear?.endDate
									  )}`
									: '-'}
							</p>
						</div>
					</div>
				</div>

				<div className="col-md-6">
					<div className="row mb-3">
						<div className="col-4 col-md-2 details-information__title mb-0">
							<h6 className="mb-0">Phone:</h6>
						</div>
						<div className="col-8 col-md-10 details-information__value">
							<p className="mb-0">{client.phoneNumber}</p>
						</div>
					</div>
					<div className="row mb-3">
						<div className="col-4 col-md-2 details-information__title mb-0">
							<h6 className="mb-0">Email:</h6>
						</div>
						<div className="col-8 col-md-10 details-information__value">
							<p className="mb-0">{client.email}</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ClientInfo;
