import { AuthService } from 'common/auth/AuthService';
import { Roles } from 'common/enums/Roles';
import { useContext, useEffect, useState } from 'react';
import { NotificationTypes } from '../common/interfaces/INotification';
import { ITenant } from '../common/interfaces/tenant/ITenant';
import { DataService } from '../common/services/DataService';
import AppContext from '../context/AppContext';
import { useLocalStorage } from './useLocalStorage';
import { useTableList } from './useTableList';
import ResourceDataService from 'common/services/ResourceDataService';
import { ICurrentClient } from 'common/interfaces/user/IClientUser';

type ActivateDeactivateBody = {
	id: string;
	type: 'activate' | 'deactivate';
};
export const useTenant = (isSuperAdmin?: boolean, load: boolean = true ) => {
	const { showLoader, showNotification, currentUser, setCurrentClient: setCurrentClientToContext } = useContext(AppContext);
	const [showAddTenantModal, setShowAddTenantModal] = useState(false);
	const {
		currentPage,
		perPage,
		allRecords,
		onPagination,
		onPerPage,
		tableData,
		isLoading,
		fetchData,
		onSortCall,
	} = useTableList<ITenant>(
		'tenant',
		0,
		load && (isSuperAdmin || AuthService.checkIfUserHasPermission([Roles.SuperAdmin], currentUser?.role))
	);
	const { setValue, storedValue } = useLocalStorage('tenantId', '');
	const [currentTenantId, setCurrentTenantId] = useState(storedValue);
	const [currentClient, setCurrentClient] = useState<ICurrentClient>({} as ICurrentClient)
	useEffect(() => {
		setValue(currentTenantId);
	}, [currentTenantId]);

	useEffect(() => {
		const getCurrentClient = async() => {
			try {
				const clientSv = new ResourceDataService<ICurrentClient>({url: 'client/current'})
				const {data: client} = await clientSv.getAll()
				setCurrentClient(client)
				setCurrentClientToContext(client);
			} catch (error) {
				console.log(error)
			}
		}
		
		load && getCurrentClient()
		
	}, [load]);

	const activateDeactivateRequest = async (
		{ id, type }: ActivateDeactivateBody,
		successCallback: () => Promise<ITenant[] | undefined>
	) => {
		showLoader(true);
		const svc = new DataService<any>({ url: `tenant/${id}/${type}` });
		try {
			await svc.create(null);
			await successCallback();
			showNotification(
				NotificationTypes.success,
				`The client has been ${
					type === 'activate' ? 'activated' : 'deactivated'
				} successful`
			);
			showLoader(false);
		} catch (e: any) {
			showNotification(NotificationTypes.danger, e.message);
			showLoader(false);
		}
	};
	const addTenant = async (
		tenant: ITenant,
		successCallback: () => Promise<ITenant[] | undefined>
	) => {
		showLoader(true);
		const svc = new DataService<ITenant>({ url: `tenant` });
		try {
			await svc.create(tenant);
			await successCallback();
			showNotification(NotificationTypes.success, `The client has been added successful`);
			setShowAddTenantModal(false);
			showLoader(false);
		} catch (e: any) {
			showNotification(NotificationTypes.danger, e.message);
			showLoader(false);
		}
	};
	return {
		activateDeactivateRequest,
		addTenant,
		showAddTenantModal,
		setShowAddTenantModal,
		currentPage,
		perPage,
		allRecords,
		onPagination,
		onPerPage,
		tableData,
		isLoading,
		fetchData,
		onSortCall,
		setCurrentTenantId,
		currentTenantId,
		currentClient,
		setTenantToLS: setValue
	};
};
