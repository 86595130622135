import React, { FC, useContext, useEffect } from 'react'
import { manageDeactivateSchedulesContent } from '../../../content/administration/AdministrationContent'

import { ActivationDeactivation } from '../../../common/enums/Actions'
import {  IJobScheduleRequested } from '../../../common/interfaces/jobs/IJob'

import useActivateDeactivate from '../../../customHooks/useActivateDeactivate'
import { useTableList } from '../../../customHooks/useTableList'
import { momentDateFormat, momentHoursFormat } from '../../../utils/DateFormatting'
import { matchWeekDays } from '../../../utils/MatchWeekDays'

import BaseModal from '../../../components/modal/BaseModal'
import DataTable from '../../../components/tables/DataTable'
import { VocabularyEnum } from '../../../common/enums/VocabularyEnum'
import { useVocabulary } from '../../../customHooks/vocabulary/useVocabulary'
import { matchOptionWithName } from '../../../utils/MatchOptionWithName'
import { SVGApprove, SVGCancel } from '../../../assets/icons/SvgIcons'
import { IBaseRequestToDeactivationProps } from '../../../common/interfaces/IBaseActivationDeactivation'
import { AuthService } from '../../../common/auth/AuthService'
import { Roles } from '../../../common/enums/Roles'
import AppContext from '../../../context/AppContext'

interface IProps extends IBaseRequestToDeactivationProps { }

const RequestsToDeactivateSchedules: FC<IProps> = ({ hideTbodyCols, hideTheadCols, pageSize, onSuccess, reload }) => {
    const { tableData, isLoading, handleColClick, setTableData, fetchData } = useTableList<IJobScheduleRequested>('schedule/requested', pageSize ? pageSize : 0)
    const { vocabulary } = useVocabulary(VocabularyEnum.shift, true)
    const { onRequestHandler, onRequestSubmitHandler, requestModals, modalText, onCloseRequestModals, itemInFocus, onSuccessResponse } = useActivateDeactivate<IJobScheduleRequested>('schedule');
    const { vocabulary: reasonsForDeactivation } = useVocabulary(VocabularyEnum.scheduleDeactivation, true)
    const { currentUser } = useContext(AppContext)
    const itemsIsVisible = AuthService.checkIfUserHasPermission([Roles.Admin, Roles.SuperAdmin], currentUser.role)
    
    useEffect(() => {
        reload && fetchData(1, pageSize ? pageSize : 0, 'schedule/requested')
    }, [reload])

    return (
        <>
            {
                React.useMemo(() => (
                    <DataTable
                        thead={manageDeactivateSchedulesContent.thead}
                        tbody={tableData ?
                            tableData.map(schedule => ({
                                id: schedule.id,
                                jobId: schedule.jobId,
                                jobNameLink: `<a href="#/jobs/${schedule.jobId}/details">${schedule.jobName}</a>`,
                                date: `${momentDateFormat(schedule.startDate)} to ${momentDateFormat(schedule.endsOn)}, <br />
                                ${momentHoursFormat(schedule.timeFrom)} to ${momentHoursFormat(schedule.timeTo, schedule.timeFrom)}, <br />${matchOptionWithName(schedule.shiftPeriod, vocabulary)}`,
                                repeat: `Every week: <br /> ${matchWeekDays(schedule.repeatOn)}`,
                                requestDate: schedule.statusRequestDate ? momentDateFormat(schedule.statusRequestDate) : '-',
                                requestBy: schedule.statusRequestedBy || "-",
                                assignedGuards: schedule.assignedGuards || "-",
                                statusChangeReason: schedule.statusChangeReason ? matchOptionWithName(schedule.statusChangeReason, reasonsForDeactivation) : '-',
                            }))
                        : []}
                        ignoreCols={[...[0, 1], ...hideTbodyCols || []]}
                        ignoreTheadCols={hideTheadCols}
                        isLoading={isLoading}
                        tableClass={'table-info--notFixed'}
                        // onColClick={(key, _trIndx, rowItem) => handleColClick(key, rowItem.jobId, 'jobNameLink', 'jobs')}
                    >
                        {
                            (_id, rowItem: IJobScheduleRequested) => (
                                <>
                                    <td className="aling-middle">
                                        <div>
                                            <button
                                                className={`btn ${itemsIsVisible ? 'btn-aqua-blue' : 'btn-disabled'}`}
                                                onClick={() => itemsIsVisible && onRequestHandler(
                                                    {
                                                        title: `Are you sure you want to deactivate schedule for <span class="font-weight-bold">${rowItem.jobNameLink}</span>?`,
                                                        itemInFocus: rowItem 
                                                    }
                                                )}
                                            >
                                                <SVGApprove />
                                            </button>
                                            <button
                                                className={`btn ${itemsIsVisible ? 'btn-aqua-blue' : 'btn-disabled'} ml-2`}
                                                onClick={() => itemsIsVisible && onRequestHandler(
                                                    { 
                                                        title: `Are you sure you want to decline the deactivation request for <span class="font-weight-bold">${rowItem.jobNameLink}</span>?`,
                                                        itemInFocus: rowItem,
                                                        type: ActivationDeactivation.disapprove
                                                    }
                                                )}
                                            >
                                                <SVGCancel/>
                                            </button>
                                        </div>
                                    </td>
                                </>
                            )
                        }
                    </DataTable>
                ), [tableData, isLoading, reasonsForDeactivation, vocabulary])
            }

            {           
                (requestModals.showRequest || requestModals.showDissaprove) && <BaseModal
                    show={requestModals.showRequest || requestModals.showDissaprove}
                    onCancel={() => onCloseRequestModals()}
                    onSubmit={() => {
                        onRequestSubmitHandler(requestModals.showRequest ? ActivationDeactivation.approve : ActivationDeactivation.disapprove, 
                        { itemData: null, successCallback: id => {
                            setTableData(onSuccessResponse<IJobScheduleRequested>(id, tableData))
                            onSuccess && onSuccess()
                        } },
                        requestModals.showRequest ? 
                        `The selected schedule for <span class="font-weight-bold">${itemInFocus.jobNameLink}</span> has been deactivated.` :
                        `The selected schedule for <span class="font-weight-bold">${itemInFocus.jobNameLink}</span> has not been deactivated.`
                    )}}
                    submitBtnText={"Confirm"}
                    cancelBtnText={"Close"}
                >
                    <h4 className="text-left mb-4" dangerouslySetInnerHTML={{ __html: modalText.title }} />
                </BaseModal>
            }

            {
                requestModals.showSuccess && <BaseModal
                    show={requestModals.showSuccess}
                    onCancel={() => onCloseRequestModals()}
                    cancelBtnText={'Close'}
                >
                    <h4 className="text-left mb-4" dangerouslySetInnerHTML={{ __html: modalText.title }} />
                </BaseModal>
            }
        </>
    )
}

export default RequestsToDeactivateSchedules
