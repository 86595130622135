import { IError } from "../interfaces/IError";
import { ErrorResponse } from "../models/Error";
import { ILoggedInUser } from '../interfaces/IUser'
import { Roles } from "../enums/Roles";

export interface ISignInBody {
    firstName: string,
    middleName: string,
    lastName: string,
    email: string,
    role: Roles,
    id: string,
    accessToken: string
    refreshToken: string
}

export interface IRefreshTokenBody {
    jwtToken: string
    refreshToken: string
}

const baseUrl = process.env.REACT_APP_API_URL
const credentials = process.env.NODE_ENV === 'development' ? 'include' : 'same-origin'

export class AuthService {
    static bearer: string = '';
    static refreshTokenPromise: Promise<any> | null = null

    static async signIn (email: string, password: string): Promise<ILoggedInUser> {
        const options: any = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password })
        };
        try {
            const response = await fetch(`${baseUrl}/account/authenticate`, options)
            if (!response.ok) {
                const error: IError = await response.json()
                return await Promise.reject(new ErrorResponse(error.Errors ? error.Errors.join(',') : error.Message));
            } else {
                const body = await response.json()
                AuthService.setBearer = body.data.accessToken
                return body.data;
            }
        } catch (error: any) {
            return Promise.reject(new Error(error))
        }
    }

    static async signOut () {
        try {
            const response = await fetch(`${baseUrl}/account/revoke-token`, {
                credentials,
                method: 'POST'
            })
            if (!response.ok) {
                const error: IError = await response.json()
                return await Promise.reject(new ErrorResponse(error.Errors ? error.Errors.join(',') : error.Message));
            } else {
                const body = await response.json()
                AuthService.setBearer = ''
                window.localStorage.clear();
                return body.data;
            }
        } catch (error: any) {
            return Promise.reject(new Error(error))
        }
    }

    static async forgotPassword (email: string) {
        const options: any = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email })
        };

        try {
            const response = await fetch(`${baseUrl}/forgot-password`, options)
            if (!response.ok) {
                const error: IError = await response.json()
                return await Promise.reject(new ErrorResponse(error.Errors ? error.Errors.join(',') : error.Message));
            } else {
                const body = await response.json()
                return body.data;
            }
        } catch (error: any) {
            return Promise.reject(new Error(error))
        }
    }

    static async refreshToken (): Promise<ILoggedInUser> {
        try {
            const response = await fetch(`${baseUrl}/account/refresh-token`, {
                credentials,
                method: 'POST'
             })
            if (!response.ok) {
                const error: IError = await response.json()
                return await Promise.reject(new ErrorResponse(error.Errors ? error.Errors.join(',') : error.Message));
            } else {
                const body = await response.json()
                return body.data as ILoggedInUser;
            }
        } catch (error: any) {
            return Promise.reject(new Error(error))
        }
      }

    static isAccessTokenExpired (exp: number) {
        if (new Date().getTime() >= exp * 1000) {
            return true;
        } else { return false }
    }

    static async getAuthenticatedUser (user: ILoggedInUser) {
        if (Object.keys(user).length === 0) {
            try {
                const updatedData = await AuthService.refreshToken();
                AuthService.setBearer= updatedData.accessToken
                return updatedData
            } catch (error: any) {
                AuthService.setBearer = ''
                return undefined
            }

        } else {
            if (AuthService.isAccessTokenExpired(AuthService.parseTokenData(user.accessToken).exp)) {
                try {
                    const updatedData = await AuthService.refreshToken();
                    AuthService.setBearer = updatedData.accessToken
                    return updatedData
                } catch (error: any) {
                    AuthService.setBearer = ''
                    return undefined
                }
            } else { return user }
        }
    }

    static getAccessToken () {
        if ( AuthService.isAccessTokenExpired(AuthService.parseTokenData(AuthService.getBearer).exp) ) { 
            if (AuthService.refreshTokenPromise === null) { // check for an existing in-progress request
                // if nothing is in-progress, start a new refresh token request
                AuthService.refreshTokenPromise = AuthService.refreshToken().then(token => {
                    AuthService.refreshTokenPromise = null // clear state
                    AuthService.setBearer = token.accessToken
                    return token.accessToken // resolve with the new token
                });
            }
            return AuthService.refreshTokenPromise.then(token => token)
            
        } else { return AuthService.getBearer }
    }

    static parseTokenData (accessToken: string): ILoggedInUser {
        let payload = ''
        let tokenData: ILoggedInUser = {} as ILoggedInUser

        try {
          payload = accessToken.split('.')[1]
          tokenData = payload ? JSON.parse(atob(payload)) : ''
        } catch (error: any) {
          throw new Error(error)
        }

        return tokenData
    }

    static checkIfUserHasPermission = (allowedRoles: Array<Roles>, roleToCheck: Roles) => {
        if (allowedRoles.some(role => roleToCheck?.toString() === role)) {
            return true
        } else {
            return false
        }
    }

    static get getBearer () { return this.bearer }

    static set setBearer (accessToken: string) {
        this.bearer = accessToken
    }
}
